import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const ProductWiseSearch = ({ reportLists }) => {
  return (
    <li className="whiteBoxShadow">
      <h3>Product Wise Search</h3>
      {reportLists.productWise.length ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={reportLists.productWise}
            // barSize={20}
          >
            <XAxis
              dataKey="productName"
              interval={"preserveStartEnd"}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "10px",
                // fontWeight: "bold",
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "10px",
              }}
              domain={[0, reportLists.productWise.length + 8]}
            />
            <CartesianGrid vertical={false} />

            <Bar
              dataKey="count"
              fill="#8884d8"
              // name="Product"
              // legendType="rect"
            >
              {reportLists.productWise.map((entry, ind) => (
                <Cell fill={entry.color} key={ind} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div>No Data Found</div>
      )}
    </li>
  );
};

export default ProductWiseSearch;
