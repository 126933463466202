import Layout from "../Layout";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import Loader from "../../../components/Loader/Loader";
import {
  calculateTotalWithPercentage,
  getAllChartUsers,
  getMonthAbbreviation,
} from "../../../components/ChartsUtils/ChartsUtils";
import ReportCalendar from "../../../components/Calendar/Calendar";
import ProductWiseSearch from "../../../components/SearchByAll/ProductWiseSearch";
import MonthWIseUserSearch from "../../../components/SearchByAll/MonthWIseUserSearch";
import MonthWIseSearchChart from "../../../components/SearchByAll/MonthWIseSearchChart";
import { PostCallsAdmin } from "../../../ApiClient/PostCall";

const Report = () => {
  const [isLoader, setIsLoader] = useState(false);
  const { http } = ApiClient();
  const navigate = useNavigate();
  const postAdmin = PostCallsAdmin(http, setIsLoader, Swal, navigate);

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
    subsriberCountWise: [],
  });

  const searchHistoryHandleOnChangeCalendar = (event) => {
    if (event) {
      let startDate = moment(event[0]["$d"]).format("YYYY-MM-DD");
      let endDate = moment(event[1]["$d"]).format("YYYY-MM-DD");

      getMonthWiseSearch(startDate, endDate);
      getProductWiseSearch(startDate, endDate);
      getSearchSubscriberCountByMonth(startDate, endDate);
      getMonthWiseUserSearch(startDate, endDate);
    }
  };

  const getMonthWiseSearch = async (startDate, endDate) => {
    setIsLoader(true);
    let data = await postAdmin.getMonthWiseSearch(startDate, endDate);

    setReportLists((prevLists) => ({
      ...prevLists,
      monthWise: data,
    }));
    setIsLoader(false);
  };
  const getProductWiseSearch = async (startDate, endDate) => {
    let data = await postAdmin.getProductWiseSearch(startDate, endDate);

    setReportLists((prevLists) => ({
      ...prevLists,
      productWise: data,
    }));
  };

  const getSearchSubscriberCountByMonth = async (startDate, endDate) => {
    let data = await postAdmin.getSubscriberCountByMonthAdmin(
      startDate,
      endDate
    );
    setReportLists((prevLists) => ({
      ...prevLists,
      subsriberCountWise: data,
    }));
  };

  const getMonthWiseUserSearch = async (startDate, endDate) => {
    let data = await postAdmin.getUsersByMonth(startDate, endDate);

    setReportLists((prevLists) => ({
      ...prevLists,
      userWise: calculateTotalWithPercentage(data),
      users: getAllChartUsers(data),
    }));
  };

  useEffect(() => {
    getMonthWiseSearch("");
    getProductWiseSearch("");
    getSearchSubscriberCountByMonth("");
    getMonthWiseUserSearch("");

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={7}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <div className="ReportDate">
            <h1>Report</h1>
            <ReportCalendar
              searchHistoryHandleOnChangeCalendar={
                searchHistoryHandleOnChangeCalendar
              }
            />
          </div>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Reports</span>
          </div>
        </div>
        <div className="blankContainer">
          <div className="report3Clo">
            <ul>
              <li className="scribe_count">
                <div className="whiteBoxShadow">
                  <h3>Month Wise Subscribers Count</h3>
                  {reportLists.subsriberCountWise.length ? (
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={reportLists.subsriberCountWise}>
                        <XAxis
                          dataKey="month"
                          interval={"preserveStartEnd"}
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={getMonthAbbreviation}
                          style={{
                            fontSize: "10px",
                          }}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          style={{
                            fontSize: "10px",
                          }}
                          domain={[
                            0,
                            reportLists.subsriberCountWise.length + 8,
                          ]}
                        />
                        <CartesianGrid vertical={false} />

                        <Bar
                          dataKey="count"
                          fill="#F88B68"
                          label={{ fill: "white", fontSize: 10 }}
                          // name="Product"
                          // legendType="rect"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              </li>
              {/* <li className="mpnth_search"> */}
              <MonthWIseSearchChart reportLists={reportLists} />
              {/* </li> */}
              {/* <li className="product_search"> */}
              <ProductWiseSearch reportLists={reportLists} />
              {/* </li> */}
            </ul>
            <MonthWIseUserSearch reportLists={reportLists} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
