import React from "react";
import { PolicyDownload } from "../../../components/PolicyDownload/Policydownload";
import { secondLastLocation } from "../../../Utils/Locations";

const PolicyDownloadComponent = ({
  underguideLinedetails,
  carrierPolicy,
  location,
  underGuideLineOnChangeHandleLogo,
}) => {
  return (
    <div className="formBox01 noBorder">
      <div className="twoPart">
        <div className="left">
          <p className="head">Underwriting Guidelines</p>
          <p className="smallTest">
            {underguideLinedetails.logoFileTitle || "NA"}

            {carrierPolicy && !underguideLinedetails.logoFileTitle ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  PolicyDownload(
                    carrierPolicy,
                    `${secondLastLocation(location)}_policy`
                  )
                }
              >
                VIEW
              </span>
            ) : null}
          </p>
        </div>
        <div className="right">
          <div className="upload_btn_wrapper">
            <input
              type="file"
              onChange={(event) => underGuideLineOnChangeHandleLogo(event)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDownloadComponent;
