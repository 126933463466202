import React, { Fragment } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { DateFormat } from "../DateFormat/DateFormat";

const SearchHistoryDetailsModal = ({
  viewShow,
  ViewHandleClose,
  currCust,
  oneHist,
}) => {
  return (
    <Modal
      show={viewShow}
      onHide={ViewHandleClose}
      className="SearchHistoryModal"
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2>Search History Details</h2>
        <ul className="SearchHistoryDetail_modal">
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Agency Name</li>
              <li>{currCust.agency || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>User Name</li>
              <li>{currCust.createdBy || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Created On:</li>
              <li>{DateFormat(currCust.createdOn) || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Last Modifed on:</li>
              <li>{DateFormat(currCust.lastUpdatedOn) || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Customer Name</li>
              <li>{currCust.customerName || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Location Searched</li>
              <li>{currCust.state || "NA"}</li>
            </ul>
          </li>
          <li>
            <ul className="SearchHistoryDetail_modal_inner">
              <li>Searched Product</li>
              <li>{currCust.product || "NA"}</li>
            </ul>
          </li>
        </ul>
        <div className="accordian_wrapper">
          <Accordion
            defaultActiveKey="0"
            className="SearchHistoryDetail_accordian"
          >
            {oneHist?.map((hist, idx) => (
              <Accordion.Item eventKey={`${idx}`} key={idx}>
                <Accordion.Header>
                  {DateFormat(hist?.date) || "July 15,2023"}
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="sHaBody">
                    {hist?.searchQuestionAnswer?.map((el, indx) => {
                      return (
                        <Fragment key={indx}>
                          <li>
                            <ul className="innerPart">
                              <li>Selected questions and answer</li>
                              {Object.entries(el?.questionAnswer)?.map(
                                (el, i) => (
                                  <li key={i}>
                                    {el[0]}: <span>{el[1]}</span>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                          <li>
                            <ul className="innerPart">
                              <li>Matched Carriers:</li>
                              <li className="logoContainer">
                                {el?.matchedCarrier.length
                                  ? el?.matchedCarrier.map(
                                      (matchCarr, indexes) => (
                                        <img
                                          key={indexes}
                                          src={matchCarr?.carrierLogo}
                                          loading="lazy"
                                          alt="Logo"
                                          width={"50px"}
                                        />
                                      )
                                    )
                                  : "NA"}
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ul className="innerPart">
                              <li>Confirmed Carriers:</li>
                              <li className="logoContainer">
                                {el?.confermedCarrier
                                  ? el?.confermedCarrier.map(
                                      (confCarr, index) => (
                                        <img
                                          key={index}
                                          src={confCarr?.carrierLogo}
                                          loading="lazy"
                                          alt="Logo"
                                          width={"50px"}
                                        />
                                      )
                                    )
                                  : "NA"}
                              </li>
                            </ul>
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchHistoryDetailsModal;
