import React from "react";
import { Form } from "react-bootstrap";

const MinMaxCommonComponent = ({
  productQuestionEntity,
  underguideLinedetails,
  underGuideLineOnChangeHandle,
}) => {
  return (
    <>
      <Form.Group className="width125" controlId="formBasicEmail">
        <Form.Control
          type="text"
          className="form_control_2"
          name={`${productQuestionEntity.questionLabelCode}1`}
          placeholder="min-value"
          value={
            underguideLinedetails[
              `${productQuestionEntity.questionLabelCode}1`
            ] || ""
          }
          onChange={(event) =>
            underGuideLineOnChangeHandle(
              event,
              underguideLinedetails.carrierQuestionAnswer.questinAnswerSatate[0]
                .answers
            )
          }
        />
      </Form.Group>
      <Form.Group
        className="width125"
        controlId="formBasicEmail"
        style={
          underguideLinedetails[
            `${productQuestionEntity.questionLabelCode}3`
          ] === 2 &&
          underguideLinedetails[
            `${productQuestionEntity.questionLabelCode}2`
          ] === null
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <Form.Control
          type="text"
          className="form_control_2"
          name={`${productQuestionEntity.questionLabelCode}2`}
          placeholder="max-value"
          value={
            underguideLinedetails[
              `${productQuestionEntity.questionLabelCode}2`
            ] || ""
          }
          onChange={(event) =>
            underGuideLineOnChangeHandle(
              event,
              underguideLinedetails.carrierQuestionAnswer.questinAnswerSatate[0]
                .answers
            )
          }
        />
      </Form.Group>
    </>
  );
};

export default MinMaxCommonComponent;
