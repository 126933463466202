import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";

const ContactUs = () => {
  const [editContactDetails, setEditContactDetails] = useState({
    aboutContactUs: "",
    address: "",
    city: "",
    pinCode: "",
    contact: "",
    email: "",
    allContacts: [],
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const handleEditShowClose = () => setEditShow(false);

  const { http } = ApiClient();

  const handleEditShow = () => {
    setEditShow(true);
  };

  const editUserOnChangeHandle = (event) => {
    let { name, value } = event.target;
    setEditContactDetails({ ...editContactDetails, [name]: value });
  };

  const handleSubmitEditButtonOnClick = () => {
    const { aboutContactUs, address, city, pinCode, contact, email } =
      editContactDetails;
    if (
      !aboutContactUs ||
      !address ||
      !city ||
      !pinCode ||
      !contact ||
      !email
    ) {
      return Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let contactPayload = {
      riskerContactEntityId:
        editContactDetails.allContacts[0].riskerContactEntityId,
      message: aboutContactUs,
      address,
      city,
      pinCode,
      phoneNumber: contact,
      email,
    };

    setIsLoader(true);
    http
      .put("/updateRiskerContact", contactPayload)
      .then(() => {
        getContactUs();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleEditShowClose();
  };

  const getContactUs = () => {
    setIsLoader(true);
    http
      .get(`/page/getAllRiskerContact`)
      .then((res) => {
        let { message, address, city, pinCode, phoneNumber, email } =
          res.data[0];
        setEditContactDetails({
          ...editContactDetails,
          allContacts: res.data,
          aboutContactUs: message,
          address,
          city,
          pinCode,
          contact: phoneNumber,
          email,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        // isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getContactUs();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Layout sideBarActive={12}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Contact Us</h1>
          <div className="rightWithButton">
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ ContactUs</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Address</th>
                <th>Mobile</th>
                <th>Email address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editContactDetails.allContacts?.length ? (
                editContactDetails.allContacts.map(
                  (
                    { address, city, email, phoneNumber, pinCode, status },
                    ind
                  ) => (
                    <tr key={ind}>
                      <td>{address + " " + city + " " + pinCode}</td>
                      <td>{phoneNumber}</td>
                      <td>{email}</td>
                      <td>
                        <span className="action_icon_container">
                          <span onClick={() => handleEditShow()}>
                            <img
                              src={IMAGE_PATHS.edit_icon_table}
                              loading="lazy"
                              alt="Logo"
                              width={"20px"}
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>Edit Contact</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">
                    About Contact Us
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="aboutContactUs"
                    value={editContactDetails.aboutContactUs}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <h1>Office</h1>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Address"
                    name="address"
                    value={editContactDetails.address}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    value={editContactDetails.city}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Pin Code</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Pin Code"
                    name="pinCode"
                    value={editContactDetails.pinCode}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <h1>Call Us</h1>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Number"
                    name="contact"
                    value={editContactDetails.contact}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    value={editContactDetails.email}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default ContactUs;
