import React from "react";
import { onChangeHandleForOption } from "../../../Utils/CommonApi";

const HandleOptionComponent = ({
  underguideLinedetails,
  setunderguideLinedetails,
  productQuestionEntity,
}) => {
  const underGuideLineOnChangeHandleForOption = (event) => {
    onChangeHandleForOption(
      event,
      underguideLinedetails,
      setunderguideLinedetails
    );
  };

  return (
    <div className="borderBoxCheckBox">
      <fieldset className="checkbox-group">
        {underguideLinedetails[productQuestionEntity.questionLabelCode]?.map(
          (elem, ind) => (
            <div className="checkbox" key={ind}>
              {Object.entries(elem).map(([key, value]) => (
                <label className="checkbox-wrapper" key={key}>
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    name={productQuestionEntity.questionLabelCode}
                    value={key} // Assuming 'key' is the label for checkbox
                    onChange={(event) =>
                      underGuideLineOnChangeHandleForOption(event)
                    }
                    checked={value} // Assuming 'value' is the checked state
                  />
                  <span className="checkbox-tile">
                    <span className="checkbox-label">{key}</span>
                  </span>
                </label>
              ))}
            </div>
          )
        )}
      </fieldset>
    </div>
  );
};

export default HandleOptionComponent;
