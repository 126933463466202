import React, { useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
const baseUrl = process.env.REACT_APP_BASE_URL;

const FinishAgent = (props) => {
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const sendMailToUpdatePassword = async () => {
    let staffDetails = JSON.parse(sessionStorage.getItem("staffDetails"));

    let sendMailPayload = {
      firstName: staffDetails.firstName,
      lastName: staffDetails.lastName,
      userEmail: staffDetails.userEmail,
      url: `${
        process.env.REACT_APP_MAIN_PATH
      }${baseUrl}/setPassword/reset/${aesUtil.encrypt(
        JSON.stringify(staffDetails.userId)
      )}`,
      isForgot: 0,
    };

    try {
      setisLoader(true);
      await http.post("/sendEmail", sendMailPayload);
      navigate(`${baseUrl}/UserManagementAgent`);

      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };
  return (
    <LayoutAgent sideBarActive={24}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Add a User</h1>
          <div>
            <Link to={`${baseUrl}/AgentDashboard`}>Dashboard</Link>
            <span>/</span>
            <Link to={`${baseUrl}/UserManagementAgent`}>User Management</Link>
            <span>/ Add Sub-Agent</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <div className="carriersLeft">
            <div className="select_plan_process">
              <ul className="displayCarriersInnerLeft">
                <li className="completed">
                  <div>
                    <img
                      src={IMAGE_PATHS.blueCirleTick}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      height={"31px"}
                    />
                    <span>Add User</span>
                  </div>
                </li>
                <li className="completed">
                  <div>
                    <img
                      src={IMAGE_PATHS.blueCirleTick}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Subscription</span>
                  </div>
                </li>
                <li className="active">
                  <div>
                    <img
                      src={IMAGE_PATHS.finish_agent_active}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Finish</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="carriersRight unerGuide">
            <div className="modal_body">
              <div className="Container357">
                <div className="SuccessBlueTicIcon_outer">
                  <div className="SubscriptionAgentBorder">
                    <img
                      src={IMAGE_PATHS.SuccessBlueTicIconBig}
                      loading="lazy"
                      alt="Logo"
                      width={"53px"}
                    />
                    <h3 className="mt-4">Payment successful!</h3>
                    <p>
                      Payment has been successfully made and a link has been
                      shared with the sub user to update the profile
                    </p>
                    <div className="w-100 mt-4">
                      <div
                        className={props.sideBarActive === 24 ? "active" : ""}
                        onClick={() => sendMailToUpdatePassword()}
                      >
                        <Button className="login sign_in_button mt_20">
                          Go to User Management
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default FinishAgent;
