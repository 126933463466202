import React, { memo, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ApiClient from "../ApiClient/ApiClient";
const baseUrl = process.env.REACT_APP_BASE_URL;

const PrivateRoutes = ({ children, allowedRoles }) => {
  const { getToken, isAccessTokenExpired } = ApiClient();
  const navigate = useNavigate();
  const getLoginRedirectPath = useMemo(() => {
    return (userRole) => {
      switch (userRole) {
        case "1": // Agent
        case "2": // Staff
          return `${baseUrl}`;
        case "3": // Admin
          return `${baseUrl}/Admin`;
        default:
          return baseUrl;
      }
    };
  }, []);

  useEffect(() => {
    const userRole = sessionStorage.getItem("userRole");

    if (isAccessTokenExpired()) {
      sessionStorage.clear();
      const loginRedirectPath = getLoginRedirectPath(userRole);
      navigate(loginRedirectPath);
    }

    if (!allowedRoles?.includes(userRole)) {
      const loginRedirectPath = getLoginRedirectPath(userRole);
      navigate(loginRedirectPath);
    }
    return () => {};
    // eslint-disable-next-line
  }, [getLoginRedirectPath, navigate, allowedRoles]);

  return getToken() ? children : <Navigate to="/" />;
};

export default memo(PrivateRoutes);
