import React from "react";
import { Button, Modal } from "react-bootstrap";
import IMAGE_PATHS from "../../constants";

const StateModalWIthRecent = ({
  stateShow,
  handleStateClose,
  isLoader,
  Loader,
  recentStates,
  handleCarrierSearchOnChange,
  searchdetails,
  stateModalOnClickHandle,
}) => {
  return (
    <Modal
      show={stateShow}
      onHide={handleStateClose}
      className="AddNewCategoryModal"
      keyboard={false}
    >
      <Modal.Body>
        <div className="modal_body newStateModal">
          <div className="row">
            <div className="twoPartCommon align-items-center mb-3">
              <div className="left" style={{ fontWeight: 600 }}>
                Change States
              </div>
              <div className="right">
                <img
                  src={IMAGE_PATHS.Close_modal}
                  loading="lazy"
                  alt="Logo"
                  width={"31px"}
                  onClick={handleStateClose}
                  className="cursorPointer"
                />
              </div>
            </div>
          </div>

          <div className="row">
            {isLoader && <Loader />}
            {recentStates?.length ? (
              <div>Recent search states</div>
            ) : (
              <div>No Data Found</div>
            )}
            <div className="SelectedStateRadio SelectedState mt-2">
              <div className="row">
                <fieldset className="checkbox-group newStateModalOne">
                  {recentStates?.map(({ stateId, stateCode }) => (
                    <div className="checkbox" key={stateId}>
                      <label className="checkbox-wrapper">
                        <input
                          type="radio"
                          className="checkbox-input"
                          name="state"
                          id={stateId}
                          value={stateCode}
                          onChange={(event) =>
                            handleCarrierSearchOnChange(event)
                          }
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-label">{stateCode}</span>
                        </span>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
            <div>Other states</div>

            <div className="SelectedStateRadio SelectedState mt-2">
              <div className="row">
                <fieldset className="checkbox-group newStateModalOne">
                  {searchdetails.allStates.length ? (
                    searchdetails.allStates.map(({ stateId, stateCode }) => (
                      <div
                        className="checkbox"
                        key={stateId}
                        style={{
                          display: recentStates?.find((obj) => {
                            return obj?.stateCode === stateCode;
                          })
                            ? "none"
                            : "block",
                        }}
                      >
                        <label className="checkbox-wrapper">
                          <input
                            type="radio"
                            className="checkbox-input"
                            name="state"
                            id={stateId}
                            value={stateCode}
                            onChange={(event) =>
                              handleCarrierSearchOnChange(event)
                            }
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-label">{stateCode}</span>
                          </span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <div>No Data Found</div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>

          <div className="buttn_row mt_20">
            <Button
              variant="primary"
              type="submit"
              className="login sign_in_button"
              onClick={() => stateModalOnClickHandle()}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StateModalWIthRecent;
