import React from "react";
import { useNavigate } from "react-router-dom";

const DashboardBox = ({ Link, searchdetails, IMAGE_PATHS, boxValue }) => {
  const navigate = useNavigate();
  return (
    <li
      className="whiteBoxShadow"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(Link);
      }}
    >
      <div className="twoPartCommon">
        <div>
          <ul className="AdminDashboardFourUL">
            <li className="AdminDashboardFourBoxHead">{boxValue}</li>
            <li className="AdminDashboardFourBoxNumber">{searchdetails}</li>
          </ul>
        </div>
        <div>
          <img src={IMAGE_PATHS} loading="lazy" alt="Logo" width={"60px"} />
        </div>
      </div>
    </li>
  );
};

export default DashboardBox;
