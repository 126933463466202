import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import Paginations from "../../../components/Pagination";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import SearchHistoryCalendar from "../../../components/Calendar/SearchHistoryCalendar";
import AllProductsComponent from "../../../components/Products/AllProductsComponent";
import SearchHistoryTable from "../../../components/SearchHistoryTable";
import SearchHistoryDetailsModal from "../../../components/Modal/SearchHistoryDetailsModal";
import { PostCallsAgent, PostCallsCommon } from "../../../ApiClient/PostCall";
import { getIndividualHistory } from "../../../Utils/CommonApi";
const baseUrl = process.env.REACT_APP_BASE_URL;

const SearchHistoryAgent = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let date = JSON.parse(sessionStorage.getItem("todaySearch"));
  const [loading, setLoading] = useState(false);
  const { http } = ApiClient();
  const postCommon = PostCallsCommon(http, setLoading, Swal, navigate, "agent");
  const postAgent = PostCallsAgent(http, setLoading, Swal, navigate);

  const [searchHistoryDetails, setsearchHistoryDetails] = useState({
    count: 1,
    allProducts: [],
    customerName: null,
    productName: null,
    date: date?.date || [],
    searchHistoryLists: [],
  });
  const [currCust, setcurrCust] = useState({
    agency: "",
    product: "",
    productId: "",
    createdOn: "",
    matchedCarrier: "",
    createdBy: "",
    lastUpdatedOn: "",
    reviewdcarrier: "",
    state: "",
    stateId: "",
    customerName: "",
  });
  const [oneHist, setOneHist] = useState([
    {
      date: "2023-11-25",
      searchQuestionAnswer: [
        {
          questionAnswer: {
            additionalProp1: "string",
            additionalProp2: "string",
            additionalProp3: "string",
          },
          matchedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
          confermedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
        },
      ],
    },
  ]);

  const ViewHandleClose = () => setViewShow(false);
  const [viewShow, setViewShow] = useState(false);

  const ViewHandleShow = (obj) => {
    setViewShow(true);
    getIndividualHistory(
      setcurrCust,
      obj,
      setLoading,
      http,
      setOneHist,
      setViewShow,
      setLoading,
      navigate,
      Swal,
      "agent"
    );
  };

  const searchHistoryHandleOnChange = (event) => {
    let { value, name } = event.target;
    setsearchHistoryDetails({
      ...searchHistoryDetails,
      [name]: value || null,
    });
  };

  const handleRepeatSearch = async (list) => {
    let data = await postAgent.searchBySearchIdAgent({
      data: aesUtil.encrypt(`${list.searchId}`),
    });

    let agentDashboardSearchDetails = {
      customerName: list.customerName,
      product: { proName: list.product, proId: list.productId },
      state: { stateId: list.stateId, stateName: list.stateCode },
    };
    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(agentDashboardSearchDetails)
    );
    navigate(`${baseUrl}/AgentDashboardSearch`, {
      state: { repeatSearchData: data },
    });
  };

  const searchHistoryOnClickHandle = async () => {
    let payload = {
      customerName: searchHistoryDetails.customerName,
      productId: parseInt(searchHistoryDetails.productName),
      startDate: searchHistoryDetails.date[0] || null,
      endDate: searchHistoryDetails.date[1] || null,
    };
    setLoading(true);
    let data = await postAgent.getAgentSearchHistory(page, payload);
    setsearchHistoryDetails({
      ...searchHistoryDetails,
      searchHistoryLists: data,
    });
    setLoading(false);

    sessionStorage.removeItem("todaySearch");
  };

  const getAllData = async () => {
    let payload = {
      customerName: searchHistoryDetails.customerName,
      productId: parseInt(searchHistoryDetails.productName),
      startDate: searchHistoryDetails.date[0] || null,
      endDate: searchHistoryDetails.date[1] || null,
    };
    setLoading(true);
    let data = await postCommon.getAllProductCommon();
    setsearchHistoryDetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      allProducts: data,
    }));
    let res = await postAgent.getAgentSearchHistory(page, payload);
    setLoading(false);
    setsearchHistoryDetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      searchHistoryLists: res,
    }));
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [page]);

  return (
    <LayoutAgent sideBarActive={22}>
      {loading && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Search History</h1>
          <div>
            <Link to={`${baseUrl}/AgentDashboard`}>Dashboard</Link>
            <span>/ Search History</span>
          </div>
        </div>
        <div className="mt-3">
          <div className="searchHistoryFormBar">
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  name="customerName"
                  value={searchHistoryDetails.customerName || ""}
                  onChange={(event) => searchHistoryHandleOnChange(event)}
                />
              </Form.Group>
            </div>

            <AllProductsComponent
              carrierdetailsOnChangeHandle={searchHistoryHandleOnChange}
              allProducts={searchHistoryDetails?.allProducts}
            />
            <SearchHistoryCalendar
              searchHistoryDetails={searchHistoryDetails}
              setSearchHistoryDetails={setsearchHistoryDetails}
            />
            <div>
              <Button
                className="button_blue"
                onClick={() => searchHistoryOnClickHandle()}
              >
                <img
                  src={IMAGE_PATHS.search_icon_white}
                  loading="lazy"
                  alt="Logo"
                  width={"13px"}
                />
                Search
              </Button>
            </div>
          </div>
        </div>

        <SearchHistoryTable
          searchHistoryLists={searchHistoryDetails?.searchHistoryLists}
          ViewHandleShow={ViewHandleShow}
          loading={loading}
          handleRepeatSearch={handleRepeatSearch}
        />
        {searchHistoryDetails?.searchHistoryLists.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={searchHistoryDetails.searchHistoryLists[0].count}
          />
        ) : null}

        <SearchHistoryDetailsModal
          viewShow={viewShow}
          ViewHandleClose={ViewHandleClose}
          currCust={currCust}
          oneHist={oneHist}
        />
      </div>
    </LayoutAgent>
  );
};

export default SearchHistoryAgent;
