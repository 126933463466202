import React from "react";
import IMAGE_PATHS from "../../constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import ApiClient from "../../ApiClient/ApiClient";
import { PostCallsAdmin } from "../../ApiClient/PostCall";
import CommonHeader from "../../components/CommonHeader";

const Header = () => {
  let profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));

  let profileImage = profileDetails?.profileImage || IMAGE_PATHS.userBlankIcon;
  const [show, setShow] = useState(false);
  const [, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();

  const postAdmin = PostCallsAdmin(http, setIsLoader, Swal, navigate);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await postAdmin.logoutAdmin();
  };

  return (
    <CommonHeader
      profileDetails={profileDetails}
      profileImage={profileImage}
      show={show}
      handleShow={handleShow}
      handleClose={handleClose}
      location={null}
      portalTypeLocation={"admin"}
    />
  );
};

export default Header;
