import React from "react";
import { dependableOnChange } from "../../../Utils/CommonApi";

const OpenDependentBoxComponent = ({
  dependableDetails,
  setDependableDetails,
  productQuestionEntity,
}) => {
  const handleDependableOnChange = (event) => {
    dependableOnChange(event, dependableDetails, setDependableDetails);
  };
  return (
    <div
      className="SelectedState"
      style={{
        marginTop: "10px",
        alignItems: "center",
        display:
          dependableDetails[
            `${productQuestionEntity?.questionLabel}_${productQuestionEntity?.productQuestionId}1`
          ]?.length > 0
            ? "none"
            : "flex",
      }}
    >
      <label
        style={{
          marginRight: "15px",
        }}
      >
        Any dependend question?
      </label>
      <div className="SelectedStateRadio">
        <fieldset className="checkbox-group">
          <div className="checkbox">
            <label className="checkbox-wrapper">
              <input
                type="checkbox"
                className="checkbox-input"
                name={`${productQuestionEntity?.questionLabel}_${productQuestionEntity?.productQuestionId}1`}
                onChange={(event) => handleDependableOnChange(event)}
              />
              <span className="checkbox-tile plus_blue">
                <span className="checkbox-label">+</span>
              </span>
            </label>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default OpenDependentBoxComponent;
