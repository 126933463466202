import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";

import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import moment from "moment";
import ReportCalendar from "../../../components/Calendar/Calendar";
import ProductWiseSearch from "../../../components/SearchByAll/ProductWiseSearch";
import MonthWIseSearchChart from "../../../components/SearchByAll/MonthWIseSearchChart";
import { PostCallsAgent, PostCallsCommon } from "../../../ApiClient/PostCall";

const ReportAgent = () => {
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const postAgent = PostCallsAgent(http, setIsLoader, Swal, navigate);

  const postCommon = PostCallsCommon(
    http,
    setIsLoader,
    Swal,
    navigate,
    "agent"
  );

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
  });

  const searchHistoryHandleOnChangeCalendar = (event) => {
    if (event) {
      let startDate = moment(event[0]["$d"]).format("YYYY-MM-DD");
      let endDate = moment(event[1]["$d"]).format("YYYY-MM-DD");

      getMonthWiseSearch(startDate, endDate);
      getProductWiseSearch(startDate, endDate);
      getUserWiseSearch(startDate, endDate);
    }
  };

  const getMonthWiseSearch = async (startDate, endDate) => {
    let data = await postAgent.searchUserByMonthAgent(startDate, endDate);
    setReportLists((prevLists) => ({
      ...prevLists,
      monthWise: data,
    }));
  };
  const getProductWiseSearch = async (startDate, endDate) => {
    setIsLoader(true);
    let data = await postCommon.getSearchUserByProductCommon(
      startDate,
      endDate
    );
    setReportLists((prevLists) => ({
      ...prevLists,
      productWise: data,
    }));
    setIsLoader(false);
  };

  const getUserWiseSearch = async (startDate, endDate) => {
    let data = await postAgent.searchUserWiseOnlyAgent(startDate, endDate);
    setReportLists((prevLists) => ({
      ...prevLists,
      userWise: data,
    }));
  };

  useEffect(() => {
    getMonthWiseSearch("", "");
    getProductWiseSearch("", "");
    getUserWiseSearch("", "");
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAgent sideBarActive={25}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="head_bread">
          <div className="searchHistoryFormBar">
            <h1>Report</h1>
            <ReportCalendar
              searchHistoryHandleOnChangeCalendar={
                searchHistoryHandleOnChangeCalendar
              }
            />
          </div>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Report</span>
          </div>
        </div>
        <div className="blankContainer">
          <div>
            <ul className="twoPartCommon two_50_50">
              <MonthWIseSearchChart reportLists={reportLists} />

              <ProductWiseSearch reportLists={reportLists} />
            </ul>
          </div>
          <div className="whiteBoxShadow mt-3">
            <h3>User Wise Search</h3>

            {reportLists.userWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  layout="vertical"
                  data={reportLists.userWise}
                  barSize={6}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis
                    type="number"
                    style={{
                      fontSize: "10px",
                      color: "gray",
                    }}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, reportLists.userWise.length + 8]}
                  />
                  <YAxis
                    dataKey="name"
                    type="category"
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                    axisLine={false}
                    tickLine={false}
                  />
                  {/* <Tooltip /> */}

                  <Bar
                    dataKey="count"
                    fill="#0AB39C"
                    style={{ borderRadius: "20px" }}
                    radius={[0, 5, 5, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default ReportAgent;
