import React from "react";
import { Modal, Table } from "react-bootstrap";
import { DateFormat } from "./DateFormat/DateFormat";
import IMAGE_PATHS from "../constants";
import { lastLocation } from "../Utils/Locations";
import { useLocation } from "react-router-dom";

const ReviewsTable = ({
  editReviewsDetails,
  handleShow,
  show,
  handleClose,
  editActiveInActiveOnChangeHandle,
  handleEditShow,
  deleteReviewOnClickHandle,
}) => {
  const location = useLocation();
  return (
    <div className="table_area">
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Reviewer Name</th>
            <th>Review Date</th>
            <th>Review Message</th>
            <th>Rating</th>
            <th>Status</th>
            {lastLocation(location) === "ReviewsAgent" && <th>Action</th>}
          </tr>
        </thead>

        <tbody>
          {editReviewsDetails?.allReviews?.length ? (
            editReviewsDetails.allReviews.map((reviews) => (
              <tr key={reviews.reviewEntityId}>
                <td>{reviews.reviewerName}</td>
                <td>{DateFormat(reviews.reviewDate)}</td>
                <td>{reviews.reviewMessage}</td>
                <td>{reviews.reviewNumber}</td>

                <td>
                  <span
                    className={
                      reviews.status === 1
                        ? "active_green cursorPointer"
                        : "pending_red cursorPointer"
                    }
                    onClick={() =>
                      handleShow(reviews.reviewEntityId, reviews.status)
                    }
                  >
                    {reviews.status === 1 ? "Active" : "InActive"}
                  </span>
                </td>
                {lastLocation(location) === "ReviewsAgent" && (
                  <td>
                    <span className="action_icon_container">
                      <span onClick={() => handleEditShow(reviews, "Edit")}>
                        <img
                          src={IMAGE_PATHS.edit_icon_table}
                          loading="lazy"
                          alt="Logo"
                          width={"20px"}
                        />
                      </span>

                      <span
                        onClick={() =>
                          deleteReviewOnClickHandle(reviews.reviewEntityId)
                        }
                      >
                        <img
                          src={IMAGE_PATHS.DeleteIconGray}
                          loading="lazy"
                          alt="Logo"
                          height={"18px"}
                        />
                      </span>
                    </span>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td></td>
              <td></td>
              <td>No Data Found</td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal
        show={show}
        onHide={handleClose}
        className="switchModal"
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>
            Are you sure,
            <br />
            you want to inactive agency?{" "}
          </h3>
          <div className="wrap">
            <label htmlFor="switchA1" className="switch-item">
              <input
                type="checkbox"
                name="editIsActive"
                className="control"
                defaultChecked={editReviewsDetails.initialCheckboxState}
                onChange={() => editActiveInActiveOnChangeHandle()}
              />
              <span className="label"></span>
              <span className="label_L"></span>
            </label>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReviewsTable;
