import React from "react";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";

const AddMoreDependentButton = ({
  handleAddMoreDependent,
  productQuestionEntity,
}) => {
  return (
    <Button
      className="button_blueBorder mt-2"
      onClick={() => handleAddMoreDependent(productQuestionEntity)}
    >
      <img
        src={IMAGE_PATHS.phPlusBoldIcon}
        loading="lazy"
        alt="Logo"
        width={"10px"}
      />
      Add More Dependend
    </Button>
  );
};

export default AddMoreDependentButton;
