import React from "react";
import { Modal } from "react-bootstrap";

const FullMatchCriteriaModal = ({ show3, handleClose3, searchDetails }) => {
  return (
    <Modal
      show={show3}
      onHide={handleClose3}
      className="disLikeModal greenRedBoxModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="orangeRadio">
        <p className="imageArea">
          <img
            src={searchDetails.matchCriteriaLogo}
            loading="lazy"
            alt="Logo"
            width={"148px"}
            height={"43px"}
          />
        </p>
        <p className="headingArea">
          Match criteria {searchDetails.matchCriteriaPercentage || 0}%
        </p>

        {searchDetails.matchQuestions.length ? (
          searchDetails.matchQuestions.map(({ question, match }, ind) => (
            <div key={ind}>
              <div className={match === 1 ? "greenBoxModal" : "redBoxModal"}>
                {question}
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>No Data Found</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FullMatchCriteriaModal;
