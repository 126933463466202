import React from "react";
import { Form } from "react-bootstrap";

const AllProductsComponent = ({
  carrierdetailsOnChangeHandle,
  allProducts,
}) => {
  return (
    <div>
      <Form.Select
        aria-label="Default select example"
        name="category"
        onChange={(event) => carrierdetailsOnChangeHandle(event)}
      >
        <option hidden>Category</option>
        {allProducts?.length ? (
          allProducts?.map(({ productName, productId }) => (
            <option key={productId} value={productId}>
              {productName}
            </option>
          ))
        ) : (
          <option disabled>No Products</option>
        )}
      </Form.Select>
    </div>
  );
};

export default AllProductsComponent;
