let allAnswers = [];

let filteredAnswer = (value, type, questionId) => {
  const existingAnswer = allAnswers.find(
    (answer) => answer.questionId === questionId
  );

  if (!existingAnswer) {
    allAnswers = [...allAnswers, { questionId, type, answer: value }];
  } else {
    existingAnswer.answer = value;
  }
  return allAnswers;
};

const convertPngTosvg = (data) => {
  let product = data && [...data];

  product?.forEach((item) => {
    if (item?.productIcon && item?.productIcon?.productIcon) {
      let data = item.productIcon.productIcon.split(",");
      data[0] = "data:image/svg+xml;base64";
      item.productIcon.productIcon = data.join(",");
    }
  });
  return product;
};

const convertAllIconIntoBase64 = async (allAddNewCategoryIcons, Swal) => {
  let firstString = "data:image/svg+xml;base64,";
  try {
    let array = [];
    for (let icon of allAddNewCategoryIcons) {
      const response = await fetch(icon);
      const svgText = await response.text();
      const base64String = btoa(svgText);
      let result = firstString.concat(base64String);
      array.push(result);
    }
    return array;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Sorry, Something went wrong!",
      confirmButtonText: "OK",
    });
    return null;
  }
};

const convertToBase64 = async (photoURL) => {
  const response = await fetch(photoURL);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
let uploadImage = (event, Swal, setImageDetails, imageDetails, name) => {
  let file = event.target.files[0];
  if (file) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      Swal.fire({
        icon: "error",
        // title: "Invalid file type",
        text: "Please upload a valid image file (jpg, jpeg, png).",
      });
      event.target.value = "";
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      console.log(reader.result, "DDDDD");

      setImageDetails({
        ...imageDetails,
        [name]: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
};

export {
  filteredAnswer,
  convertPngTosvg,
  uploadImage,
  convertToBase64,
  convertAllIconIntoBase64,
};
