import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/css/style.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Agent/Login/Login";
import SignUp from "./pages/Agent/SignUp/SignUp";
import AgentUpdateProfile from "./pages/AgentUpdateProfile/AgentUpdateProfile";
import SelectYourPlan from "./pages/Agent/SelectYourPlan/SelectYourPlan";
import SelectPaymentType from "./pages/Agent/SelectPaymentType/SelectPaymentType";
import SaveCard from "./pages/Agent/SaveCard/SaveCard";
import SaveBankAccount from "./pages/Agent/SaveBankAccount/SaveBankAccount";
import AgentDbSuccess from "./pages/Agent/AgentDbSuccess/AgentDbSuccess";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import Products from "./pages/AdminDashboard/Products/Products";
import HomePrpducts from "./pages/AdminDashboard/Products/HomePrpducts";

import AdminLogin from "./pages/Admin/Login/AdminLogin";
import AgentDashoard from "./pages/AgentDashboard/AgentDashoard";
import Carriers from "./pages/AdminDashboard/Carriers/Carriers";
import CarriersDetails from "./pages/AdminDashboard/Carriers/CarriersDetails";
import UnderwritingGuidelines from "./pages/AdminDashboard/Carriers/UnderwritingGuidelines";
import AddNnewcarrierPage from "./pages/AdminDashboard/Carriers/AddNnewcarrierPage";
import UnderGuide from "./pages/AdminDashboard/Carriers/UnderGuide";
import AddNewCarrierFinish from "./pages/AdminDashboard/Carriers/AddNewCarrierFinish";
import ManageSubscription from "./pages/AdminDashboard/ManageSubscription/ManageSubscription";
import Agency from "./pages/AdminDashboard/Agency/Agency";
import ViewAgency from "./pages/AdminDashboard/Agency/ViewAgency";
import SearchHistory from "./pages/AdminDashboard/SearchHistory/SearchHistory";
import Report from "./pages/AdminDashboard/Report/Report";
import MonthSearchHistory from "./pages/AdminDashboard/Report/MonthSearchHistory";
import Payments from "./pages/AdminDashboard/Payments/Payments";
import UserManagement from "./pages/AdminDashboard/UserManagement/UserManagement";
import AgentDashboardSearch from "./pages/AgentDashboard/AgentDashboardSearch/AgentDashboardSearch";
import UserManagementAgent from "./pages/AgentDashboard/UserManagementAgent/UserManagementAgent";
import AddUserAgent from "./pages/AgentDashboard/UserManagementAgent/AddUserAgent";
import SubscriptionAgent from "./pages/AgentDashboard/UserManagementAgent/SubscriptionAgent";
import FinishAgent from "./pages/AgentDashboard/UserManagementAgent/FinishAgent";
import CarriersAgent from "./pages/AgentDashboard/CarriersAgent/CarriersAgent";
import ReportAgent from "./pages/AgentDashboard/ReportAgent/ReportAgent";
import ReportDetailsAgent from "./pages/AgentDashboard/ReportAgent/ReportDetailsAgent";
import BillingManagement from "./pages/AgentDashboard/BillingManagement/BillingManagement";
import BillingManagementEdit from "./pages/AgentDashboard/BillingManagement/BillingManagementEdit";
import SearchHistoryAgent from "./pages/AgentDashboard/SearchHistoryAgent/SearchHistoryAgent";
import ProfileSettingsAdmin from "./pages/AdminDashboard/ProfileSettingsAdmin/ProfileSettingsAdmin";
import ProfileSettingsAgent from "./pages/AgentDashboard/ProfileSettingsAgent/ProfileSettingsAgent";
import ResetPasswordAgent from "./pages/AgentDashboard/ProfileSettingsAgent/ResetPasswordAgent";
import ResetPasswordAdmin from "./pages/AdminDashboard/ProfileSettingsAdmin/ResetPasswordAdmin";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import SetPassword from "./pages/AgentDashboard/UserManagementAgent/SetPassword";
import AdminDashboardSearch from "./pages/AdminDashboard/AdminDashboardSearch/AdminDashboardSearch";
import DebitBalanace from "./components/DebitBalanace";
import Home from "./pages/Main/Home/Home";
import StartATrial from "./pages/Main/StartATrial/StartATrial";
import LoginMain from "./pages/Main/LoginMain/LoginMain";
import RegistrationMain from "./pages/Main/RegistrationMain/RegistrationMain";
import BookADemo from "./pages/Main/BookADemo/BookADemo";
import CarriersMain from "./pages/Main/CarriersMain/CarriersMain";
import AgentMain from "./pages/Main/AgentMain/AgentMain";
import AboutMain from "./pages/Main/AboutMain/AboutMain";
import ProductsMain from "./pages/Main/ProductsMain/ProductsMain";
import FAQMain from "./pages/Main/FAQMain/FAQMain";
import ContactMain from "./pages/Main/ContactMain/ContactMain";
import FAQTopics from "./pages/AdminDashboard/FAQTopics/FAQTopics";
import FAQDetails from "./pages/AdminDashboard/FAQDetails/FAQDetails";
import ContactUs from "./pages/AdminDashboard/ContactUs/ContactUs";
import Reviews from "./pages/AdminDashboard/Reviews/Reviews";
import ReviewsAgent from "./pages/AgentDashboard/ReviewsAgent/ReviewsAgent";
import Teams from "./pages/AdminDashboard/Teams/Teams";

function App() {
  const env = process.env.REACT_APP_ENV;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrlMain = process.env.REACT_APP_BASE_URL_MAIN;
  return (
    <>
      <BrowserRouter>
        <Routes>
          {(env === "PROD" || env === "staging") && (
            <Route
              exact
              path={env === "staging" ? baseUrlMain : "/"}
              element={env === "PROD" ? < Home /> : <Navigate to={baseUrl} />}
            />
          )}
          <Route path={`${baseUrlMain}`} element={<Home />} />

          <Route path={`${baseUrl}`} element={<Login />} />

          <Route path={`${baseUrl}/Admin`} element={<AdminLogin />} />

          <Route path={`${baseUrl}/SignUp`} element={<SignUp />} />

          <Route
            path={`${baseUrl}/AgentUpdateProfile`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentUpdateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SelectYourPlan`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SelectYourPlan />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SelectPaymentType`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SelectPaymentType />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SaveCard`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SaveCard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SaveBankAccount`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SaveBankAccount />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/setPassword/:flag/:id`}
            element={
              // <PrivateRoute allowedRoles={["1"]}>
              <SetPassword />
              // </PrivateRoute>
            }
          />

          <Route
            path={`${baseUrl}/AgentDbSuccess`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDbSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/AdminDashboard`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/AdminDashboardSearch`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AdminDashboardSearch />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Products`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/:productName/:productId`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <HomePrpducts />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Carriers`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Carriers />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/CarriersDetails/:id`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <CarriersDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/CarriersDetails/:carrierId/UnderwritingGuidelines/:productId`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UnderwritingGuidelines />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/AddNnewcarrier`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AddNnewcarrierPage />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/UnderGuide`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UnderGuide />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/AddNewCarrierFinish`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AddNewCarrierFinish />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/ManageSubscription`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ManageSubscription />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/ManageSubscription`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ManageSubscription />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Agency`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Agency />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/ViewAgency`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ViewAgency />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/SearchHistory`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <SearchHistory />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Report`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/MonthSearchHistory`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <MonthSearchHistory />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Payments`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Payments />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/UserManagement`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/FAQTopics`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <FAQTopics />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/FAQDetails`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <FAQDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/ContactUs`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ContactUs />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Reviews`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Reviews />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/Admin/Teams`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Teams />
              </PrivateRoute>
            }
          />

          <Route
            path={`${baseUrl}/AgentDashboard`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDashoard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/AgentDashboardSearch`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDashboardSearch />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/UserManagementAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <UserManagementAgent />
              </PrivateRoute>
            }
          />

          <Route
            path={`${baseUrl}/AddUserAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AddUserAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SubscriptionAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SubscriptionAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/FinishAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <FinishAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/CarriersAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <CarriersAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ReportAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReportAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ReportDetailsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReportDetailsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/BillingManagement`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <BillingManagement />
              </PrivateRoute>
            }
          />

          <Route
            path={`${baseUrl}/BillingManagementEdit`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <BillingManagementEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/SearchHistoryAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SearchHistoryAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ReviewsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReviewsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ProfileSettingsAdmin`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ProfileSettingsAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ProfileSettingsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ProfileSettingsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ResetPasswordAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ResetPasswordAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/DebitBalance`}
            element={
              // <PrivateRoute allowedRoles={["1", "2"]}>
              <DebitBalanace />
              // </PrivateRoute>
            }
          />
          <Route
            path={`${baseUrl}/ResetPasswordAdmin`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ResetPasswordAdmin />
              </PrivateRoute>
            }
          />

          <Route
            path={`${baseUrlMain}/StartATrial`}
            element={<StartATrial />}
          />
          <Route path={`${baseUrlMain}/LoginMain`} element={<LoginMain />} />
          <Route
            path={`${baseUrlMain}/RegistrationMain`}
            element={<RegistrationMain />}
          />
          <Route path={`${baseUrlMain}/BookADemo`} element={<BookADemo />} />
          <Route
            path={`${baseUrlMain}/CarriersMain`}
            element={<CarriersMain />}
          />
          <Route path={`${baseUrlMain}/AgentMain`} element={<AgentMain />} />
          <Route path={`${baseUrlMain}/AboutMain`} element={<AboutMain />} />
          <Route
            path={`${baseUrlMain}/ProductsMain`}
            element={<ProductsMain />}
          />
          <Route path={`${baseUrlMain}/FAQMain`} element={<FAQMain />} />
          <Route
            path={`${baseUrlMain}/ContactMain`}
            element={<ContactMain />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
