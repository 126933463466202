import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import IMAGE_PATHS from "../../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
const baseUrl = process.env.REACT_APP_BASE_URL;

const ViewAgency = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [viewAgencyDetails, setViewAgencyDetails] = useState({
    agencyDetails: {},
    staffUserDetails: [],
  });
  const { http } = ApiClient();

  const getAgentDetails = () => {
    setIsLoader(true);

    http
      .get(
        `/getAgentdetails/${aesUtil.encrypt(
          JSON.stringify(location?.state?.agentId)
        )}`,
        {}
      )
      .then((res) => {
        setViewAgencyDetails((prevViewAgencyDetails) => ({
          ...prevViewAgencyDetails,
          agencyDetails: res.data,
        }));
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };
  const getAllStaffUserDetails = () => {
    setIsLoader(true);

    http
      .get(
        `/getAllStafUserDetails/${aesUtil.encrypt(
          JSON.stringify(location?.state?.agentId)
        )}`,
        {}
      )
      .then((res) => {
        setViewAgencyDetails((prevViewAgencyDetails) => ({
          ...prevViewAgencyDetails,
          staffUserDetails: res.data,
        }));
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllStaffUserDetails();
    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (viewAgencyDetails?.staffUserDetails) {
      getAgentDetails();
    }
    // eslint-disable-next-line
  }, [viewAgencyDetails.staffUserDetails]);

  return (
    <Layout sideBarActive={5}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>
            <Link to={`${baseUrl}/Admin/Agency`}>
              <img
                src={IMAGE_PATHS.ep_back}
                loading="lazy"
                alt="Logo"
                // width={"57px"}
              />
            </Link>
            Agency Details
          </h1>
          <div>
            <Link to={`${baseUrl}/Admin/Agency`}>Agency</Link>
            <span>/ Agency</span>
          </div>
        </div>
        <div className="white_data_box">
          <h2>
            {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
              viewAgencyDetails.agencyDetails.organisationName}
            <span
              className={
                Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
                viewAgencyDetails.agencyDetails.agentStatus === 1
                  ? "active_green"
                  : "pending_red"
              }
            >
              {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
              viewAgencyDetails.agencyDetails.agentStatus === 1
                ? "Active"
                : "Pending"}
            </span>
          </h2>
          <Table responsive className="whiteTableTd AgencyDetailsTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email address</th>
                <th>Subscription Type</th>
                <th>Created on</th>
                <th>Expire on</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
                    viewAgencyDetails.agencyDetails.firstName +
                      " " +
                      viewAgencyDetails.agencyDetails.lastName}
                </td>
                <td>
                  {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
                    viewAgencyDetails.agencyDetails.email}
                </td>
                <td>
                  {Object.keys(viewAgencyDetails.agencyDetails).length > 0 ? (
                    viewAgencyDetails.agencyDetails.subscriptionPlanName
                  ) : (
                    <span style={{ textAlign: "center" }}>No Data Found</span>
                  )}
                </td>
                <td>
                  {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
                    DateFormat(viewAgencyDetails.agencyDetails.ceatedOn)}
                </td>
                <td>
                  {Object.keys(viewAgencyDetails.agencyDetails).length > 0 &&
                    DateFormat(viewAgencyDetails.agencyDetails.expireOn)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>
                  <span className="shorting_container">Team Member</span>
                </th>
                <th>
                  <span className="shorting_container">Email address</span>
                </th>
                <th>
                  <span className="shorting_container">Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {viewAgencyDetails.staffUserDetails.length ? (
                viewAgencyDetails.staffUserDetails.map(
                  ({ firstName, lastName, userEmail, status, userId }) => (
                    <tr key={userId}>
                      <td>{firstName + " " + lastName}</td>
                      <td>{userEmail}</td>
                      <td>
                        <span
                          className={`${
                            status === 0 ? "pending_red" : "active_green"
                          }`}
                        >
                          {status === 1 ? "Active" : "Pending"}
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td style={{ fontWeight: 800 }}>No data Found</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default ViewAgency;
