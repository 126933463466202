import React from "react";
import { onChangeHandleState } from "../../../Utils/CommonApi";

const SelectedStateComponent = ({
  state,
  underguideLinedetails,
  setunderguideLinedetails,
}) => {
  const underGuideLineOnChangeHandleState = (id) => {
    onChangeHandleState(underguideLinedetails, setunderguideLinedetails, id);
  };
  return (
    <div className="checkbox" key={state?.stateId}>
      {console.log(state, "SS")}
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          className="checkbox-input"
          name="state"
          value={state.stateCode}
          onChange={() => underGuideLineOnChangeHandleState(state?.stateId)}
          checked={underguideLinedetails?.stateIds?.includes(state?.stateId)}
        />
        <span className="checkbox-tile">
          <span className="checkbox-label">{state?.stateCode}</span>
        </span>
      </label>
    </div>
  );
};

export default SelectedStateComponent;
