import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import { Col, Row } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link } from "react-router-dom";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const ContactMain = () => {
  const text_usToggle = () => {
    setcontactDetails({ ...contactDetails, name: "", email: "", message: "" });
    document.body.classList.toggle("text_usToggle");
  };

  // const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [contactDetails, setcontactDetails] = useState({
    name: "",
    email: "",
    message: "",
    addresses: {},
  });
  const { http } = ApiClient();

  const getContact = async () => {
    let data = await MainPostCalls.getContact(http, setIsLoader, Swal);
    setcontactDetails({ ...contactDetails, addresses: data[0] });
  };
  useEffect(() => {
    getContact();
    return () => {};
    // eslint-disable-next-line
  }, []);

  const saveTextUsOnClickHandle = async () => {
    const { name, email, message } = contactDetails;
    if (!name || !email || !message) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let payload = {
      customerName: name,
      customerEmail: email,
      message,
    };

    let data = await MainPostCalls.saveTextUs(http, setIsLoader, Swal, payload);
    if (data?.statusCode === 200) {
      Swal.fire({
        icon: "success",
        title: "Saved",
        text: data?.message || "Your response saved successfully!",
        confirmButtonText: "OK",
      });
      text_usToggle();
    }
  };

  return (
    <LayoutMain sideBarActive={7}>
      {isLoader && <Loader />}
      <div className="FAQMain_outer">
        <div className="ContactMain_topBg"></div>
        <div className="FAQMain_contentPart">
          <div className="container">
            <h3>Contact Us</h3>
            <p>
              {Object.keys(contactDetails.addresses).length &&
                contactDetails.addresses.message}
            </p>
            <div className="ContactMain_contentPart_box">
              <Row>
                <Col lg={6}>
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.office_icon}
                        loading="lazy"
                        alt="Logo"
                        width={""}
                      />
                    </li>
                    <li>
                      <h4>Office</h4>
                      <p>
                        {Object.keys(contactDetails.addresses).length &&
                          contactDetails.addresses.address}
                      </p>
                      <p>
                        {Object.keys(contactDetails.addresses).length &&
                          contactDetails.addresses.city}
                      </p>
                      <p>
                        {Object.keys(contactDetails.addresses).length &&
                          contactDetails.addresses.pinCode}
                      </p>
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.callUs_icon}
                        loading="lazy"
                        alt="Logo"
                        width={""}
                      />
                    </li>
                    <li>
                      <h4>Call Us</h4>
                      <p>
                        {Object.keys(contactDetails.addresses).length &&
                          contactDetails.addresses.phoneNumber}
                      </p>
                      {Object.keys(contactDetails.addresses).length &&
                        contactDetails.addresses.email}
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Link to="#" className="TextUs" onClick={text_usToggle}>
          <img
            src={IMAGE_PATHS.text_us_icon}
            loading="lazy"
            alt="Logo"
            width={""}
          />
          <span>Text Us</span>
        </Link>
        <div className="TextUs_box">
          <div className="TextUs_box_top">
            <h1>Get a quick response via text</h1>
            <img
              src={IMAGE_PATHS.popUp_close}
              loading="lazy"
              alt="Logo"
              width={""}
              className="popUp_close"
              onClick={text_usToggle}
            />
          </div>
          <p className="mb-3">
            Enter your information, and our team will text you shortly.
          </p>
          <div className="mb-3">
            <Form.Control
              type="text"
              aria-describedby="passwordHelpBlock"
              placeholder="Name"
              value={contactDetails.name}
              onChange={(event) =>
                setcontactDetails({
                  ...contactDetails,
                  name: event.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <Form.Control
              type="text"
              aria-describedby="passwordHelpBlock"
              placeholder="Email address"
              value={contactDetails.email}
              onChange={(event) =>
                setcontactDetails({
                  ...contactDetails,
                  email: event.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <Form.Control
              as="textarea"
              aria-label="With textarea"
              placeholder="Enter your message"
              value={contactDetails.message}
              onChange={(event) =>
                setcontactDetails({
                  ...contactDetails,
                  message: event.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="login sign_in_button"
              onClick={() => saveTextUsOnClickHandle()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default ContactMain;
