import React from "react";
import { Button, Modal } from "react-bootstrap";
import IMAGE_PATHS from "../../constants";

const LogoutModal = ({ show, handleClose, signout }) => {
  return (
    <Modal show={show} onHide={handleClose} size="sm" centered>
      <Modal.Body>
        <div className="modal_body pad20">
          <img
            src={IMAGE_PATHS.Close_modal_normal}
            loading="lazy"
            alt="Logo"
            width={"15px"}
            onClick={handleClose}
            className="modalCrossNormal hand"
          />
          <p className="logoutText">
            Are you sure,
            <br />
            you want to logout?
          </p>
          <div className="logoutButton">
            <Button
              variant="primary"
              onClick={handleClose}
              className="button_grayBorder"
            >
              Cancel
            </Button>
            <Button
              className="button_lightBlueFill"
              variant="danger"
              onClick={() => {
                signout();
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
