import React from "react";
import { Table } from "react-bootstrap";
import IMAGE_PATHS from "../constants";
import moment from "moment";

const MonthSearchHistoryTable = ({ setSortData, history }) => {
  return (
    <div className="table_area">
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>
              <span className="shorting_container">
                Customer Name
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "customerName", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "customerName", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th>
              <span className="shorting_container">
                Month
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "month", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "month", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th>
              <span className="shorting_container">
                User Name
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "userName", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "userName", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th>
              <span className="shorting_container">
                Created on
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "createdOn", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "createdOn", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th>
              <span className="shorting_container">
                Last Update
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "lastUpdated", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "lastUpdated", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th>
              <span className="shorting_container">
                Product
                <span className="shorting">
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "productName", order: "asc" });
                    }}
                    src={IMAGE_PATHS.up_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                  <img
                    aria-hidden="true"
                    onClick={() => {
                      setSortData({ name: "productName", order: "desc" });
                    }}
                    src={IMAGE_PATHS.down_arrow}
                    loading="lazy"
                    alt="Logo"
                    width={"14px"}
                  />
                </span>
              </span>
            </th>
            <th className="tdWidth100">
              <span className="shorting_container">Matched Carriers</span>
            </th>
            <th>
              <span className="shorting_container">Reviewed Carrier</span>
            </th>
            <th>
              <span className="shorting_container">Confirmed Carrier</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {history?.data?.map((el, ind) => (
            <tr key={ind}>
              <td>{el?.customerName}</td>
              <td>{moment(`2000-${el?.month}-11`).format("MMMM")}</td>
              <td>{el?.userName}</td>
              <td>{moment(el?.createdOn).format("DD/MM/YYYY")}</td>
              <td>{moment(el?.lastUpdated).format("DD/MM/YYYY")}</td>
              <td>{el?.productName}</td>
              <td className="tdCenter">{el?.matchedCarrier}</td>
              <td>
                {el?.reviwedCarrier?.length
                  ? el?.reviwedCarrier?.map((ele, idx) =>
                      idx === 0 ? `${ele}` : `, ${ele}`
                    )
                  : "NA"}
              </td>
              <td>{el?.confirmedCarrier[0] || "NA"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!history?.data?.length ? (
        <div style={{ textAlign: "center", marginTop: "5rem" }}>
          No Data Found
        </div>
      ) : null}
    </div>
  );
};

export default MonthSearchHistoryTable;
