import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import { scrollToTop } from "../../../components/ScrollTop/ScrollTop.";
import { filteredAnswer } from "../../../components/FunctionHelper";
import StateModalWIthRecent from "../../../components/Modal/StateModalWIthRecent";
import MainSearchBar from "../../../components/MainSearchBar";
import FullPartialMatchCarriers from "../../../components/FullPartialMatchCarriers";
import CommonQuestionAnswers from "../../../components/CommonQuestionAnswers";
import FullMatchCriteriaModal from "../../../components/Modal/FullMatchCriteriaModal";
import CommonDislikeModal from "../../../components/Modal/CommonDislikeModal";
import CommonLikeModal from "../../../components/Modal/CommonLikeModal";
import { PostCallsCommon } from "../../../ApiClient/PostCall";
import { downloadPdf, itemMatchedAndMerged } from "../../../Utils/helper";

const AgentDashboardSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [recentStates, setRecentStates] = useState([]);

  const [show2, setShow2] = useState(false);
  const [selectedmatchtype, setselectedmatchtype] = useState("All");
  const [isLoader, setisLoader] = useState(false);
  const [stateShow, setStateShow] = useState(false);
  const { http } = ApiClient();
  const postCommon = PostCallsCommon(
    http,
    setisLoader,
    Swal,
    navigate,
    "agent"
  );

  let data = sessionStorage.getItem("agentDashboardSearchDetails");
  let searchData = JSON.parse(data);

  const [searchDetails, setSearchDetails] = useState({
    customerName: searchData?.customerName,
    product: searchData?.product,
    state: searchData?.state,
    stateName: "",
    stateId: "",
    allProducts: [],
    filteredQuestionAnswer: [],
    allStates: [],
    likedQuestion: {
      carrierId: 0,
      userId: 0,
      like: [
        {
          likeQuestionsId: 0,
          likeAnswer: 1,
        },
      ],
    },
    matchQuestions: [],
    matchCriteriaLogo: "",
    matchCriteriaPercentage: 0,
    dislikeLogo: "",
    dislikedQuestions: [],
    carrierLikeQuestionsDetails: [],
    allSearchedDetails: {},
  });

  const handleClose2 = async (button) => {
    const filteredArray = searchDetails.dislikedQuestions.filter(
      (item) => item.dislikeAnswer === 1 || item.dislikeAnswer === 2
    );

    let dislikeQuestionPayload = {
      carrierId: searchDetails.carrierId,
      userId: JSON.parse(sessionStorage.getItem("userId")),
      like: filteredArray,
    };

    if (button === "confirm") {
      await postCommon.saveCarrierDisLikesCommon(dislikeQuestionPayload);
      searchCarrierWithQuestion(searchDetails.filteredQuestionAnswer);
    }
    setShow2(false);
  };

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (questionDetails, carrierLogo, rating) => {
    if (!questionDetails) return;
    setSearchDetails({
      ...searchDetails,

      matchCriteriaLogo: carrierLogo,
      matchQuestions: questionDetails,
      matchCriteriaPercentage: rating,
    });
    setShow3(true);
  };

  const matchType = [
    { label: "All" },
    { label: "Connected" },
    { label: "Not Connected" },
  ];

  const handleShow = (carrierId) => {
    getCarrierLikedQuestion(carrierId);

    setShow(true);
  };

  // likeQuestion apis

  const getAllCarrierLikeQuestionsDetails = async (carrierId) => {
    setisLoader(true);
    let data = await postCommon.getAllCarrierLikeQuestionsCommon();
    setSearchDetails((prevSearchDetails) => ({
      ...prevSearchDetails,
      carrierLikeQuestionsDetails: data,
      carrierId: carrierId,
    }));
  };

  const getCarrierLikedQuestion = async (carrierId) => {
    setisLoader(true);

    let res = await postCommon.getAllCarrierLikesCommon(
      aesUtil.encrypt(JSON.stringify(carrierId))
    );
    setSearchDetails((prevSearchDetails) => ({
      ...prevSearchDetails,
      likedQuestion: {
        carrierId: carrierId,
        isConnect: res.data?.isConnect,
        carrierLogo: res.data?.carrierLogo,
        like: [
          {
            likeQuestionsId:
              res.data.carrierLikeResponses.length &&
              res.data.carrierLikeResponses[0].likeQuestionsId,
            likeAnswer:
              res.data.carrierLikeResponses.length &&
              res.data.carrierLikeResponses[0].likeAnswer,
          },
        ],
      },
    }));
    getAllCarrierLikeQuestionsDetails(carrierId);
    setisLoader(false);
  };

  // likeQuestion apis end

  const handleShow2 = (carrierId) => {
    getDislikedQuestion(carrierId);
    setShow2(true);
  };

  //  dislikeQuestion  apis
  const getDislikedQuestion = async (carrierId) => {
    setisLoader(true);
    let data = await postCommon.getAllCarrierDislikeCommon(
      aesUtil.encrypt(JSON.stringify(carrierId))
    );
    const mergedArray = itemMatchedAndMerged(searchDetails, data);
    setSearchDetails({
      ...searchDetails,
      dislikedQuestions: mergedArray,
      carrierId: carrierId,
      dislikeLogo: data.carrierLogo,
    });
    setisLoader(false);
  };

  // dislikeQuestion apis end

  const handleClose = async (button) => {
    let likeQuestionPayload = {
      carrierId: searchDetails.carrierId,
      userId: JSON.parse(sessionStorage.getItem("userId")),
      like: [
        {
          likeQuestionsId: +searchDetails.likedQuestion.like[0].likeQuestionsId,
          likeAnswer: 1,
        },
      ],
    };

    if (
      searchDetails.likedQuestion.like[0].likeQuestionsId === 0 &&
      button === "confirm"
    ) {
      return Swal.fire({
        title: "Oops...",
        text: "Atleast one field is mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    if (
      searchDetails.likedQuestion.like[0].likeQuestionsId &&
      button === "confirm"
    ) {
      setisLoader(true);

      await postCommon.saveCarrierLikesCommon(likeQuestionPayload);
      searchCarrierWithQuestion(searchDetails.filteredQuestionAnswer);
    }
    setShow(false);
  };

  const handleShowState = () => {
    getAllState();
    setStateShow(true);
  };
  const handleCloseState = () => {
    setStateShow(false);
  };

  const stateModalOnClickHandle = () => {
    setSearchDetails({
      ...searchDetails,
      state: {
        stateName: searchDetails.stateName,
        stateId: searchDetails.stateId,
      },
    });
    setStateShow(false);
  };

  const carrierLikeDislikeOnChangeHandle = (event) => {
    let { name, value } = event.target;

    if (name === "likeQuestion") {
      setSearchDetails((prevSearchDetails) => ({
        ...prevSearchDetails,
        likedQuestion: {
          ...prevSearchDetails.likedQuestion,
          like: [
            {
              ...prevSearchDetails.likedQuestion.like[0],
              likeQuestionsId: +value,
              likeAnswer: 1,
            },
          ],
        },
      }));
    }
  };

  const likeDislikeOnClickHandle = (type, productQuestionId) => {
    setSearchDetails((prevSearchDetails) => {
      const updatedDislikedQuestion = prevSearchDetails.dislikedQuestions.map(
        (item) => {
          if (item.productQuestionId === productQuestionId) {
            if (type === "like") {
              return {
                ...item,
                dislikeAnswer: item.dislikeAnswer === 1 ? 0 : 1,
              };
            } else if (type === "dislike") {
              return {
                ...item,
                dislikeAnswer: item.dislikeAnswer === 2 ? 0 : 2,
              };
            }
          }
          return item;
        }
      );
      return {
        ...prevSearchDetails,
        dislikedQuestions: updatedDislikedQuestion,
      };
    });
  };

  const getAllStatesWithRecent = async () => {
    let res = await postCommon.getAllRecentStateCommon();
    if (res.status === 200) {
      setRecentStates(res.data);
    }
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setSearchDetails({ ...searchDetails, [name]: value });
    }
    if (name === "state") {
      setSearchDetails({
        ...searchDetails,
        stateName: value,
        stateId: +id,
      });
    }
    if (name === "product") {
      setSearchDetails({
        ...searchDetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  const searchCarrierWithQuestion = async (filteredArray) => {
    let productWiseCarriersPayload = {
      customerName: searchDetails.customerName,
      productId: searchDetails.product.proId,
      stateId: searchDetails.state.stateId,
      questions: filteredArray,
    };
    setisLoader(true);

    let data = await postCommon.searchAllCarriersCommom(
      productWiseCarriersPayload
    );
    scrollToTop();
    setSearchDetails((prevSearchDetails) => {
      return {
        ...prevSearchDetails,
        allSearchedDetails: data,
      };
    });
    setisLoader(false);
  };

  const productQuestionOnChangeHandle = (event, questionId, type) => {
    let { value } = event.target;
    if (!value) return;

    let filteredArray = filteredAnswer(value, type, questionId);
    setSearchDetails((prevSearchDetails) => {
      return {
        ...prevSearchDetails,
        filteredQuestionAnswer: filteredArray,
      };
    });
    searchCarrierWithQuestion(filteredArray);
  };

  const handleSearchCarrierButtonOnClick = () => {
    let searchedDetails = {
      customerName: searchDetails.customerName,
      product: searchDetails.product,
      state: searchDetails.state,
    };

    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(searchedDetails)
    );
    navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboardSearch`, {
      replace: true,
    });
    window.location.reload(true);
    // saveProAndState();
    // getAllProduct();
  };

  const getAllState = async () => {
    setisLoader(true);

    let data = await postCommon.getAllStateCommon();
    setSearchDetails({
      ...searchDetails,
      allStates: data,
    });
    getAllStatesWithRecent();
    setisLoader(false);
  };

  const saveProAndState = async () => {
    let searchCarrierPayload = {
      isRepeat: location?.state?.repeatSearchData?.isRepeat ? 1 : 0,
      customerName: searchDetails.customerName,
      productId: searchDetails?.product?.proId,
      stateId: searchDetails?.state?.stateId,
    };
    setisLoader(true);
    let data = await postCommon.searchAllCarriersCommom(searchCarrierPayload);

    setSearchDetails((prevSearchDetails) => ({
      ...prevSearchDetails,
      allSearchedDetails: data,
    }));
    setisLoader(false);
  };

  const getAllProduct = async () => {
    let data = await postCommon.getAllConnectedProducts();
    setSearchDetails((prevSearchDetails) => ({
      ...prevSearchDetails,
      allProducts: data,
    }));
  };

  const handleMatchType = (label) => {
    setselectedmatchtype(label);
  };

  useEffect(() => {
    saveProAndState();
    getAllProduct();
    return () => {};

    // eslint-disable-next-line
  }, []);
  return (
    <LayoutAgent sideBarActive={21}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <MainSearchBar
          searchdetails={searchDetails}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          handleStateShow={handleShowState}
          handleSearchCarrierButtonOnClick={handleSearchCarrierButtonOnClick}
        />
        <div className="head_bread adminDasHead">
          <div className="ReportDate">
            <h1>Select answer from below questions</h1>
          </div>
        </div>

        <div className="AgentDashLeftRight">
          <Row>
            <CommonQuestionAnswers
              searchDetails={searchDetails}
              productQuestionOnChangeHandle={productQuestionOnChangeHandle}
            />
            <Col xs="" lg="8" className="AgentDashRight">
              <div className="AgentDashRightContainer">
                {/* <h3 className="heading02">Partial Match Carriers</h3> */}
                <div className="orangeActiveDeactive">
                  <fieldset className="checkbox-group twoOptions">
                    {matchType.map(({ label }, id) => {
                      return (
                        <div key={id} className="checkbox">
                          <label className="checkbox-wrapper">
                            <input
                              type="radio"
                              className="checkbox-input"
                              name="isActive"
                              onChange={() => {
                                handleMatchType(label);
                              }}
                              checked={
                                label === selectedmatchtype ? true : false
                              }
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">{label}</span>
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </fieldset>
                </div>

                {selectedmatchtype === "All" &&
                Object.keys(searchDetails.allSearchedDetails).length &&
                searchDetails.allSearchedDetails.productQuestion.length ? (
                  <>
                    <h3 className="heading03 devider_02">
                      Full Match Carriers
                    </h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails?.allSearchedDetails?.allCarrier
                        ?.matchedCarrier?.length > 0 ? (
                        searchDetails?.allSearchedDetails?.allCarrier?.matchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="full"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                    <br />
                    <h3 className="heading03">Partial Match Carriers</h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails?.allSearchedDetails?.allCarrier
                        ?.parsedMatchedCarrier?.length > 0 ? (
                        searchDetails?.allSearchedDetails?.allCarrier?.parsedMatchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="partial"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : selectedmatchtype === "Connected" &&
                  Object.keys(searchDetails.allSearchedDetails).length &&
                  searchDetails.allSearchedDetails.productQuestion.length ? (
                  <>
                    <h3 className="heading03 devider_02">
                      Full Match Carriers
                    </h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails.allSearchedDetails?.connectedCarrier
                        ?.matchedCarrier?.length > 0 ? (
                        searchDetails.allSearchedDetails?.connectedCarrier?.matchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            questionDetails,
                            rating,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="full"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                    <br />
                    <h3 className="heading03">Partial Match Carriers</h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails.allSearchedDetails?.connectedCarrier
                        ?.parsedMatchedCarrier?.length > 0 ? (
                        searchDetails.allSearchedDetails?.connectedCarrier?.parsedMatchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="partial"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : selectedmatchtype === "Not Connected" &&
                  Object.keys(searchDetails.allSearchedDetails).length &&
                  searchDetails.allSearchedDetails.productQuestion.length ? (
                  <>
                    <h3 className="heading03 devider_02">
                      Full Match Carriers
                    </h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails.allSearchedDetails?.notConnectCarrier
                        ?.matchedCarrier?.length > 0 ? (
                        searchDetails.allSearchedDetails?.notConnectCarrier?.matchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            questionDetails,
                            rating,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="full"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                    <br />
                    <h3 className="heading03">Partial Match Carriers</h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails.allSearchedDetails?.notConnectCarrier
                        ?.parsedMatchedCarrier?.length > 0 ? (
                        searchDetails.allSearchedDetails?.notConnectCarrier?.parsedMatchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <FullPartialMatchCarriers
                              carrier={carrier}
                              handleShow3={handleShow3}
                              questionDetails={questionDetails}
                              rating={rating}
                              like={like}
                              handleShow={handleShow}
                              dislike={dislike}
                              handleShow2={handleShow2}
                              downloadPdf={() =>
                                downloadPdf(
                                  postCommon,
                                  setisLoader,
                                  aesUtil,
                                  carrier.carrierId,
                                  carrier.carrierName
                                )
                              }
                              type="partial"
                              key={carrier.carrierId}
                            />
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="white_data_box2"
                    style={{ textAlign: "center", width: "352px" }}
                  >
                    No Data Found
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {/* like modal start */}

        <CommonLikeModal
          show={show}
          handleClose={handleClose}
          searchDetails={searchDetails}
          carrierLikeDislikeOnChangeHandle={carrierLikeDislikeOnChangeHandle}
        />
        {/* like modal end */}

        {/* dislike modal start */}
        <CommonDislikeModal
          show2={show2}
          handleClose2={handleClose2}
          searchDetails={searchDetails}
          likeDislikeOnClickHandle={likeDislikeOnClickHandle}
        />

        {/* dislike modal end */}

        {/* Full Match Carriers modal start */}

        <FullMatchCriteriaModal
          show3={show3}
          handleClose3={handleClose3}
          searchDetails={searchDetails}
        />

        {/* Full Match Carriers modal end */}

        <StateModalWIthRecent
          stateShow={stateShow}
          handleStateClose={handleCloseState}
          isLoader={isLoader}
          Loader={Loader}
          recentStates={recentStates}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          searchdetails={searchDetails}
          stateModalOnClickHandle={stateModalOnClickHandle}
        />
      </div>
    </LayoutAgent>
  );
};

export default AgentDashboardSearch;
