const baseUrl = process.env.REACT_APP_BASE_URL;

const isLogin = (error, navigate, Swal) => {
  if (error.response?.data?.isLogin) {
    Swal.fire({
      icon: "warning",
      title: "Oops...",
      text: "Already login, please login again!",
      confirmButtonText: "OK",
    });
    sessionStorage.clear();
    navigate(`${baseUrl}/Admin`);
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response?.data?.message || "Sorry, Something went wrong",
      confirmButtonText: "CANCEL",
    });
  }
};

const isLoginAgent = (error, navigate, Swal) => {
  if (error.response?.data?.isLogin) {
    Swal.fire({
      icon: "warning",
      title: "Oops...",
      text: "Already login, please login again!",
      confirmButtonText: "OK",
    });
    sessionStorage.clear();
    navigate(`${baseUrl}`);
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response?.data?.message || "Sorry, Something went wrong",
      confirmButtonText: "CANCEL",
    });
  }
};

const isLoginMain = (error, Swal) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: error.response?.data?.message || "Sorry, Something went wrong",
    confirmButtonText: "CANCEL",
  });
};

export { isLogin, isLoginAgent, isLoginMain };
