
import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import IMAGE_PATHS from "../../../constants";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const AboutMain = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [aboutDetails, setAboutDetails] = useState({
    allValues: [],
    allTeams: [],
  });

  const { http } = ApiClient();

  const getValues = async () => {
    let data = await MainPostCalls.getAllValues(http, setIsLoader, Swal);

    getTeams();
    setAboutDetails((prevDetails) => ({
      ...prevDetails,
      allValues: data,
    }));
  };

  const getTeams = async () => {
    let data = await MainPostCalls.getAllTeams(http, setIsLoader, Swal);

    setAboutDetails((prevDetails) => ({
      ...prevDetails,
      allTeams: data,
    }));
  };

  useEffect(() => {
    getValues();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <LayoutMain sideBarActive={5}>
        <div className="AboutMainBanner_outer">
          <div className="container">
            <div className="AboutMainBanner">
              <h2>About Risker</h2>
              <p>
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himeClass aptent taciti sociosqu
              </p>
            </div>

            <div className="WhoWeAre_image_text">
              <div className="WhoWeAre_image">
                <img
                  src={IMAGE_PATHS.WhoWeAre_img}
                  loading="lazy"
                  alt="WhoWeAre"
                  width={""}
                />
              </div>
              <div className="WhoWeAre_text">
                <h2>Who We Are</h2>
                <p>
                  Donec dictum tristique porta. Etiam convallis lorem lobortis
                  nulla molestie, nec tincidunt ex ullamcorper. Quisque ultrices
                  lobortis elit sed euismod. Duis in ultrices dolor, ac rhoncus
                  odio. Suspendisse tempor sollicitudin dui sed lacinia. Nulla
                  quis enim posuere, congue libero quis, commodo purus
                </p>
              </div>
            </div>

            <div className="OurValues">
              <h2>Our Values</h2>
              <p>
                Ut sodales, ex sit amet consectetur accumsan, nibh ex
                sollicitudin metus, volutpat lacinia arcu nibh vel ante. Proin
                dapibus dui eget justo tincidunt eleifend. Mauris porta elemen
              </p>
              <div className="OurValues_threeBox">
                {/* {aboutDetails.allValues.length ? (
                  aboutDetails.allValues.map(
                    ({ valuesEntityId, heading, message, valuesIcon }) => (
                      <div className="simplyTransparent" key={valuesEntityId}>
                        <ul>
                          <li>
                            <img
                              src={valuesIcon}
                              loading="lazy"
                              alt="WhoWeAre"
                              width={""}
                            />
                          </li>
                          <li>{heading}</li>
                          <li>{message}</li>
                        </ul>
                      </div>
                    )
                  )
                ) : (
                  <div style={{ color: "black" }}>No Data Found</div>
                )} */}
                <div className="simplyTransparent">
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.simply_transparent_img}
                        loading="lazy"
                        alt="WhoWeAre"
                        width={""}
                      />
                    </li>
                    <li>Simply transparent</li>
                    <li>
                      Donec dictum tristique porta. Etiam convallis lorem
                      lobortis nulla molestie, nec
                    </li>
                  </ul>
                </div>
                <div className="Acting_as_one_team">
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.acting_as_one_team_img}
                        loading="lazy"
                        alt="WhoWeAre"
                        width={""}
                      />
                    </li>
                    <li>Acting as one team</li>
                    <li>
                      Donec dictum tristique porta. Etiam convallis lorem
                      lobortis nulla molestie, nec
                    </li>
                  </ul>
                </div>
                <div className="Make_bold_decisions">
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.make_bold_decisions_img}
                        loading="lazy"
                        alt="WhoWeAre"
                        width={""}
                      />
                    </li>
                    <li>Make bold decisions</li>
                    <li>
                      Donec dictum tristique porta. Etiam convallis lorem
                      lobortis nulla molestie, nec
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="how_can_we_help">
          <div className="container">
            <Row>
              <Col sm={6} className="how_can_we_help_content">
                <div>
                  <h3>How can we help you today?</h3>
                  <p>
                    Donec vitae mi vulputate, suscipit urna in, malesuada nisl.
                    Pellentesque laoreet pretium nisl, et pulvinar massa
                    eleifend sed. Curabitur maximus mollis diam, vel varius
                    sapien suscipit eget. Cras sollicitudin ligula at volutpat
                    ultrices
                  </p>
                  <Button
                    className="Book_a_Demo"
                    onClick={() =>
                      navigate(
                        `${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo#book_demo`
                      )
                    }
                  >
                    Book a Demo
                  </Button>
                </div>
              </Col>
              <Col sm={6}>
                <img
                  src={IMAGE_PATHS.how_can_we_help_img}
                  loading="lazy"
                  alt="WhoWeAre"
                  width={""}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="container Our_Team">
          <h3>Our Team</h3>
          <p>
            Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin
            metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget
            justo tincidunt eleifend. Mauris porta elemen
          </p>
          <div className="Our_Team_box">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={4}
              slidesPerGroup={2}
              // navigation
              pagination={{ clickable: true }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              // className="Discover_why_swiper"
              breakpoints={{
                // when window width is >= 375
                375: {
                  //width: 640,
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                // when window width is >= 375
                414: {
                  //width: 414,
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                // when window width is >= 640px
                640: {
                  //width: 640,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                // when window width is >= 768px
                768: {
                  //width: 768,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                1024: {
                  //width: 768,
                  slidesPerView: 4,
                  slidesPerGroup: 2,
                },
              }}
            >
              {aboutDetails.allTeams.length ? (
                aboutDetails.allTeams.map(
                  ({ teamId, name, designation, image }) => (
                    <SwiperSlide
                      key={teamId}
                      className="Our_Team_individual_box"
                    >
                      <ul>
                        <li className="Our_Team_img">
                          <img
                            src={image}
                            loading="lazy"
                            alt="WhoWeAre"
                            width={""}
                            // width={"162"}
                            // height={"162"}
                          />
                        </li>
                        <li className="Our_Team_name">{name}</li>
                        <li className="Our_Team_details">{designation}</li>
                      </ul>
                    </SwiperSlide>
                  )
                )
              ) : (
                <div style={{ color: "black" }}>No Data Found</div>
              )}
            </Swiper>

            
          </div>


            {/* <div className="Our_Team_box">
                <div className="Our_Team_individual_box">
                  <ul>
                    <li className="Our_Team_img">
                      <img
                        src={IMAGE_PATHS.image_04}
                        loading="lazy"
                        alt="WhoWeAre"
                        width={""}
                      />
                    </li>
                    <li className="Our_Team_name">Larry J. Cash</li>
                    <li className="Our_Team_details">Marketing & Operations</li>
                  </ul>
                </div>

                <div className="Our_Team_individual_box">
                  <ul>
                    <li className="Our_Team_img">
                      <img
                        src={IMAGE_PATHS.image_04}
                        loading="lazy"
                        alt="WhoWeAre"
                        width={""}
                      />
                    </li>
                    <li className="Our_Team_name">Larry J. Cash</li>
                    <li className="Our_Team_details">Marketing & Operations</li>
                  </ul>
                </div>

                <div className="Our_Team_individual_box">
              <ul>
                <li className="Our_Team_img">
                  <img
                    src={IMAGE_PATHS.image_04}
                    loading="lazy"
                    alt="WhoWeAre"
                    width={""}
                  />
                </li>
                <li className="Our_Team_name">Larry J. Cash</li>
                <li className="Our_Team_details">Marketing & Operations</li>
              </ul>
                </div>
            </div> */}
        </div>
      </LayoutMain>
    </>
  );
};

export default AboutMain;