import React from "react";
import { Form } from "react-bootstrap";

const ConnectedProducts = ({
  allProducts,
  searchdetails,
  handleCarrierSearchOnChange,
}) => {
  return (
    <li>
      <Form.Select
        aria-label="Default select example"
        name="product"
        onChange={(event) => handleCarrierSearchOnChange(event)}
      >
        <option hidden>
          {searchdetails?.product?.proName || "Select Product"}
        </option>
        {allProducts?.length > 0 ? (
          allProducts.map(({ productId, productName }) => (
            <option key={productId} value={productName} id={productId}>
              {productName}
            </option>
          ))
        ) : (
          <option>No Data Found</option>
        )}
      </Form.Select>
    </li>
  );
};

export default ConnectedProducts;
