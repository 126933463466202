import React from "react";
import { Link, useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../../../constants";
const baseUrlMain = process.env.REACT_APP_BASE_URL_MAIN;

const FooterMain = () => {
  const navigate = useNavigate();
  return (
    <div className="FooterMainContainer">
      <div className="container">
        <div className="FooterMainContainer_inner">
          <div className="left">
            <div className="FooterMainContainer_inner_content">
              <div>
                <ul>
                  <li className="head">Products</li>
                  <li>
                    <Link to={`${baseUrlMain}/ProductsMain`}>Home</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/ProductsMain`}>Auto</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/ProductsMain`}>
                      Dwelling Fire
                    </Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/ProductsMain`}>
                      Manufactured Home
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="head">Company</li>
                  <li>
                    <Link to={`${baseUrlMain}/AboutMain`}>About Us</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/CarriersMain`}>Careers</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/ContactMain`}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to="#">Press room</Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="head">Resources</li>
                  <li>
                    <Link to={process.env.REACT_APP_BASE_URL}>Log in</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/FAQMain`}>Help</Link>
                  </li>
                  <li>
                    <Link to={`${baseUrlMain}/AboutMain`}>Learn</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_bottom">
              <Link>Privacy Policy</Link>{" "}
              <span className="devider">&nbsp;</span> <Link>Terms of Use</Link>
              <span className="copyright">
                Copyright © Risker 2023. All Rights Reserved.
              </span>
            </div>
          </div>
          <div className="right desktop_show_tab_off">
            <ul>
              <li
                onClick={() => navigate(`${baseUrlMain}`)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={IMAGE_PATHS.Risker_final_logo_footer}
                  className="Risker_final_logo"
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
              </li>
              <li>
                <img
                  src={IMAGE_PATHS.footer_phone_icon}
                  className=""
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
                +1-202-555-0127
              </li>
              <li>
                <img
                  src={IMAGE_PATHS.fb_footer}
                  className=""
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
                <img
                  src={IMAGE_PATHS.tw_footer}
                  className=""
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
                <img
                  src={IMAGE_PATHS.in_footer}
                  className=""
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
                <img
                  src={IMAGE_PATHS.yo_footer}
                  className=""
                  loading="lazy"
                  alt="Logo"
                  width={""}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="right_mobile desktop_off_tab_show">
        <div className="container">
          <ul>
            <li>
              <img
                src={IMAGE_PATHS.Risker_final_logo_footer}
                className="Risker_final_logo"
                loading="lazy"
                alt="Logo"
                width={""}
              />
            </li>
            <li>
              <img
                src={IMAGE_PATHS.footer_phone_icon}
                className=""
                loading="lazy"
                alt="Logo"
                width={""}
              />
              +1-202-555-0127
            </li>
            <li>
              <img
                src={IMAGE_PATHS.fb_footer}
                className=""
                loading="lazy"
                alt="Logo"
                width={""}
              />
              <img
                src={IMAGE_PATHS.tw_footer}
                className=""
                loading="lazy"
                alt="Logo"
                width={""}
              />
              <img
                src={IMAGE_PATHS.in_footer}
                className=""
                loading="lazy"
                alt="Logo"
                width={""}
              />
              <img
                src={IMAGE_PATHS.yo_footer}
                className=""
                loading="lazy"
                alt="Logo"
                width={""}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
