import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const ReportCalendar = ({ searchHistoryHandleOnChangeCalendar }) => {
  return (
    <div className="dateButtonContainer" style={{ width: "250px" }}>
      <RangePicker
        onChange={(event) => searchHistoryHandleOnChangeCalendar(event)}
        style={{ padding: "10px 20px" }}
      />
    </div>
  );
};

export default ReportCalendar;
