import React, { useEffect, useState } from "react";
import LayoutAgent from "./LayoutAgent/LayoutAgent";
import IMAGE_PATHS from "../../constants";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import StateModalWIthRecent from "../../components/Modal/StateModalWIthRecent";
import MainSearchBar from "../../components/MainSearchBar";
import ProductWiseSearch from "../../components/SearchByAll/ProductWiseSearch";
import MonthWIseSearchChart from "../../components/SearchByAll/MonthWIseSearchChart";
import DashboardBox from "../../components/DashboardBox";
import TodaySearchBox from "../../components/TodaySearchBox";
import { PostCallsAgent, PostCallsCommon } from "../../ApiClient/PostCall";
const baseUrl = process.env.REACT_APP_BASE_URL;

const AgentDashoard = () => {
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const postAgent = PostCallsAgent(http, setisLoader, Swal, navigate);

  const postCommon = PostCallsCommon(
    http,
    setisLoader,
    Swal,
    navigate,
    "agent"
  );

  const [searchDetails, setSearchDetails] = useState({
    customerName: "",
    product: { proName: "", proId: "" },
    state: { stateName: "", stateId: "" },

    allStates: [],
    stateName: "",
    stateId: "",
    allCountData: {},
    connectedCarrierList: [],
    allProducts: [],
  });
  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
  });

  const [stateShow, setStateShow] = useState(false);
  const [recentStates, setRecentStates] = useState([]);

  const handleClose = () => {
    setStateShow(false);
  };

  const stateModalOnClickHandle = () => {
    setSearchDetails({
      ...searchDetails,
      state: {
        stateName: searchDetails.stateName,
        stateId: searchDetails.stateId,
      },
    });
    setStateShow(false);
  };
  const handleSearchCarrierButtonOnClick = () => {
    if (
      !searchDetails.customerName ||
      !searchDetails.product.proName ||
      !searchDetails.state.stateName
    ) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let agentDashboardSearchDetails = {
      customerName: searchDetails.customerName,
      product: searchDetails.product,
      state: searchDetails.state,
    };
    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(agentDashboardSearchDetails)
    );

    navigate(`${baseUrl}/AgentDashboardSearch`);
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setSearchDetails({ ...searchDetails, [name]: value });
    }
    if (name === "state") {
      setSearchDetails({
        ...searchDetails,
        stateName: value,
        stateId: +id,
        // state: { stateName: value, stateId: +id },
      });
    }
    if (name === "product") {
      setSearchDetails({
        ...searchDetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  const getAllState = async () => {
    setisLoader(true);
    let data = await postCommon.getAllStateCommon();
    setSearchDetails({
      ...searchDetails,
      allStates: data,
      stateName: "",
      stateId: "",
    });
    getAllStatesWithRecent();
    setisLoader(false);
  };
  const getAllStatesWithRecent = async () => {
    let res = await postCommon.getAllRecentStateCommon();
    if (res.status === 200) {
      setRecentStates(res.data);
    }
  };
  const handleShow = () => {
    getAllState();
    setStateShow(true);
  };

  const getLastSearch = async () => {
    let data = await postCommon.getTodaysSearch();

    setSearchDetails((prevLists) => ({
      ...prevLists,
      // customerName: res.data[0]?.searchEntity?.customerName,
      state: {
        stateName: data[0]?.masterState?.stateCode,
        stateId: data[0]?.masterState?.stateId,
      },
      product: {
        proName: data[0]?.searchEntity?.productBuilder?.productName,
        proId: data[0]?.searchEntity?.productBuilder?.productId,
      },
    }));
  };

  const getAllProduct = async () => {
    let data = await postCommon.getAllConnectedProducts();
    setSearchDetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      allProducts: data,
    }));
  };

  const getSearchCarriersNumber = async () => {
    let data = await postAgent.getSearchCarriersNumber();
    setSearchDetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      allCountData: data,
    }));
  };

  const connectedCarriers = async () => {
    setisLoader(true);
    let data = await postAgent.getAllConnectedCarriers();
    setSearchDetails((prevCarriers) => ({
      ...prevCarriers,
      connectedCarrierList: data,
    }));
    getMonthAndProductWiseSearch();
    getProductWiseSearch();

    getLastSearch();
    getAllProduct();
    getSearchCarriersNumber();

    setisLoader(false);
  };

  // chart showing start

  const getMonthAndProductWiseSearch = async () => {
    let data = await postAgent.getSearchByMonth();
    setReportLists((prevLists) => ({
      ...prevLists,
      monthWise: data,
    }));
  };

  const getProductWiseSearch = async () => {
    let data = await postCommon.getSearchUserByProductCommon("", "");
    setReportLists((prevLists) => ({
      ...prevLists,
      productWise: data,
    }));
  };

  // chart showing end

  useEffect(() => {
    sessionStorage.removeItem("agentDashboardSearchDetails");
    localStorage.removeItem("paymentData");

    connectedCarriers();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAgent sideBarActive={21}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <MainSearchBar
          searchdetails={searchDetails}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          handleStateShow={handleShow}
          handleSearchCarrierButtonOnClick={handleSearchCarrierButtonOnClick}
        />
        <div className="head_bread mt-4 mb-3">
          <div className="ReportDate">
            <h1>Dashboard</h1>
          </div>
        </div>
        <div className="AdminDashboardFourBox">
          <ul>
            <DashboardBox
              Link={`${baseUrl}/SearchHistoryAgent`}
              boxValue={"Number of Search"}
              searchdetails={searchDetails?.allCountData?.search || 0}
              IMAGE_PATHS={IMAGE_PATHS.na_icon_01}
            />
            <DashboardBox
              Link={`${baseUrl}/CarriersAgent`}
              boxValue={"Connected Carrier"}
              searchdetails={searchDetails?.allCountData?.connectedCarrier || 0}
              IMAGE_PATHS={IMAGE_PATHS.as_icon_02}
            />
            <TodaySearchBox
              Link={`${baseUrl}/SearchHistoryAgent`}
              searchdetails={
                Object.keys(searchDetails.allCountData).length > 0
                  ? searchDetails.allCountData.todaySearch
                  : 0
              }
            />
            <DashboardBox
              // Link={`${baseUrl}/CarriersAgent`}
              boxValue={"Rating Pending"}
              searchdetails={0}
              IMAGE_PATHS={IMAGE_PATHS.ip_icon_05}
            />
          </ul>
        </div>
        <div className="AdminDashboardThreeBox">
          <MonthWIseSearchChart reportLists={reportLists} />
          <ProductWiseSearch reportLists={reportLists} />

          <div className="whiteBoxShadow">
            <div className="twoPartCommon align-items-center mb_12">
              <h3 className="mb-0">Connected Carriers</h3>
              {/* <Button className="button_blueBorder_small ">dd</Button> */}
            </div>
            <div className="cc_icons">
              {searchDetails?.connectedCarrierList?.map(
                ({ carrier }, ind) =>
                  ind < 6 && (
                    <div className="cc_icons_box" key={carrier.carrierId}>
                      <img
                        src={carrier.carrierLogo}
                        loading="lazy"
                        alt="Logo"
                        width={100}
                      />
                    </div>
                  )
              )}
              {searchDetails?.connectedCarrierList.length === 0 && (
                <div>No Data Found</div>
              )}
            </div>
          </div>
        </div>
        <StateModalWIthRecent
          stateShow={stateShow}
          handleStateClose={handleClose}
          isLoader={isLoader}
          Loader={Loader}
          recentStates={recentStates}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          searchdetails={searchDetails}
          stateModalOnClickHandle={stateModalOnClickHandle}
        />
      </div>
    </LayoutAgent>
  );
};

export default AgentDashoard;
