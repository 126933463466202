import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../constants";
import ApiClient from "../../ApiClient/ApiClient";
import LogoutModal from "../../components/Modal/LogoutModal";
const baseUrl = process.env.REACT_APP_BASE_URL;

const LeftMenu = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };
  const { logout } = ApiClient();

  const signout = () => {
    logout("admin");
  };

  return (
    <div>
      <div className="full_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin}
          loading="lazy"
          alt="Logo"
          width={"120px"}
          onClick={() => navigate(`${baseUrl}/Admin/AdminDashboard`)}
        />
      </div>
      <div className="small_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin_small}
          loading="lazy"
          alt="Logo"
          width={"120px"}
        />
      </div>
      <div>
        <ul className="mainMenu">
          <li className="Dashboard">
            <Link
              className={props.sideBarActive === 1 ? "active" : ""}
              to={`${baseUrl}/Admin/AdminDashboard`}
            >
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="Products">
            <Link
              className={props.sideBarActive === 2 ? "active" : ""}
              to={`${baseUrl}/Admin/Products`}
            >
              <span>Products</span>
            </Link>
          </li>
          <li className="Carriers">
            <Link
              className={props.sideBarActive === 3 ? "active" : ""}
              to={`${baseUrl}/Admin/Carriers`}
            >
              <span>Carriers</span>
            </Link>
          </li>
          <li className="ManageSubscription">
            <Link
              className={props.sideBarActive === 4 ? "active" : ""}
              to={`${baseUrl}/Admin/ManageSubscription`}
            >
              <span>Manage Subscription</span>
            </Link>
          </li>
          <li className="Agency">
            <Link
              className={props.sideBarActive === 5 ? "active" : ""}
              to={`${baseUrl}/Admin/Agency`}
            >
              <span>Agency</span>
            </Link>
          </li>
          <li className="SearchHistory">
            <Link
              className={props.sideBarActive === 6 ? "active" : ""}
              onClick={() => sessionStorage.removeItem("todaySearch")}
              to={`${baseUrl}/Admin/SearchHistory`}
            >
              <span>Search History</span>
            </Link>
          </li>
          <li className="Report">
            <Link
              className={props.sideBarActive === 7 ? "active" : ""}
              to={`${baseUrl}/Admin/Report`}
            >
              <span>Report</span>
            </Link>
          </li>
          <li className="Payments">
            <Link
              className={props.sideBarActive === 8 ? "active" : ""}
              to={`${baseUrl}/Admin/Payments`}
            >
              <span>Payments</span>
            </Link>
          </li>
          <li className="UserManagement_2">
            <Link
              className={props.sideBarActive === 9 ? "active" : ""}
              to={`${baseUrl}/Admin/UserManagement`}
            >
              <span>User Management</span>
            </Link>
          </li>
          <li className="FAQTopics">
            <Link
              className={props.sideBarActive === 10 ? "active" : ""}
              to={`${baseUrl}/Admin/FAQTopics`}
            >
              <span>FAQ Topics</span>
            </Link>
          </li>

          <li className="FAQDetails">
            <Link
              className={props.sideBarActive === 11 ? "active" : ""}
              to={`${baseUrl}/Admin/FAQDetails`}
            >
              <span>FAQ Details</span>
            </Link>
          </li>
          <li className="ContactUs">
            <Link
              className={props.sideBarActive === 12 ? "active" : ""}
              to={`${baseUrl}/Admin/ContactUs`}
            >
              <span>Contact Us</span>
            </Link>
          </li>
          <li className="Reviews">
            <Link
              className={props.sideBarActive === 13 ? "active" : ""}
              to={`${baseUrl}/Admin/Reviews`}
            >
              <span>Reviews</span>
            </Link>
          </li>
          <li className="OurTeam">
            <Link
              className={props.sideBarActive === 14 ? "active" : ""}
              to={`${baseUrl}/Admin/Teams`}
            >
              <span>Teams</span>
            </Link>
          </li>

          <li
            className="Logout"
            onClick={() => {
              setShow(true);
            }}
          >
            <Link>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>

      <LogoutModal show={show} handleClose={handleClose} signout={signout} />
    </div>
  );
};

export default LeftMenu;
