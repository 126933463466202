import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { PostCallsAdmin, PostCallsAgent } from "./PostCall";
import { useState } from "react";

export default function ApiClient() {
  const navigate = useNavigate();
  const [, setIsLoader] = useState(false);
  const getToken = () => {
    let tokenString = sessionStorage.getItem("accessToken");
    return tokenString;
  };

  const getexpireInTime = () => {
    let expireString = sessionStorage.getItem("expireIn");
    return expireString;
  };

  const getRefreshToken = () => {
    let refreshTokenString = sessionStorage.getItem("refreshToken");
    return refreshTokenString;
  };

  // Function to set the access token and its expiration time in local storage
  // const setAccessToken = (token, expiresIn, refreshToken) => {
  //   sessionStorage.setItem("accessToken", token);
  //   sessionStorage.setItem("refreshToken", refreshToken.toString());
  //   sessionStorage.setItem("expireIn", expiresIn.toString());
  // };
  const setAccessToken = (token, expiresIn) => {
    sessionStorage.setItem("accessToken", token);
    sessionStorage.setItem("expireIn", expiresIn.toString());
  };

  // Function to check if the access token has expired
  const isAccessTokenExpired = () => {
    const expirationTime = sessionStorage.getItem("expireIn");
    return expirationTime && Date.now() > Number(expirationTime);
  };

  // Function to delete the access token from local storage
  const logout = async (type) => {
    if (type === "agent") {
      await postAgent.logoutAgent();
    } else {
      await postAdmin.logoutAdmin();
    }
  };

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      authorization: `Bearer ${getToken()?.trim()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const postAgent = PostCallsAgent(http, setIsLoader, Swal, navigate);
  const postAdmin = PostCallsAdmin(http, setIsLoader, Swal, navigate);

  // const refreshAccessToken = async () => {
  //   if (getRefreshToken() === null) {
  //     logout();
  //     return;
  //   }

  //   try {
  //     let requestData = { refreshToken: getRefreshToken() };

  //     if (isEncrypted === "1") {
  //       // Encrypt the refreshToken before sending
  //       const encryptedData = aesUtil.encrypt(JSON.stringify(requestData));

  //       requestData = {
  //         reqData: encryptedData,
  //       };
  //     }

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/auth/refreshToken`,
  //       requestData
  //     );

  //     if (isEncrypted === "1") {
  //       // Decrypt the response if it's encrypted
  //       const decryptedData = aesUtil.decrypt(response.data.resData);
  //       response.data = JSON.parse(decryptedData);
  //     }

  //     const { accessToken, expireIn, refreshToken } = response.data.data;
  //     setAccessToken(accessToken, expireIn, refreshToken);
  //   } catch (error) {
  //     logout();
  //   }
  // };

  // if (isEncrypted === 0) {
  //   // Axios request interceptor
  //   http.interceptors.request.use(
  //     async (config) => {
  //       if (isAccessTokenExpired()) {
  //         await refreshAccessToken();
  //       }
  //       config.headers.Authorization = `Bearer ${getToken()}`;
  //       return config;
  //     },
  //     (error) => {
  //       return Promise.reject(error);
  //     }
  //   );
  // } else {
  //   // Axios request interceptor
  //   http.interceptors.request.use(
  //     async (config) => {
  //       if (isAccessTokenExpired()) {
  //         await refreshAccessToken();
  //       }

  //       config.headers.Authorization = `Bearer ${getToken()}`;
  //       if (config.method === "post" || config.method === "put") {
  //         const encryptedData = aesUtil.encrypt(JSON.stringify(config.data));

  //         config.data = {
  //           reqData: encryptedData,
  //         };
  //         config.headers["Content-Type"] = "application/json";
  //       }

  //       return config;
  //     },
  //     (error) => {
  //       return Promise.reject(error);
  //     }
  //   );

  //   // Add a response interceptor

  //   http.interceptors.response.use(
  //     async (response) => {
  //       if (response.data) {
  //         if (response.data.resData) {
  //           const decryptedData = aesUtil.decrypt(response.data.resData);
  //           response.data = JSON.parse(decryptedData);
  //           let { statusCode, msg } = response.data;

  //           if (statusCode === 400) {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Oops...",
  //               text: msg || "Sorry, Something went wrong",
  //               confirmButtonText: "CANCEL",
  //             });
  //           } else {
  //             return response;
  //           }
  //         }

  //         return response;
  //       }
  //     },
  //     async (error) => {
  //       const decryptedError = aesUtil.decrypt(error.response.data.resData);
  //       error.response.data = JSON.parse(decryptedError);
  //       return Promise.reject(error);
  //     }
  //   );
  // }

  return {
    setAccessToken,
    getexpireInTime,
    getToken,
    logout,
    http,
    isAccessTokenExpired,
    getRefreshToken,
  };
}
