import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import MyLoader from "../../../components/Loader/myLoader";
import SelectOption from "../../SelectOption/ProductSelectOption";
import { sortArray } from "../../../Utils/helper";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import Paginations from "../../../components/Pagination";
import { downloadCsv } from "../../../components/CsvDownload/CsvDownload";
import MonthSearchHistoryTable from "../../../components/MonthSearchHistoryTable";
import SearchHistoryDetailsCalendar from "../../../components/Calendar/SearchHistoryDetailsCalendar";
const baseUrl = process.env.REACT_APP_BASE_URL;

const MonthSearchHistory = () => {
  const [history, setHistory] = useState({ data: [], count: 0, csvFile: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchdata, setSearchdata] = useState({
    customerName: "",
    startDate: "",
    endDate: "",
  });
  const [selectedProd, setSelectedProd] = useState(null);
  const [page, setPage] = useState(1);
  const [sortData, setSortData] = useState({ name: "", order: "" });

  const { http } = ApiClient();

  const getMonthllyHistoryData = async (payload = false) => {
    try {
      setLoading(true);

      let reqPayload = {
        productId: selectedProd,
        ...searchdata,
      };

      if (payload) {
        setPage(1);
      }

      const res = await http.post(
        `/getMonthWiseSearchHistoryDetailsAdmin/${payload ? 1 : page}`,
        payload ? reqPayload : null
      );

      setHistory({
        ...history,
        data: res?.data?.data?.map((el) => {
          return { ...el, userNameSpecial: el?.userName?.toLowerCase() };
        }),
        count: res?.data?.count,
        csvFile: res.data.csvFile,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLogin(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthllyHistoryData();
    return () => {};
    // eslint-disable-next-line
  }, [page]);

  const sortHistoryData = (name, order) => {
    setHistory({
      ...history,
      data: sortArray(
        history.data,
        name === "userName" ? "userNameSpecial" : name,
        order
      ),
    });
  };

  useEffect(() => {
    if (sortData.name && sortData.order) {
      sortHistoryData(sortData.name, sortData.order);
    }
    return () => {};
    // eslint-disable-next-line
  }, [sortData]);

  return (
    <Layout sideBarActive={7}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>
            <Link to={`${baseUrl}/Admin/Report`}>
              <img src={IMAGE_PATHS.ep_back} loading="lazy" alt="Logo" />
            </Link>
            Month Wise Search History
          </h1>
          <div className="r">
            <Link to={`${baseUrl}/Admin/AdminDashboard`}>Dashboard</Link>
            <span>/ Reports</span>
          </div>
        </div>

        <div className="mt-3 sideBySideBoxLeftRight">
          <div className="searchHistoryFormBar left">
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  name="customerName"
                  value={searchdata.customerName}
                  onChange={(e) => {
                    setSearchdata({
                      ...searchdata,
                      customerName: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </div>
            <div>
              <SelectOption setSelectedProduct={setSelectedProd} />
            </div>

            <SearchHistoryDetailsCalendar
              searchdata={searchdata}
              setSearchdata={setSearchdata}
            />
            <div>
              <Button
                className="button_blue"
                onClick={() => {
                  getMonthllyHistoryData(true);
                }}
              >
                <img
                  aria-hidden="true"
                  src={IMAGE_PATHS.search_icon_white}
                  loading="lazy"
                  alt="Logo"
                  width={"13px"}
                />
                Search
              </Button>
            </div>
          </div>
          <div className="report_right_button right">
            {/* <Button className="redBorderButton">View</Button> */}
            <Button
              className="button_redBorder"
              onClick={() => downloadCsv(history.csvFile)}
            >
              View
            </Button>
          </div>
        </div>

        <MonthSearchHistoryTable setSortData={setSortData} history={history} />

        {history?.data?.length ? (
          <Paginations page={page} setPage={setPage} count={history.count} />
        ) : null}
      </div>
    </Layout>
  );
};

export default MonthSearchHistory;
