import React from "react";
import IMAGE_PATHS from "../../../constants";

const DeleteDependentBox = ({
  dependableDetails,
  setDependableDetails,
  productQuestionEntity,
  ind,
}) => {
  return (
    <span
      className="ps-2"
      onClick={() => {
        const updatedArray = [
          ...dependableDetails[
            `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
          ],
        ];
        updatedArray.splice(ind, 1);
        setDependableDetails({
          ...dependableDetails,
          [`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`]:
            updatedArray,
        });
      }}
    >
      <img
        src={IMAGE_PATHS.DeleteIconGray}
        loading="lazy"
        alt="Logo"
        width={"16px"}
      />
    </span>
  );
};

export default DeleteDependentBox;
