import React from "react";

const DependentCheckboxComponent = ({
  productQuestionEntity,
  underguideLinedetails,
  handleCheckboxOnChange,
}) => {
  return (
    <>
      <input
        style={{
          width: "20px",
          cursor: "pointer",
          marginLeft: "10px",
        }}
        type="checkbox"
        name={`${productQuestionEntity.questionLabelCode}3`}
        // value={
        //   underguideLinedetails[
        //     `${productQuestionEntity.questionLabelCode}3`
        //   ]
        // }
        checked={
          underguideLinedetails[
            `${productQuestionEntity.questionLabelCode}2`
          ] === null
        }
        onChange={(event) =>
          handleCheckboxOnChange(event, productQuestionEntity, "")
        }
      />
      <label>Current Year</label>
    </>
  );
};

export default DependentCheckboxComponent;
