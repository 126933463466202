import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import CarriersLeftMenu from "../../../components/CarriersLeftMenu/CarriersLeftMenu";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { uploadImage } from "../../../components/FunctionHelper";
import { PostCallsAdmin, PostCallsCommon } from "../../../ApiClient/PostCall";
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddNnewcarrierPage = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  let { http } = ApiClient();

  const postCommon = PostCallsCommon(
    http,
    setisLoader,
    Swal,
    navigate,
    "admin"
  );
  const postAdmin = PostCallsAdmin(http, setisLoader, Swal, navigate);

  const handleShow = () => {
    getAllState();
    setShow(true);
  };

  const [addNewCarrierdetails, setaddNewCarrierdetails] = useState({
    carrierName: "",
    email: "",
    statesName: [],
    statesIds: [],
    contact: "",
    allSelectedProduct: [],
    carrierLogo: "",
    statesOfcarriers: [],
    allProducts: [],
  });

  const handleClose = (button) => {
    if (button !== "confirm") {
      setaddNewCarrierdetails({
        ...addNewCarrierdetails,
        statesIds: [],
        statesName: [],
      });
    }

    setShow(false);
  };

  const getAllState = async () => {
    setisLoader(true);
    let data = await postCommon.getAllStateCommon();
    setaddNewCarrierdetails({
      ...addNewCarrierdetails,
      statesOfcarriers: data,
      statesName: [],
      statesIds: [],
    });
    setisLoader(false);
  };

  const selectAllStateHandleOnClick = () => {
    const arrayOfIds = addNewCarrierdetails.statesOfcarriers.map(
      (state) => state.stateId
    );

    const arrayOfState = addNewCarrierdetails.statesOfcarriers.map(
      (state) => state.stateCode
    );
    setaddNewCarrierdetails({
      ...addNewCarrierdetails,
      statesIds: arrayOfIds,
      statesName: arrayOfState,
    });
  };

  const addNewCarrierOnChangeHandle = (event) => {
    let { name, value, id } = event.target;
    if (name === "statesName") {
      const itemsIds = addNewCarrierdetails.statesIds;
      const itemsName = addNewCarrierdetails.statesName;

      const indexIds = itemsIds.indexOf(+id);
      const indexName = itemsName.indexOf(value);

      if (indexIds !== -1) {
        itemsIds.splice(indexIds, 1);
      } else {
        itemsIds.push(+id);
      }
      if (indexName !== -1) {
        itemsName.splice(indexName, 1);
      } else {
        itemsName.push(value);
      }
      setaddNewCarrierdetails({
        ...addNewCarrierdetails,
        statesIds: itemsIds,
        statesName: itemsName,
      });
    } else if (name === "allSelectedProduct") {
      const items = addNewCarrierdetails.allSelectedProduct;
      const index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(value);
      }
      setaddNewCarrierdetails({
        ...addNewCarrierdetails,
        [name]: items,
      });
    } else if (name === "carrierLogo") {
      uploadImage(
        event,
        Swal,
        setaddNewCarrierdetails,
        addNewCarrierdetails,
        name
      );
    } else {
      setaddNewCarrierdetails({
        ...addNewCarrierdetails,
        [name]: value,
      });
    }
  };

  const addNnewcarrierPageOnClickHandle = async () => {
    const prodsIds = [];

    // getIds of product
    addNewCarrierdetails.allSelectedProduct.forEach((productName) => {
      const foundPro = addNewCarrierdetails.allProducts.find(
        (product) => product.productName === productName
      );
      if (foundPro) {
        prodsIds.push(foundPro.productId);
      }
    });

    let carrierPayload = {
      carrierName: addNewCarrierdetails.carrierName,
      carrierEmail: addNewCarrierdetails.email || null,
      carrierContactNo: addNewCarrierdetails.contact || null,
      carrierLogo: addNewCarrierdetails.carrierLogo,
      stateId: addNewCarrierdetails.statesIds,
      productId: prodsIds,
    };

    setisLoader(true);
    let data = await postAdmin.saveCarrierAdmin(carrierPayload);
    console.log(data, "Dda");
    // return;
    navigate(`${baseUrl}/Admin/UnderGuide`, {
      state: {
        allCarrier: data,
      },
    });
  };

  const addStateOnClickHandle = () => {
    handleClose("confirm");
  };

  const getAllProduct = async () => {
    setisLoader(true);
    let data = await postAdmin.getProductWithQuestion();
    setaddNewCarrierdetails({
      ...addNewCarrierdetails,
      allProducts: data,
    });
    setisLoader(false);
  };

  useEffect(() => {
    getAllProduct();
    return () => {};
    // eslint-disable-next-line
  }, []);

  const isFieldAllSet =
    addNewCarrierdetails.carrierLogo &&
    addNewCarrierdetails.carrierName &&
    addNewCarrierdetails.allSelectedProduct.length > 0 &&
    addNewCarrierdetails.statesIds.length > 0;

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Add New Carrier</h1>
          <div>
            <Link to={`${baseUrl}/Admin/Carriers`}>Carriers</Link>
            <span>/ AddCarrier</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <CarriersLeftMenu pageValue={"BasicInfo"} />
          <div className="left carriersRight">
            <div className="modal_body">
              <h2 className="headText">Add New Carrier</h2>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">
                    Name of Carrier
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter carrier name"
                    name="carrierName"
                    value={addNewCarrierdetails.carrierName}
                    onChange={(event) => addNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email ID"
                    name="email"
                    value={addNewCarrierdetails.email}
                    onChange={(event) => addNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row SelectedState " onClick={() => handleShow()}>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Label className="label_text">Select State</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>
                      {addNewCarrierdetails.statesName[0] || "States"}
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter number"
                    name="contact"
                    value={addNewCarrierdetails.contact}
                    onChange={(event) => addNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Label className="label_text">
                    Select serviceable products
                  </Form.Label>
                </Form.Group>

                <div className="borderBoxCheckBox">
                  <fieldset className="checkbox-group">
                    {addNewCarrierdetails.allProducts &&
                    addNewCarrierdetails.allProducts.length > 0 ? (
                      addNewCarrierdetails.allProducts.map(
                        ({ productName, productId }) => (
                          <div className="checkbox" key={productId}>
                            <label className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                className="checkbox-input"
                                name="allSelectedProduct"
                                value={productName}
                                onChange={(event) =>
                                  addNewCarrierOnChangeHandle(event)
                                }
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-label">
                                  {productName}
                                </span>
                              </span>
                            </label>
                          </div>
                        )
                      )
                    ) : (
                      <span style={{ fontWeight: "bold" }}>No data found</span>
                    )}
                  </fieldset>
                </div>
              </div>

              <div className="row">
                <div>
                  <div className="formBox01 noBorder">
                    <div className="twoPart">
                      <div className="left">
                        <p className="head">Upload Carrier Logo</p>
                        {addNewCarrierdetails.carrierLogo && (
                          <img
                            src={addNewCarrierdetails.carrierLogo}
                            loading="lazy"
                            alt="Logo"
                            width={50}
                            height={50}
                          />
                        )}
                      </div>
                      <div className="right">
                        <div className="upload_btn_wrapper">
                          <input
                            type="file"
                            name="carrierLogo"
                            // value={addNewCarrierdetails.carrierLogo.type}
                            onChange={(event) =>
                              addNewCarrierOnChangeHandle(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className={`login sign_in_button ${
                    !isFieldAllSet && "deactive"
                  }`}
                  disabled={!isFieldAllSet}
                  onClick={() => addNnewcarrierPageOnClickHandle()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left"></div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={() => handleClose("")}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="twoPartCommon align-items-center">
                  <div className="left">Add states</div>
                  <div className="left">
                    <Button
                      className="button_blueBorder_small"
                      onClick={selectAllStateHandleOnClick}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group">
                      {addNewCarrierdetails.statesOfcarriers &&
                        addNewCarrierdetails.statesOfcarriers.map(
                          ({ stateCode, stateId }) => (
                            <div className="checkbox" key={stateId}>
                              <label className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  name="statesName"
                                  value={stateCode}
                                  id={stateId}
                                  onChange={(event) =>
                                    addNewCarrierOnChangeHandle(event)
                                  }
                                  checked={addNewCarrierdetails.statesIds.includes(
                                    stateId
                                  )}
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => addStateOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default AddNnewcarrierPage;
