import React from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import { Row, Col } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;

const AgentMain = () => {
  const navigate = useNavigate();
  return (
    <LayoutMain sideBarActive={4}>
      <>
        <div className="mainBanner">
          <div className="carriersMainBg">
            <img
              src={IMAGE_PATHS.Become_an_Agent_Bg}
              loading="lazy"
              alt="Logo"
              width={""}
            />
            <div className="container carriersMainContainer">
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <h1>Become an Agent</h1>
                  <p className="AgentMain_P">
                    Class aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himeClass aptent taciti sociosqu
                  </p>
                  <div className="join_now_button_cover">
                    <Button
                      className="join_now_button"
                      onClick={() => navigate(`${baseUrl}/SignUp`)}
                    >
                      Join Now
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="container AgentMain">
          <div></div>
          <p className="AgentMain_BlueBox_top_P">
            Ut sodales, ex sit amet consectetur accumsan, nibh ex sollicitudin
            metus, volutpat lacinia arcu nibh vel ante. Proin dapibus dui eget
            justo tincidunt eleifend. Mauris porta elemen
          </p>
          <div className="AgentMain_BlueBox">
            <h2>Join become an agent like you, who are interested in</h2>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit
                amet justo ipsum. Sed accumsan quam vitae est varius fringilla.{" "}
              </li>
              <li>
                Cras turpis risus, imperdiet vel urna vel, maximus efficitur
                turpis.
              </li>
              <li>
                Vivamus finibus felis posuere ex porta gravida. Donec hendrerit
                pretium purus
              </li>
              <li>
                Nullam nisl nisi, iaculis eget lacus quis, volutpat pretium
                neque. Suspendisse potenti
              </li>
            </ul>
            <Button
              className="join_now_button"
              onClick={() => navigate(`${baseUrl}/SignUp`)}
            >
              Join Now
            </Button>
          </div>
          <div className="Grow_with_us">
            <h2>Grow with us</h2>
            <Row>
              <Col sm={6} className="Grow_with_us_box">
                <div>
                  <img
                    src={IMAGE_PATHS.Grow_with_us_box_img_01}
                    loading="lazy"
                    alt="images"
                    width="100%"
                  />
                </div>
                <div>
                  <h3>Build relationships</h3>
                  <p>
                    Aenean sit amet nisl interdum, auctor dolor sit amet,
                    malesuada leo. Vivamus vitae augue
                  </p>
                </div>
              </Col>
              <Col sm={6} className="Grow_with_us_box">
                <div>
                  <img
                    src={IMAGE_PATHS.Grow_with_us_box_img_02}
                    loading="lazy"
                    alt="images"
                    width="100%"
                  />
                </div>
                <div>
                  <h3>Grow with us</h3>
                  <p>
                    Aenean sit amet nisl interdum, auctor dolor sit amet,
                    malesuada leo. Vivamus vitae augue
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="becoming_an_agent">
            <h3>Interested in becoming an Agent?</h3>
            <Button className="" onClick={() => navigate(`${baseUrl}/SignUp`)}>
              Join Now
            </Button>
          </div>
        </div>
      </>
    </LayoutMain>
  );
};

export default AgentMain;
