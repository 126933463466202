import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import IMAGE_PATHS from "../constants";
import { useNavigate } from "react-router-dom";
import ApiClient from "../ApiClient/ApiClient";
import Swal from "sweetalert2";
import { uploadImage } from "./FunctionHelper";
import Loader from "./Loader/Loader";
import { PostCallsAdmin, PostCallsAgent } from "../ApiClient/PostCall";
import { lastLocation } from "../Utils/Locations";

let tabs = ["Edit Profile", "Reset Password"];

const ProfileSettingsCommon = ({ profileDetails, location }) => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const { http } = ApiClient();

  const postAgent = PostCallsAgent(http, setIsLoader, Swal, navigate);
  const postAdmin = PostCallsAdmin(http, setIsLoader, Swal, navigate);

  const [userProfileDetails, setuserProfileDetails] = useState({
    profileImage: profileDetails?.profileImage,
    billingAddress: profileDetails?.billingAddress,
    firstName: profileDetails?.firstName,
    lastName: profileDetails?.lastName,
    tab: location?.state?.tab,
  });

  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    oldPass: "",
    newPass: "",
    confPass: "",
    isOldPass: 0,
    isNewPass: 0,
    isConfPass: 0,
  });

  const userProfileOnChangeHandle = (event) => {
    if (event.target.name === "profileImage") {
      uploadImage(
        event,
        Swal,
        setuserProfileDetails,
        userProfileDetails,
        event.target.name
      );
    } else {
      setuserProfileDetails({
        ...userProfileDetails,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  const eyeIconHandleOnClick = (type) => {
    if (type === "old") {
      setResetPasswordDetails({
        ...resetPasswordDetails,
        isOldPass: resetPasswordDetails.isOldPass ? 0 : 1,
      });
    } else if (type === "new") {
      setResetPasswordDetails({
        ...resetPasswordDetails,
        isNewPass: resetPasswordDetails.isNewPass ? 0 : 1,
      });
    } else {
      setResetPasswordDetails({
        ...resetPasswordDetails,
        isConfPass: resetPasswordDetails.isConfPass ? 0 : 1,
      });
    }
  };

  const resetPasswordDetailsOnChangeHandle = (event) => {
    let { name, value } = event.target;

    setResetPasswordDetails({ ...resetPasswordDetails, [name]: value });
  };

  const updateProfileOnClickHandle = async () => {
    let { profileImage, billingAddress, firstName, lastName } =
      userProfileDetails;
    if (!profileImage || !billingAddress || !firstName || !lastName) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let payload = {
      firstName,
      lastName,
      profileImage,
      billingAddress,
    };

    setIsLoader(true);

    let data =
      lastLocation(location) === "ProfileSettingsAgent"
        ? await postAgent.updateUserProfileAgent(payload)
        : await postAdmin.updateProfileAdmin(payload);

    Swal.fire({
      icon: "success",
      // title: "Oops...",
      text: data?.message || "Profile saved successfully!",
      confirmButtonText: "OK",
    }).then(() => {
      sessionStorage.setItem(
        "profileDetails",
        JSON.stringify({
          firstName,
          lastName,
          billingAddress,
          profileImage,
        })
      );
      window.location.reload(true);
    });
    setIsLoader(false);
  };

  //   reset password handle

  const resetPasswordOnClickHandle = async () => {
    let { newPass, oldPass, confPass } = resetPasswordDetails;
    if (!oldPass || !newPass || !confPass) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    if (newPass !== confPass) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Password doesn't match!",
        confirmButtonText: "CANCEL",
      });
    }
    if (newPass === oldPass) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "New password is same as old password!",
        confirmButtonText: "CANCEL",
      });
    }
    let payload = {
      oldpassword: resetPasswordDetails.oldPass,
      password: resetPasswordDetails.newPass,
    };
    setIsLoader(true);
    let data =
      lastLocation(location) === "ProfileSettingsAgent"
        ? await postAgent.changeUserPasswordAgent(payload)
        : await postAdmin.changePasswordAdmin(payload);

    Swal.fire({
      icon: "success",
      // title: "Oops...",
      text: data?.message || "Password have been changed successfully!",
      confirmButtonText: "OK",
    }).then(() => {
      setResetPasswordDetails({
        ...resetPasswordDetails,
        oldPass: "",
        newPass: "",
        confPass: "",
      });
    });

    setIsLoader(false);
  };

  useEffect(() => {
    location?.state?.tab &&
      setuserProfileDetails({
        ...userProfileDetails,
        tab: location?.state?.tab,
      });

    return () => {};
    // eslint-disable-next-line
  }, [location?.state?.tab]);

  return (
    <Fragment>
      {isLoader && <Loader />}

      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Profile Settings</h1>
          <div>
            {/* <Link to="#">Dashboard</Link> <span>/ Search History</span> */}
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="orangeActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  {tabs.map((tab, ind) => (
                    <div className="checkbox" key={ind}>
                      <label className="checkbox-wrapper">
                        <input
                          type="radio"
                          className="checkbox-input"
                          name="isActive"
                          checked={tab === userProfileDetails.tab}
                          onChange={() =>
                            setuserProfileDetails({
                              ...userProfileDetails,
                              tab: tab,
                            })
                          }
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-label">{tab}</span>
                        </span>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
            <div className="right"></div>
          </div>
          <div className="AdminDashboardWhiteBody">
            <div className="Container357">
              {userProfileDetails.tab === "Edit Profile" && (
                <div className="profile_image_area">
                  <div className="profile_image_area_box">
                    <img
                      src={
                        userProfileDetails.profileImage
                          ? userProfileDetails.profileImage
                          : IMAGE_PATHS.userBlankIcon
                      }
                      loading="lazy"
                      alt="Logo"
                      width={"120px"}
                    />
                    <div className="profile_image_area_edit">
                      <Button
                        className="profile_image_area_edit_button"
                        onClick={handleEditIconClick}
                      >
                        <img
                          src={IMAGE_PATHS.edit_icon_table}
                          loading="lazy"
                          alt="Logo"
                          width={"20px"}
                        />
                      </Button>
                      <Form.Control
                        type="file"
                        className="profile_image_area_edit_fileUpload"
                        ref={fileInputRef}
                        name="profileImage"
                        onChange={userProfileOnChangeHandle}
                      />
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="col">
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={userProfileDetails.firstName}
                            onChange={userProfileOnChangeHandle}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="col">
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={userProfileDetails.lastName}
                            onChange={userProfileOnChangeHandle}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col">
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Billing Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="billingAddress"
                            value={userProfileDetails.billingAddress || ""}
                            onChange={userProfileOnChangeHandle}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="cal-12 twoButtonSideBySide2 mt-0">
                      {/* <button
                        className="button_grayBorder w-100 justify-content-center"
                        onClick={() => window.location.reload(true)}
                      >
                        Cancel
                      </button> */}
                      <button
                        className="button_lightBlueFill w-100 justify-content-center"
                        onClick={updateProfileOnClickHandle}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {userProfileDetails.tab === "Reset Password" && (
                <div className="reset_password_area">
                  <p>Reset Password</p>
                  <div className="row w-100">
                    <div className="col">
                      <Form>
                        <InputGroup className="InputGroupCVV mb-3">
                          <Form.Control
                            placeholder="Old Password"
                            type={
                              !resetPasswordDetails.isOldPass
                                ? "password"
                                : "text"
                            }
                            name="oldPass"
                            value={resetPasswordDetails.oldPass}
                            onChange={resetPasswordDetailsOnChangeHandle}
                          />
                          <Button
                            variant="outline-secondary"
                            // id="button-addon2"
                            onClick={() => eyeIconHandleOnClick("old")}
                          >
                            <img
                              src={
                                !resetPasswordDetails.isOldPass
                                  ? IMAGE_PATHS.eye_close
                                  : IMAGE_PATHS.eye_open
                              }
                              loading="lazy"
                              alt="Logo"
                              width={"16px"}
                            />
                          </Button>
                        </InputGroup>

                        <InputGroup className="InputGroupCVV mb-3">
                          <Form.Control
                            placeholder="New Password"
                            type={
                              !resetPasswordDetails.isNewPass
                                ? "password"
                                : "text"
                            }
                            name="newPass"
                            value={resetPasswordDetails.newPass}
                            onChange={resetPasswordDetailsOnChangeHandle}
                          />
                          <Button
                            variant="outline-secondary"
                            // id="button-addon2"
                            onClick={() => eyeIconHandleOnClick("new")}
                          >
                            <img
                              src={
                                !resetPasswordDetails.isNewPass
                                  ? IMAGE_PATHS.eye_close
                                  : IMAGE_PATHS.eye_open
                              }
                              loading="lazy"
                              alt="Logo"
                              width={"16px"}
                            />
                          </Button>
                        </InputGroup>
                        <InputGroup className="InputGroupCVV">
                          <Form.Control
                            placeholder="Confirm Password"
                            type={
                              !resetPasswordDetails.isConfPass
                                ? "password"
                                : "text"
                            }
                            name="confPass"
                            value={resetPasswordDetails.confPass}
                            onChange={resetPasswordDetailsOnChangeHandle}
                          />
                          <Button
                            variant="outline-secondary"
                            // id="button-addon2"
                            onClick={() => eyeIconHandleOnClick("conf")}
                          >
                            <img
                              src={
                                !resetPasswordDetails.isConfPass
                                  ? IMAGE_PATHS.eye_close
                                  : IMAGE_PATHS.eye_open
                              }
                              loading="lazy"
                              alt="Logo"
                              width={"16px"}
                            />
                          </Button>
                        </InputGroup>
                      </Form>
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="col-12">
                      <button
                        className="button_lightBlueFill w-100 justify-content-center"
                        onClick={resetPasswordOnClickHandle}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileSettingsCommon;
