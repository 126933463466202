import React from "react";
import { Col, Form } from "react-bootstrap";
import IMAGE_PATHS from "../constants";
import { lastLocation } from "../Utils/Locations";
import { useLocation } from "react-router-dom";

const CommonQuestionAnswers = ({
  searchDetails,
  productQuestionOnChangeHandle,
}) => {
  const location = useLocation();
  return (
    <Col xs="" lg="4" className="AgentDashLeft">
      <div className="white_data_box">
        {Object.keys(searchDetails.allSearchedDetails).length > 1 ? (
          searchDetails.allSearchedDetails.productQuestion.length ? (
            searchDetails.allSearchedDetails.productQuestion?.map(
              ({
                masterFieldType,
                productQuestionId,
                questionLabel,
                questionLabelCode,
                isCurrentYearField,
                optionType,
              }) =>
                masterFieldType.fieldType === "Text" ? (
                  <div
                    className="formBox01"
                    key={productQuestionId}
                    style={{ width: "100%" }}
                  >
                    <div className="topPart">
                      <h3
                        className="heading01"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {questionLabel}
                        {isCurrentYearField === 2 && (
                          <img
                            width={24}
                            src={IMAGE_PATHS.tooltip_icon}
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                            title="Current Year"
                            alt="currentYear"
                          />
                        )}
                      </h3>
                    </div>
                    <div>
                      <Form.Group
                        className="width125"
                        controlId="formBasicEmail"
                        style={{ width: "55%" }}
                      >
                        <Form.Control
                          type="text"
                          placeholder={`Enter ${questionLabel}`}
                          name={questionLabelCode}
                          className="form_control_2"
                          onBlur={(event) =>
                            productQuestionOnChangeHandle(
                              event,
                              productQuestionId,
                              "Text"
                            )
                          }
                          defaultValue={
                            (lastLocation(location) ===
                              "AgentDashboardSearch" &&
                              location?.state?.repeatSearchData?.questions?.find(
                                (q) =>
                                  q.questionId === productQuestionId &&
                                  q.type === "Text"
                              )?.answer) ||
                            ""
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                ) : (
                  <div
                    className="formBox01"
                    key={productQuestionId}
                    style={{ width: "100%" }}
                  >
                    <div className="topPart">
                      <h3 className="heading01">{questionLabel}</h3>
                    </div>
                    <div className="borderBoxCheckBox">
                      <fieldset className="checkbox-group">
                        {optionType.map((optionValues, ind) => (
                          <div key={ind} className="checkbox">
                            <label className="checkbox-wrapper">
                              <input
                                type="radio"
                                className="checkbox-input"
                                name={questionLabelCode}
                                value={optionValues}
                                onChange={(event) =>
                                  productQuestionOnChangeHandle(
                                    event,
                                    productQuestionId,
                                    "Option"
                                  )
                                }
                                defaultChecked={
                                  lastLocation(location) ===
                                    "AgentDashboardSearch" &&
                                  location?.state?.repeatSearchData?.questions?.find(
                                    (q) => q.questionId === productQuestionId
                                  )?.answer === optionValues
                                }
                              />
                              <span className="checkbox-tile">
                                <span className="checkbox-label">
                                  {optionValues}
                                </span>
                              </span>
                            </label>
                          </div>
                        ))}
                      </fieldset>
                    </div>
                  </div>
                )
            )
          ) : (
            <div>No Data Found</div>
          )
        ) : (
          <div>No Data Found</div>
        )}

        {/* {

                } */}
        {/* <div>No Data Found</div> */}
      </div>
    </Col>
  );
};

export default CommonQuestionAnswers;
