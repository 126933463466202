import React from "react";
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getMonthAbbreviation } from "../ChartsUtils/ChartsUtils";

const MonthWIseUserSearch = ({ reportLists }) => {
  return (
    <div className="whiteBoxShadow mt-3">
      <h3>Month Wise User Search</h3>
      {/* <Link to={`${baseUrl}/Admin/MonthlySwarchHistory`}> */}
      {reportLists.userWise.length ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={reportLists.userWise} layout="vertical">
            <XAxis
              type="number"
              interval={"preserveStartEnd"}
              style={{
                fontSize: "10px",
              }}
              axisLine={false}
              tickLine={false}
              domain={[0, reportLists.userWise.length + 8]}
            />
            <YAxis
              dataKey="month"
              type="category"
              axisLine={false}
              tickLine={false}
              tickFormatter={getMonthAbbreviation}
              style={{
                fontSize: "10px",
              }}
            />
            {reportLists.users.map((user) => (
              <Bar
                key={user}
                dataKey={user}
                stackId="a"
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
              >
                <LabelList
                  dataKey={user}
                  position="middle"
                  style={{ fill: "white", fontSize: "10" }}
                  formatter={(value) => `${value}%`}
                />
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div>No Data Found</div>
      )}
    </div>
  );
};

export default MonthWIseUserSearch;
