import React from "react";
import IMAGE_PATHS from "../../../constants";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { lastLocation } from "../../../Utils/Locations";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import { PostCallsAgent } from "../../../ApiClient/PostCall";
import CommonHeader from "../../../components/CommonHeader";
import { useLocation, useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;

const HeaderAgent = () => {
  let profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));
  let profileImage = profileDetails?.profileImage || IMAGE_PATHS.userBlankIcon;
  const location = useLocation();
  const navigate = useNavigate();

  const { http } = ApiClient();
  const [, setIsLoader] = useState(false);

  const postAgent = PostCallsAgent(http, setIsLoader, Swal, navigate);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await postAgent.logoutAgent();
  };

  return (
    <CommonHeader
      profileDetails={profileDetails}
      profileImage={profileImage}
      show={show}
      handleShow={handleShow}
      handleClose={handleClose}
      location={
        lastLocation(location) === "AgentDashboard" ||
        lastLocation(location) === "AgentDashboardSearch" ? null : (
          <li>
            <Button
              className="darkBlueButton"
              onClick={() => navigate(`${baseUrl}/AgentDashboard`)}
            >
              Search Carrier
            </Button>
          </li>
        )
      }
      portalTypeLocation={"agent"}
    />
  );
};

export default HeaderAgent;
