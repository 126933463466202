import moment from "moment";
import { PostCallsCommon } from "../ApiClient/PostCall";
import Swal from "sweetalert2";

export const getIndividualHistory = async (
  setcurrCust,
  obj,
  setLoading,
  http,
  setOneHist,
  setViewShow,
  navigate,
  Swal,
  type
) => {
  const postCommon = PostCallsCommon(http, setLoading, Swal, navigate, type);

  setcurrCust(obj);
  const {
    agency,
    product,
    productId,
    createdOn,
    matchedCarrier,
    createdBy,
    lastUpdatedOn,
    reviewdcarrier,
    state,
    stateId,
    customerName,
  } = obj;
  let payload = {
    agency: agency,
    product: product,
    productId: productId,
    createdOn: createdOn,
    matchedCarrier: matchedCarrier,
    createdBy: createdBy,
    lastUpdatedOn: lastUpdatedOn,
    reviewdcarrier: reviewdcarrier,
    state: state,
    stateId: stateId,
    customerName: customerName,
  };

  setLoading(true);
  let data = await postCommon.getIndSearchHistoryCommon(payload);
  setOneHist(data);
  setViewShow(true);
  setLoading(false);
};

//ALL the CommonAPi for underGuid and UnderGuildelines start
const onChangeHandleState = (
  underguideLinedetails,
  setunderguideLinedetails,
  id
) => {
  const items = underguideLinedetails.stateIds;
  const index = items.indexOf(id);

  if (index !== -1) {
    items.splice(index, 1);
  } else {
    items.push(id);
  }
  setunderguideLinedetails({
    ...underguideLinedetails,
    stateIds: items,
  });
};

const dependableOnChange = (event, dependableDetails, setDependableDetails) => {
  let { name } = event.target;

  dependableDetails[name].push({});
  setDependableDetails({
    ...dependableDetails,
  });
};

const dependentsQuestionTextOptionOnChangeHandle = (
  event,
  details,
  type,
  ind,
  dependableDetails,
  setDependableDetails
) => {
  let { value, name } = event.target;

  let questionDetails = details;

  if (type === "min") {
    questionDetails.minValue = value;
  } else if (type === "max") {
    questionDetails.maxValue = value;
  } else {
    const updatedItems = questionDetails.optionValue?.map((elem) => {
      if (elem[value] !== undefined) {
        return {
          ...elem,
          [value]: elem[value] ? 0 : 1,
        };
      }
      return elem;
    });
    questionDetails.optionValue = updatedItems;
  }

  let updatedDependableDetails = [...dependableDetails[`${name}`]];
  updatedDependableDetails[ind] = questionDetails;

  setDependableDetails({
    ...dependableDetails,
    [name]: updatedDependableDetails,
  });
};

const onChangeHandleLogo = (
  event,
  underguideLinedetails,
  setunderguideLinedetails,
  type
) => {
  const file = event.target.files[0];
  let value = event.target.value.split("\\");

  if (file) {
    if (!file.type.includes("pdf")) {
      Swal.fire({
        icon: "error",
        // title: "Invalid File Type",
        text: "Please upload only PDF files.",
        confirmButtonText: "OK",
      });
      // Clear the file input
      event.target.value = null;
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      type === "underGuideLine"
        ? setunderguideLinedetails({
            ...underguideLinedetails,
            carrierPolicy: reader.result,
            logoFileTitle: value[value.length - 1],
          })
        : setunderguideLinedetails({
            ...underguideLinedetails,
            logoFileTitle: value[value.length - 1],
          });
    };

    reader.readAsDataURL(file);
  }
};

const underGuideLineOnChangeHandles = (
  event,
  type,
  underguideLinedetails,
  setunderguideLinedetails
) => {
  let { name, value } = event.target;

  if (
    !underguideLinedetails?.carrierQuestionAnswer?.questinAnswerSatate?.length
  ) {
    if (type.masterFieldType.fieldType === "Option") {
      if (!underguideLinedetails[name]) {
        setunderguideLinedetails({
          ...underguideLinedetails,
          [name]: [value],
        });
      } else {
        let items = underguideLinedetails[name];
        let index = items.indexOf(value);
        if (index !== -1) {
          items.splice(index, 1);
        } else {
          items.push(value);
        }
        setunderguideLinedetails({
          ...underguideLinedetails,
          [name]: items,
        });
      }
    }

    if (type.masterFieldType.fieldType === "Text") {
      setunderguideLinedetails({
        ...underguideLinedetails,
        [name]: value,
      });
    }
  } else {
    const newUnderGuideLineDetails = { ...underguideLinedetails };
    type.forEach(({ optionType }) => {
      if (optionType === "Text") {
        newUnderGuideLineDetails[name] = value;
        newUnderGuideLineDetails[name] = value;
      }
    });
    setunderguideLinedetails(newUnderGuideLineDetails);
  }
};

const handleCheckboxesOnChange = (
  event,
  type,
  productQuestionEntity,
  dependableDetails,
  setDependableDetails,
  underguideLinedetails,
  setunderguideLinedetails
) => {
  let maxValue = `${productQuestionEntity.questionLabelCode}2`;
  let { name, checked } = event.target;
  if (type === "dependent") {
    if (checked) {
      productQuestionEntity.maxValue = null;
      setDependableDetails({
        ...dependableDetails,
        [name]: 2,
      });
    } else {
      productQuestionEntity.maxValue = moment().year();

      setDependableDetails({
        ...dependableDetails,
        [name]: 0,
      });
    }
  } else {
    if (checked) {
      setunderguideLinedetails({
        ...underguideLinedetails,
        [name]: 2,
        [maxValue]: null,
      });
    } else {
      setunderguideLinedetails({
        ...underguideLinedetails,
        [name]: 0,
        [maxValue]: moment().year(),
      });
    }
  }
};

const onChangeHandleForOption = (
  event,
  underguideLinedetails,
  setunderguideLinedetails
) => {
  let { name, value } = event.target;
  const updatedItems = underguideLinedetails[name].map((elem) => {
    if (elem[value] !== undefined) {
      return {
        ...elem,
        [value]: elem[value] ? 0 : 1,
      };
    }

    return elem;
  });
  setunderguideLinedetails({
    ...underguideLinedetails,
    [name]: updatedItems,
  });
};

const answerLogicForText = (
  answer,
  question,
  underguideLinedetails,
  convertedAnswers
) => {
  answer = {
    questionId: question.productQuestionId,
    optionType: "Text",
    minValue: underguideLinedetails[`${question.questionLabelCode}1`] || "",
    maxValue: underguideLinedetails[`${question.questionLabelCode}2`] || null,
    depenedentQuestionRequests: convertedAnswers,
  };
  return answer;
};

const selectedTextBoxLogic = (
  question,
  answer,
  underguideLinedetails,
  convertedAnswers
) => {
  const selectedCheckboxes = (
    underguideLinedetails[question.questionLabelCode] || []
  ).filter((checkbox) => checkbox[Object.keys(checkbox)[0]] === 1);
  answer = {
    questionId: question.productQuestionId,
    optionType: "Option",
    optionValue: selectedCheckboxes.map((checkbox) => Object.keys(checkbox)[0]),
    depenedentQuestionRequests: convertedAnswers,
  };
  return answer;
};

const answerLogicForProductQuestion = (
  data,
  getData,
  dependableDetails,
  setDependentCurrentYear,
  newUnderGuideLineDetails
) => {
  let answers = data.questinAnswerSatate[0].answers;
  answers.forEach(
    ({
      optionType,
      dependentAnswers,
      optionValue,
      minValue,
      maxValue,
      productQuestionEntity,
    }) => {
      let filterDependentArray = getData(dependentAnswers);
      const dynamicKey = `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`;
      dependableDetails[dynamicKey] = filterDependentArray;
      setDependentCurrentYear(dependentAnswers);

      if (optionType === "Text") {
        newUnderGuideLineDetails[
          `${productQuestionEntity.questionLabelCode}1`
        ] = minValue;
        newUnderGuideLineDetails[
          `${productQuestionEntity.questionLabelCode}2`
        ] = maxValue;
        if (productQuestionEntity.isCurrentYearField) {
          newUnderGuideLineDetails[
            `${productQuestionEntity.questionLabelCode}3`
          ] = 2;
        } else {
          newUnderGuideLineDetails[
            `${productQuestionEntity.questionLabelCode}3`
          ] = 0;
        }

        if (filterDependentArray.length) {
          dependableDetails[productQuestionEntity.questionLabelCode] = 1;
        } else {
          dependableDetails[productQuestionEntity.questionLabelCode] = 0;
        }
      }

      if (optionType === "Option") {
        newUnderGuideLineDetails[`${productQuestionEntity.questionLabelCode}`] =
          optionValue;
        if (filterDependentArray.length) {
          dependableDetails[productQuestionEntity.questionLabelCode] = 1;
        } else {
          dependableDetails[productQuestionEntity.questionLabelCode] = 0;
        }
      }
    }
  );
};

export {
  onChangeHandleState,
  dependableOnChange,
  dependentsQuestionTextOptionOnChangeHandle,
  onChangeHandleLogo,
  underGuideLineOnChangeHandles,
  handleCheckboxesOnChange,
  onChangeHandleForOption,
  answerLogicForText,
  selectedTextBoxLogic,
  answerLogicForProductQuestion,
};
//ALL the CommonAPi for underGuid and UnderGuildelines End
