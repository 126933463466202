import React from "react";
import { Button, Modal } from "react-bootstrap";

const CommonDislikeModal = ({
  show2,
  handleClose2,
  searchDetails,
  likeDislikeOnClickHandle,
}) => {
  return (
    <Modal
      show={show2}
      onHide={handleClose2}
      className="disLikeModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="orangeRadio">
        <p className="imageArea">
          <img
            src={searchDetails.dislikeLogo}
            loading="lazy"
            alt="Logo"
            width={"148px"}
            height={"43px"}
          />
        </p>
        <p className="headingArea">Please tell us what wrong or right</p>

        <form action="#">
          {searchDetails.dislikedQuestions?.map(
            ({ productQuestionId, questionLabel, dislikeAnswer }) => (
              <div
                className="formBox01"
                key={productQuestionId}
                style={{ width: "100%" }}
              >
                <p className="subHeadArea">{questionLabel}</p>
                <div className="d-flex align-items-center">
                  <Button
                    className={`thumb_icon_like ${
                      dislikeAnswer === 1 && "active"
                    }`}
                    onClick={() =>
                      likeDislikeOnClickHandle("like", productQuestionId)
                    }
                  />
                  <span className="devider_01">|</span>
                  <Button
                    className={`thumb_icon_dislike ${
                      dislikeAnswer === 2 && "active"
                    }`}
                    onClick={() =>
                      likeDislikeOnClickHandle("dislike", productQuestionId)
                    }
                  />
                </div>
              </div>
            )
          )}
        </form>
        <div className="buttn_row mt_30">
          <Button
            className="button_blue_100"
            onClick={() => handleClose2("confirm")}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommonDislikeModal;
