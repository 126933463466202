import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import StateModal from "../../../components/Modal/StateModal";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import MyLoader from "../../../components/Loader/myLoader";
import Paginations from "../../../components/Pagination";
import SearchHistoryCalendar from "../../../components/Calendar/SearchHistoryCalendar";
import SearchHistoryTable from "../../../components/SearchHistoryTable";
import SearchHistoryDetailsModal from "../../../components/Modal/SearchHistoryDetailsModal";
import { PostCallsAdmin, PostCallsCommon } from "../../../ApiClient/PostCall";
import { getIndividualHistory } from "../../../Utils/CommonApi";

const SearchHistory = () => {
  const [viewShow, setViewShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const { http } = ApiClient();
  const navigate = useNavigate();

  const postAdmin = PostCallsAdmin(http, setLoading, Swal, navigate);
  const [page, setPage] = useState(1);

  const [stateShow, setStateShow] = useState(false);
  let date = JSON.parse(sessionStorage.getItem("todaySearch"));

  const postCommon = PostCallsCommon(http, setLoading, Swal, navigate, "admin");

  const [searchHistoryDetails, setSearchHistoryDetails] = useState({
    agencyName: null,
    customerName: null,
    product: null,
    state: { stateName: "", stateId: "" },
    date: date?.date || [],
    page: 1,
  });
  const [searchHistoryLists, setsearchHistoryLists] = useState([]);
  const [products, setProducts] = useState([]);
  const [oneHist, setOneHist] = useState([
    {
      date: "2023-11-25",
      searchQuestionAnswer: [
        {
          questionAnswer: {
            additionalProp1: "string",
            additionalProp2: "string",
            additionalProp3: "string",
          },
          matchedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
          confermedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
        },
      ],
    },
  ]);
  const [currCust, setcurrCust] = useState({
    agency: "",
    product: "",
    productId: "",
    createdOn: "",
    matchedCarrier: "",
    createdBy: "",
    lastUpdatedOn: "",
    reviewdcarrier: "",
    state: "",
    stateId: "",
    customerName: "",
  });

  const ViewHandleClose = () => setViewShow(false);

  const ViewHandleShow = (obj) => {
    getIndividualHistory(
      setcurrCust,
      obj,
      setLoading,
      http,
      setOneHist,
      setViewShow,
      setLoading,
      navigate,
      Swal,
      "admin"
    );
  };

  const handleShow = () => {
    setStateShow(true);
  };

  const stateModalOnClickHandle = () => {
    setStateShow(false);
  };
  const stateModalOnChangeHandle = (event) => {
    const { name, value, id } = event.target;
    setSearchHistoryDetails({
      ...searchHistoryDetails,
      [name]: { stateName: value, stateId: id },
    });
  };

  const searchHistoryHandleOnChange = (event) => {
    let { value, name } = event.target;
    setSearchHistoryDetails({
      ...searchHistoryDetails,
      [name]: value || null,
    });
  };

  const searchHistoryOnClickHandle = async () => {
    let payload = {
      organizationName: searchHistoryDetails.agencyName,
      customerName: searchHistoryDetails.customerName,
      productId: parseInt(searchHistoryDetails.product),
      stateId: parseInt(searchHistoryDetails.state.stateId),
      startDate: searchHistoryDetails.date[0],
      endDate: searchHistoryDetails.date[1],
    };
    setLoading(true);
    let data = await postAdmin.getAdminSearchHistory(
      searchHistoryDetails.page,
      payload
    );
    setsearchHistoryLists(data);
    setLoading(false);
    sessionStorage.removeItem("todaySearch");
  };

  const getAllData = async () => {
    let payload = {
      organizationName: searchHistoryDetails.agencyName,
      customerName: searchHistoryDetails.customerName,
      productId: parseInt(searchHistoryDetails.product),
      stateId: parseInt(searchHistoryDetails.state.stateId),
      startDate: searchHistoryDetails.date[0],
      endDate: searchHistoryDetails.date[1],
    };
    setLoading(true);
    let data = await postCommon.getAllConnectedProducts();
    setProducts(data);

    let res = await postAdmin.getAdminSearchHistory(
      searchHistoryDetails.page,
      payload
    );
    setLoading(false);
    setsearchHistoryLists(res);
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [searchHistoryDetails.page]);

  return (
    <Layout sideBarActive={6}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Search History</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Search History</span>
          </div>
        </div>
        <div className="mt-3">
          <div className="searchHistoryFormBar">
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Agency Name"
                  name="agencyName"
                  value={searchHistoryDetails.agencyName || ""}
                  onChange={(event) => searchHistoryHandleOnChange(event)}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  name="customerName"
                  value={searchHistoryDetails.customerName || ""}
                  onChange={(event) => searchHistoryHandleOnChange(event)}
                />
              </Form.Group>
            </div>

            <li>
              <Form.Select
                aria-label="Default select example"
                name="product"
                value={searchHistoryDetails.product || ""}
                onChange={(event) => searchHistoryHandleOnChange(event)}
              >
                <option hidden>Select Product</option>
                {products?.map((prod) => (
                  <option key={prod?.productId} value={prod?.productId}>
                    {prod?.productName}
                  </option>
                ))}
              </Form.Select>
            </li>
            {/* <ConnectedProducts
              allProducts={products}
              searchdetails={searchHistoryDetails}
              handleCarrierSearchOnChange={searchHistoryHandleOnChange}
            /> */}

            <div>
              <Form.Select
                aria-label="Default select example"
                onClick={() => handleShow()}
                name="stateName"
              >
                <option hidden>
                  {searchHistoryDetails.state.stateName || "Select State"}
                </option>
              </Form.Select>
            </div>
            <SearchHistoryCalendar
              searchHistoryDetails={searchHistoryDetails}
              setSearchHistoryDetails={setSearchHistoryDetails}
            />

            <div onClick={() => searchHistoryOnClickHandle()}>
              <Button className="button_blue">
                <img
                  src={IMAGE_PATHS.search_icon_white}
                  loading="lazy"
                  alt="Logo"
                  width={"13px"}
                />
                Search
              </Button>
            </div>
          </div>
        </div>

        <SearchHistoryTable
          searchHistoryLists={searchHistoryLists}
          ViewHandleShow={ViewHandleShow}
          loading={loading}
        />

        {searchHistoryLists?.length > 0 ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={searchHistoryLists[0].count}
          />
        ) : null}

        <SearchHistoryDetailsModal
          viewShow={viewShow}
          ViewHandleClose={ViewHandleClose}
          currCust={currCust}
          oneHist={oneHist}
        />

        <StateModal
          stateShow={stateShow}
          setStateShow={setStateShow}
          stateModalOnClickHandle={stateModalOnClickHandle}
          stateModalOnChangeHandle={stateModalOnChangeHandle}
          selectedState={parseInt(searchHistoryDetails.state.stateId)}
        />
      </div>
    </Layout>
  );
};

export default SearchHistory;
