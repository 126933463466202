import {
  isLogin,
  isLoginAgent,
  isLoginMain,
} from "../components/IsLogin/IsLogin";
const baseUrl = process.env.REACT_APP_BASE_URL;

const handleError = (error, Swal, navigate, portalType) => {
  if (portalType === "agent") {
    isLoginAgent(error, navigate, Swal);
  } else if (portalType === "admin") {
    isLogin(error, navigate, Swal);
  } else {
    isLoginMain(error, Swal);
  }
};

const PostCallsMain = (http, setIsLoader, Swal) => {
  return {
    getAllState: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/getAllRiskerState");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getReview: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/getReviewDetails");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getProduct: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/getAllRiskerProduct");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getAllCarriers: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/getRiskerCarriers");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    searchCarrier: async (searchText) => {
      try {
        setIsLoader(true);
        const res = await http.get(`/page/searchCarrierByRandom/${searchText}`);
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    saveDemo: async (payload) => {
      try {
        setIsLoader(true);
        const res = await http.post("/page/saveDemo", payload);
        setIsLoader(false);

        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getAllValues: async () => {
      try {
        setIsLoader(true);
        const res = await http.get(`/page/getAllValues`);
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getAllTeams: async () => {
      try {
        setIsLoader(true);
        const res = await http.get(`/page/getTeamDetails`);
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getAllFAQ: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("page/getAllFAQ");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getContact: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/getAllRiskerContact");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    getSubscription: async () => {
      try {
        setIsLoader(true);
        const res = await http.get("/page/riskerAllSubscriptionPlan");
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },

    saveTextUs: async (payload) => {
      try {
        setIsLoader(true);
        const res = await http.post("/page/saveCustomer", payload);
        setIsLoader(false);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginMain(error, Swal);
      }
    },
  };
};

const PostCallsAgent = (http, setIsLoader, Swal, navigate) => {
  return {
    getSearchByMonth: async () => {
      try {
        const res = await http.get("/searchByMonth");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    searchUserByMonthAgent: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchUserByMonth",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    searchUserWiseOnlyAgent: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchUserWise",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    getSearchCarriersNumber: async () => {
      try {
        const res = await http.get("/getNumberOfSearchAndCarrier");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    searchBySearchIdAgent: async (id) => {
      try {
        const res = await http.post("/getSearchRequestBySearchId", id);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    getAgentSearchHistory: async (page, payload) => {
      try {
        const res = await http.post(`/getAgentSearchHistory/${page}`, payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    getAllCarriersAgent: async () => {
      try {
        const res = await http.get("/getAllCarrier");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    getAllConnectedCarriers: async () => {
      try {
        const res = await http.get("/connected");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    getAllNotConnectedCarriersAgent: async () => {
      try {
        const res = await http.get("/notConnected");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    saveConnectedCarriersAgent: async (payload) => {
      try {
        await http.post("/saveConnectedCarrier", payload);
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    disconnectedCarriersAgent: async (carrierId) => {
      try {
        await http.delete(`/disconnectCarrier/${carrierId}`);
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    loginAuth: async (payload) => {
      try {
        let res = await http.post("/login", payload);
        return res;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    addAgent: async (payload) => {
      try {
        let res = await http.post("/addagent", payload);
        return res;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    addUserAgent: async (payload) => {
      try {
        let res = await http.post("/addUser", payload);
        return res;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    changeUserPasswordAgent: async (payload) => {
      try {
        let res = await http.put("/changeUserPassword", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    updateUserProfileAgent: async (payload) => {
      try {
        let res = await http.put("/updateUserProfile", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },

    logoutAgent: async () => {
      try {
        setIsLoader(true);
        await http.get("/logout");
        sessionStorage.clear();
        navigate(`${baseUrl}`);
      } catch (error) {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
    },
  };
};

const PostCallsAdmin = (http, setIsLoader, Swal, navigate) => {
  return {
    getProductWiseSearch: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchAdminByProduct",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getMonthWiseSearch: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchAdminByMonth",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },
    getUsersByMonth: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchAllUserAdminByMonthWise",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getPaymentsByMonth: async () => {
      try {
        const res = await http.get("/agentPaymentByMonthAdmin");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getSubscriberCountByMonthAdmin: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchSubscriberCountByMonth",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getProductWithQuestion: async () => {
      try {
        const res = await http.get("/getAllProductWithQuestion");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getCarriersByStatusAdmin: async () => {
      try {
        const res = await http.get(`/getAllCarrierByStatus/${1}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getCarrierByIdAdmin: async (carrierId) => {
      try {
        const res = await http.get(`/getCarrierById/${carrierId}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    saveCarrierAdmin: async (payload) => {
      try {
        const res = await http.post("/saveCarrier", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    carrierSortAdmin: async (payload) => {
      try {
        const res = await http.post("/adminCarrierSort", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getAllCarrierQuestionAnswerAdmin: async (payload) => {
      try {
        const res = await http.post("/getAllCarrierQuestionAnswer", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    updateCarrierAdmin: async (payload) => {
      try {
        await http.put("/updateCarrier", payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getAdminSearchHistory: async (page, payload) => {
      try {
        const res = await http.post(`/getSearchHistory/${page}`, payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getProductByIdAdmin: async (productId) => {
      try {
        const res = await http.get(`/getProductById/${productId}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    saveProductAdmin: async (payload) => {
      try {
        await http.post("/saveProduct", payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    updateProductAdmin: async (payload) => {
      try {
        await http.put("updateProduct", payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getProductQuestionByProductIdAdmin: async (location) => {
      try {
        const res = await http.get(
          `/getProductQuestionByProductId/${location}`
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getProductQuestionByIdAdmin: async (questionId) => {
      try {
        const res = await http.get(`/getProductQuestionById/${questionId}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getProductQuestionOptionsByIdAdmin: async (questionId) => {
      try {
        const res = await http.get(`/getProductQuestionOption/${questionId}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    deleteProductQuestionAdmin: async (questionId) => {
      try {
        await http.delete(`/deleteProductQuestion/${questionId}`);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    saveProductQuestionAdmin: async (payload) => {
      try {
        await http.post(`/saveProductQuestion`, payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    updateProductQuestionAdmin: async (payload) => {
      try {
        await http.put(`/updateProductQuestion`, payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    productQuestionOrderByAdmin: async (payload) => {
      try {
        await http.post(`/orderBy`, payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getDependentQuestionAnswerAdmin: async (payload) => {
      try {
        const res = await http.post("/getDependentQuestionAnswer", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
        return;
      }
    },

    saveQuestionAnswerAdmin: async (payload) => {
      try {
        await http.post(`/saveCarrierQuestionAnswer`, payload);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    getPolicyDownloadAdmin: async (carrierId) => {
      try {
        return await http.get(`/getPolicy/${carrierId}`);
      } catch (error) {
        if (error.response.data?.isLogin) {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
          return;
        }
      }
    },

    loginAuthAdmin: async (payload) => {
      try {
        let res = await http.post("/adminLogin", payload);
        return res;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    changePasswordAdmin: async (payload) => {
      try {
        let res = await http.put("/adminChangePassword", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    updateProfileAdmin: async (payload) => {
      try {
        let res = await http.put("/updateAdminProfile", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },

    logoutAdmin: async () => {
      try {
        setIsLoader(true);
        await http.get("/adminLogout");
        sessionStorage.clear();
        navigate(`${baseUrl}/Admin`);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    },
  };
};

const PostCallsCommon = (http, setIsLoader, Swal, navigate, portalType) => {
  return {
    getAllStateCommon: async () => {
      try {
        const res = await http.get("/allState");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllProductCommon: async () => {
      try {
        const res = await http.get("/getAllProduct");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllRecentStateCommon: async () => {
      try {
        const res = await http.get("/getAllSearchState");
        return res;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllConnectedProducts: async () => {
      try {
        const res = await http.get("/getAllConnectedProduct");
        return res.data;
      } catch (error) {
        setIsLoader(false);

        handleError(error, Swal, navigate, portalType);
      }
    },

    getTodaysSearch: async () => {
      try {
        const res = await http.get("/getLastSearchDetails");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllCarriersAndOthers: async () => {
      try {
        const res = await http.get(
          "/getNumberofAgentsSubscribersCarriersSearch"
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    searchAllCarriersCommom: async (payload) => {
      try {
        const res = await http.post("/searchCarrier", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    policyDownloadCommon: async (carrierId) => {
      try {
        const res = await http.get(`/getPolicy/${carrierId}`);
        return res;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllCarrierLikeQuestionsCommon: async () => {
      try {
        const res = await http.get("/getAllCarrierLikeQuestions");
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllCarrierLikesCommon: async (carrierId) => {
      try {
        const res = await http.get(`/getCarrierLike/${carrierId}`);
        return res;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    saveCarrierLikesCommon: async (payload) => {
      try {
        await http.post(`/saveCarrierLike`, payload);
      } catch (error) {
        handleError(error, Swal, navigate, portalType);
      }
    },

    getAllCarrierDislikeCommon: async (carrierId) => {
      try {
        const res = await http.get(`/getCarrierDisLike/${carrierId}`);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    saveCarrierDisLikesCommon: async (payload) => {
      try {
        await http.post(`/saveCarrierDislike`, payload);
      } catch (error) {
        handleError(error, Swal, navigate, portalType);
      }
    },

    getSearchUserByProductCommon: async (startDate, endDate) => {
      try {
        const res = await http.post(
          "/searchUserByProduct",
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : null
        );
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },

    getIndSearchHistoryCommon: async (payload) => {
      try {
        const res = await http.post("/getOneSearchHistory", payload);
        return res.data;
      } catch (error) {
        setIsLoader(false);
        handleError(error, Swal, navigate, portalType);
      }
    },
  };
};

export { PostCallsMain, PostCallsAgent, PostCallsAdmin, PostCallsCommon };

const MainPostCalls = {
  getAllState: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerState");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getReview: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getReviewDetails");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getProduct: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerProduct");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllCarriers: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getRiskerCarriers");
      setIsLoader(false);
      return res?.data;
    } catch (error) {
      console.log(error, "rr");
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  searchCarrier: async (http, setIsLoader, Swal, searchText) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/searchCarrierByRandom/${searchText}`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  saveDemo: async (http, setIsLoader, Swal, payload) => {
    try {
      setIsLoader(true);
      const res = await http.post("/page/saveDemo", payload);
      setIsLoader(false);

      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllValues: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/getAllValues`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllTeams: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/getTeamDetails`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllFAQ: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("page/getAllFAQ");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getContact: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerContact");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getSubscription: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/riskerAllSubscriptionPlan");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  saveTextUs: async (http, setIsLoader, Swal, payload) => {
    try {
      setIsLoader(true);
      const res = await http.post("/page/saveCustomer", payload);
      setIsLoader(false);

      return res.data;
    } catch (error) {
      console.log(error, "E");
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },
};

export { MainPostCalls };
