import React, { useEffect } from "react";
import { useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
const baseUrl = process.env.REACT_APP_BASE_URL;

const UserManagementAgent = (props) => {
  const navigate = useNavigate();
  const [editStaffDetails, seteditStaffDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userId: 0,
    allStaff: [],
  });
  const [isLoader, setIsLoader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (userId, status) => {
    seteditStaffDetails({
      ...editStaffDetails,
      userId: userId,
      initialCheckboxState: status, // Set initial state of the checkbox
    });
    setShow(true);
  };

  const [editShow, setEditShow] = useState(false);
  const handleEditShowClose = () => setEditShow(false);
  const agentId = JSON.parse(sessionStorage.getItem("agentId"));

  const handleEditShow = (userId) => {
    setIsLoader(true);
    http
      .get(`/getUser/${aesUtil.encrypt(JSON.stringify(userId))}`, {})
      .then((res) => {
        seteditStaffDetails({
          ...editStaffDetails,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.userEmail,
          userId: userId,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });

    setEditShow(true);
  };

  const { http } = ApiClient();

  const handleSubmitEditButtonOnClick = () => {
    const { firstName, lastName, email } = editStaffDetails;

    if (!firstName || !lastName || !email) {
      return Swal.fire({
        title: "Oops...",
        text: "All Fields are Mandatory",
        confirmButtonText: "Cancel",
      });
    }

    let updateUserPayload = {
      userId: editStaffDetails.userId,
      agentEntity: {
        entityId: agentId,
      },
      firstName,
      lastName,
      userEmail: email,
    };
    setIsLoader(true);
    http
      .put("/updateUser", updateUserPayload)
      .then(() => {
        getAllUsers();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });

    handleEditShowClose();
  };

  const deleteUserOnClickHandle = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteUser/${aesUtil.encrypt(JSON.stringify(userId))}`, {})
          .then(() => {
            setIsLoader(false);
            getAllUsers();
          })
          .catch((error) => {
            setIsLoader(false);
            isLoginAgent(error, navigate, Swal);
          });
      }
    });
  };

  const editUserOnChangeHandle = (event) => {
    let { name, value } = event.target;
    seteditStaffDetails({ ...editStaffDetails, [name]: value });
  };
  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(
        `/inactiveUser/${aesUtil.encrypt(
          JSON.stringify(editStaffDetails.userId)
        )}`,
        {}
      )
      .then(() => {
        getAllUsers();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
    handleClose();
  };

  const getAllUsers = () => {
    setIsLoader(true);
    http
      .get("/getStafUser", {})
      .then((res) => {
        seteditStaffDetails({ ...editStaffDetails, allStaff: res.data });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllUsers();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <LayoutAgent sideBarActive={24}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>User Management</h1>
          <div className="rightWithButton">
            <div>
              <Link
                className={props.sideBarActive === 24 ? "active" : ""}
                to={`${baseUrl}/AddUserAgent`}
              >
                <Button className="button_blueBorder bgTransparent//">
                  <img
                    src={IMAGE_PATHS.phPlusBoldIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"16px"}
                  />
                  Add User
                </Button>
              </Link>
            </div>
            <Link to={`${baseUrl}/AgentDashboard`}>Dashboard</Link>
            <span>/ User Management</span>
          </div>
        </div>
        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Team Member</th>
                <th>Email address</th>
                <th>Account Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {editStaffDetails.allStaff.length ? (
                editStaffDetails.allStaff.map(
                  ({ firstName, lastName, userEmail, status, userId }) => (
                    <tr key={userId}>
                      <td>{firstName + " " + lastName}</td>
                      <td>{userEmail}</td>
                      <td>
                        <span
                          className={
                            status === 1
                              ? "active_green cursorPointer"
                              : "pending_red cursorPointer"
                          }
                          onClick={() => handleShow(userId, status)}
                        >
                          {status === 1 ? "Active" : "Pending"}
                        </span>
                      </td>
                      <td>
                        <span className="action_icon_container">
                          <span>
                            <img
                              src={IMAGE_PATHS.edit_icon_table}
                              loading="lazy"
                              alt="Logo"
                              width={"20px"}
                              onClick={() => handleEditShow(userId)}
                            />
                          </span>

                          <span onClick={() => deleteUserOnClickHandle(userId)}>
                            <img
                              src={IMAGE_PATHS.DeleteIconGray}
                              loading="lazy"
                              alt="Logo"
                              height={"18px"}
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td style={{ textAlign: "right" }}>No Data Found</td>
                  <td></td>

                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>

          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to inactive agency?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={editStaffDetails.initialCheckboxState}
                    onChange={() => editActiveInActiveOnChangeHandle()}
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>Edit User</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">First Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={editStaffDetails.firstName}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={editStaffDetails.lastName}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    value={editStaffDetails.email}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </LayoutAgent>
  );
};

export default UserManagementAgent;
