import React from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
const { RangePicker } = DatePicker;

const SearchHistoryCalendar = ({
  searchHistoryDetails,
  setSearchHistoryDetails,
}) => {
  const searchHistoryHandleOnChangeCalendar = (event) => {
    if (event) {
      setSearchHistoryDetails({
        ...searchHistoryDetails,
        date: event.map((values) => {
          return moment(values["$d"]).format("YYYY-MM-DD");
        }),
      });
    } else {
      setSearchHistoryDetails({ ...searchHistoryDetails, date: [] });
    }
  };

  return (
    <div className="dateButtonContainer" style={{ width: "250px" }}>
      <RangePicker
        value={() =>
          searchHistoryDetails.date.map((date) => moment(date)) || [null, null]
        }
        onChange={(event) => searchHistoryHandleOnChangeCalendar(event)}
        className="AgentRangePicker"
      />
    </div>
  );
};

export default SearchHistoryCalendar;
