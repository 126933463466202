import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CarriersLeftMenu from "../../../components/CarriersLeftMenu/CarriersLeftMenu";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import {
  ConvertedDependents,
  getData,
  setDependentCurrentYear,
} from "../../../components/ConvertedDependents/ConvertedDependents";
import {
  answerLogicForProductQuestion,
  answerLogicForText,
  dependentsQuestionTextOptionOnChangeHandle,
  handleCheckboxesOnChange,
  onChangeHandleLogo,
  selectedTextBoxLogic,
  underGuideLineOnChangeHandles,
} from "../../../Utils/CommonApi";
import { PostCallsAdmin } from "../../../ApiClient/PostCall";
import SelectedStateComponent from "./SelectedStateComponent";
import HandleOptionComponent from "./HandleOptionComponent";
import PolicyDownloadComponent from "./PolicyDownloadComponent";
import MinMaxCommonComponent from "./MinMaxCommonComponent";
import DependentCheckboxComponent from "./DependentCheckboxComponent";
import OpenDependentBoxComponent from "./OpenDependentBoxComponent";
import DependentAllComponent from "./DependentAllComponent";
// changes from here
const baseUrl = process.env.REACT_APP_BASE_URL;

const UnderGuide = () => {
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const postAdmin = PostCallsAdmin(http, setisLoader, Swal, navigate);

  const location = useLocation();
  const [carrierPolicy, setcarrierPolicy] = useState("");

  const [underguideLinedetails, setunderguideLinedetails] = useState({
    productBuilder: [],
    currentYearChecked: false,
    logoFile: "",
    logoFileTitle: "",
    stateIds: [],
    carrierPolicy: "",
    questionAnswer: [],
    carrierQuestionAnswer: { productBuilder: null, questinAnswerSatate: [] },
    allCarrier: location?.state?.allCarrier,
  });

  const [dependableDetails, setDependableDetails] = useState({});

  const underGuideLineOnChangeHandleLogo = (event) => {
    onChangeHandleLogo(
      event,
      underguideLinedetails,
      setunderguideLinedetails,
      "underGuideLine"
    );
  };

  const underGuideLineOnChangeHandle = (event, type) => {
    underGuideLineOnChangeHandles(
      event,
      type,
      underguideLinedetails,
      setunderguideLinedetails
    );
  };

  // dependable code

  // Function to handle addition of select boxes

  const handleAddMoreDependent = (productQuestionEntity) => {
    dependableDetails[
      `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
    ].push({});

    setDependableDetails({
      ...dependableDetails,
    });
  };

  const dependentQuestionTextOptionOnChangeHandle = (
    event,
    details,
    type,
    ind
  ) => {
    dependentsQuestionTextOptionOnChangeHandle(
      event,
      details,
      type,
      ind,
      dependableDetails,
      setDependableDetails
    );
  };

  const handleDependableQuestionOnChange = async (event, questionId, ind) => {
    let { value } = event.target;
    let url = location.pathname;
    let urlArray = url.split("/");
    let productId = urlArray[urlArray.length - 1];
    let carrierId = urlArray[urlArray.length - 3];

    let value_id = value.split("_");
    let questionValue = `${value_id[0]}_${questionId}`;

    let payload = {
      carrierId: +aesUtil.decrypt(carrierId),
      productId: +aesUtil.decrypt(productId),
      questionId: +questionId,
      dependentQuestionId: +value_id[1],
      stateIds: underguideLinedetails.stateIds,
    };

    setisLoader(true);
    let data = await postAdmin.getDependentQuestionAnswerAdmin(payload);
    setisLoader(false);
    let updatedDependableDetails = [...dependableDetails[`${questionValue}1`]];
    updatedDependableDetails[ind] = data;

    setDependableDetails({
      ...dependableDetails,
      [questionValue]: data,
      [`${questionValue}1`]: updatedDependableDetails,
    });
  };

  //dependable code

  const handleCheckboxOnChange = (event, productQuestionEntity, type) => {
    handleCheckboxesOnChange(
      event,
      type,
      productQuestionEntity,
      dependableDetails,
      setDependableDetails,
      underguideLinedetails,
      setunderguideLinedetails
    );
  };

  const underGuideLineButtonOnClickHandle = async () => {
    let url = location.pathname;
    let urlArray = url.split("/");
    let productId = aesUtil.decrypt(urlArray[urlArray.length - 1]);
    // let carrierId = aesUtil.decrypt(urlArray[urlArray.length - 3]);

    const selectedProduct =
      underguideLinedetails?.allCarrier?.productAndQuestion?.find(
        (product) => product.productBuilder.productId === +productId
      );

    const selectedProductQuestions = selectedProduct?.questionEntities;
    console.log(selectedProductQuestions, "S");

    const selectedStates = underguideLinedetails.stateIds.map(
      (selectedStateId) => ({
        stateId: selectedStateId,
        questionAnswer: selectedProductQuestions.map((question) => {
          const convertedAnswers = ConvertedDependents(
            dependableDetails[
              `${question?.questionLabel}_${question?.productQuestionId}1`
            ]
          );
          let answer = null;
          if (question.masterFieldType.fieldType === "Text") {
            let answerText = answerLogicForText(
              answer,
              question,
              underguideLinedetails,
              convertedAnswers
            );
            answer = answerText;
          } else if (question?.masterFieldType.fieldType === "Option") {
            //copystart
            let getAnswerCheck = selectedTextBoxLogic(
              question,
              answer,
              underguideLinedetails,
              convertedAnswers
            );
            answer = getAnswerCheck;

            //copyEnd
          }

          return answer;
        }),
      })
    );

    var payloadReq = {
      productId: productId,
      carrierPolicy: carrierPolicy,
      carrierId: underguideLinedetails.allCarrier.carrier.carrierId,
      productQuestionState: selectedStates,
    };
    setisLoader(true);
    await postAdmin.saveQuestionAnswerAdmin(payloadReq);
    setisLoader(false);
    navigate(`${baseUrl}/Admin/AddNewCarrierFinish`);
  };

  useEffect(() => {
    const getAllProductQuestion = async () => {
      setisLoader(true);
      let url = location.pathname;
      let urlArray = url.split("/");
      let productId = urlArray[urlArray.length - 1];
      let carrierId = urlArray[urlArray.length - 3];

      let payload = {
        carrierId: aesUtil.decrypt(carrierId),
        productId: aesUtil.decrypt(productId),
      };

      let data = await postAdmin.getAllCarrierQuestionAnswerAdmin({
        requestData: aesUtil.encrypt(JSON.stringify(payload)),
      });

      const allStateIds = data?.questinAnswerSatate.flatMap(
        (item) => item.states.stateId
      );

      let response = await postAdmin.getPolicyDownloadAdmin(
        aesUtil.encrypt(
          JSON.stringify(underguideLinedetails.allCarrier.carrier.carrierId)
        )
      );

      setcarrierPolicy(response?.data?.carrierPolicy || "");

      let newUnderGuideLineDetails = {
        ...underguideLinedetails,
        carrierQuestionAnswer: data,
        productBuilder: data.productBuilder,
        questionAnswer: data.questinAnswerSatate,
        stateIds: allStateIds,
      };

      if (Object.keys(data).length > 1) {
        //copyStart

        answerLogicForProductQuestion(
          data,
          getData,
          dependableDetails,
          setDependentCurrentYear,
          newUnderGuideLineDetails
        );

        //copyEnd

        setunderguideLinedetails(newUnderGuideLineDetails);
      }

      setisLoader(false);
    };
    getAllProductQuestion();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer unerGuide">
        <div className="head_bread">
          <h1>Add New Carrier</h1>
          <div>
            <Link to={`${baseUrl}/Admin/Carriers`}>Carriers</Link>
            <span>/ Add New Carrier</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <CarriersLeftMenu pageValue={"UnderGuide"} />
          <div className="left carriersRight">
            <div className="modal_body">
              <h1 style={{ textAlign: "center" }}>
                <img
                  src={location?.state?.carrierLogo}
                  alt="carrier_logo"
                  width={100}
                />
              </h1>
              <h2 className="headText">Underwriting Guidelines</h2>
              <p className="headingBottomText">Selected Products</p>

              <div className="tab_area">
                <div className="Container357">
                  <Tabs
                    id="uncontrolled-tab-example"
                    className="mb-3 underGuideTab"
                  >
                    <Tab
                      eventKey={
                        underguideLinedetails.productBuilder.productName
                      }
                      title={
                        underguideLinedetails.productBuilder.productName || ""
                      }
                    >
                      <div className="SelectedState">
                        <label className="SelectedStateLabel">
                          Selected State
                        </label>
                        <div className="SelectedStateRadio">
                          <fieldset className="checkbox-group">
                            {location?.state?.carrierStates?.length > 0 &&
                              location.state.carrierStates.map((states) => (
                                <SelectedStateComponent
                                  state={states}
                                  underguideLinedetails={underguideLinedetails}
                                  setunderguideLinedetails={
                                    setunderguideLinedetails
                                  }
                                />
                              ))}
                          </fieldset>
                        </div>
                      </div>

                      <div>
                        {underguideLinedetails.questionAnswer.length &&
                          underguideLinedetails.questionAnswer[0].answers.map(
                            ({ optionType, productQuestionEntity }) => (
                              <div
                                key={productQuestionEntity.productQuestionId}
                                className="formBox01"
                              >
                                <div className="topPart">
                                  <h2>
                                    {productQuestionEntity.questionLabel}
                                    {productQuestionEntity.isCurrentYearField ===
                                    2
                                      ? "(Year)"
                                      : null}
                                  </h2>
                                </div>
                                {optionType === "Text"
                                  ? productQuestionEntity.isDelete === 0 && (
                                      <div className="bottomPart">
                                        <MinMaxCommonComponent
                                          productQuestionEntity={
                                            productQuestionEntity
                                          }
                                          underguideLinedetails={
                                            underguideLinedetails
                                          }
                                          underGuideLineOnChangeHandle={
                                            underGuideLineOnChangeHandle
                                          }
                                        />

                                        {productQuestionEntity.isCurrentYearField ===
                                          2 && (
                                          <DependentCheckboxComponent
                                            productQuestionEntity={
                                              productQuestionEntity
                                            }
                                            underguideLinedetails={
                                              underguideLinedetails
                                            }
                                            handleCheckboxOnChange={
                                              handleCheckboxOnChange
                                            }
                                          />
                                        )}
                                      </div>
                                    )
                                  : productQuestionEntity.isDelete === 0 && (
                                      <HandleOptionComponent
                                        underguideLinedetails={
                                          underguideLinedetails
                                        }
                                        setunderguideLinedetails={
                                          setunderguideLinedetails
                                        }
                                        productQuestionEntity={
                                          productQuestionEntity
                                        }
                                      />
                                    )}

                                <OpenDependentBoxComponent
                                  dependableDetails={dependableDetails}
                                  setDependableDetails={setDependableDetails}
                                  productQuestionEntity={productQuestionEntity}
                                />
                                {dependableDetails[
                                  `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                ].length > 0 ? (
                                  <DependentAllComponent
                                    dependableDetails={dependableDetails}
                                    setDependableDetails={setDependableDetails}
                                    productQuestionEntity={
                                      productQuestionEntity
                                    }
                                    underguideLinedetails={
                                      underguideLinedetails
                                    }
                                    handleDependableQuestionOnChange={
                                      handleDependableQuestionOnChange
                                    }
                                    dependentQuestionTextOptionOnChangeHandle={
                                      dependentQuestionTextOptionOnChangeHandle
                                    }
                                    handleAddMoreDependent={
                                      handleAddMoreDependent
                                    }
                                    handleCheckboxOnChange={
                                      handleCheckboxOnChange
                                    }
                                  />
                                ) : null}
                              </div>
                            )
                          )}
                      </div>

                      <PolicyDownloadComponent
                        underguideLinedetails={underguideLinedetails}
                        carrierPolicy={carrierPolicy}
                        location={location}
                        underGuideLineOnChangeHandleLogo={
                          underGuideLineOnChangeHandleLogo
                        }
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  // className={`login sign_in_button ${
                  //   underguideLinedetails.stateIds.length > 0 && carrierPolicy
                  //     ? ""
                  //     : "deactive"
                  // }`}
                  // disabled={
                  //   underguideLinedetails.stateIds.length === 0 ||
                  //   !carrierPolicy
                  // }

                  className={`login sign_in_button ${
                    underguideLinedetails.stateIds.length > 0 ? "" : "deactive"
                  }`}
                  disabled={underguideLinedetails.stateIds.length === 0}
                  onClick={() => underGuideLineButtonOnClickHandle()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnderGuide;
