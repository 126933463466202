import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../constants";

const TodaySearchBox = ({ Link, searchdetails }) => {
  const navigate = useNavigate();
  return (
    <li
      className="whiteBoxShadow"
      style={{ cursor: "pointer" }}
      onClick={() => {
        sessionStorage.setItem(
          "todaySearch",
          JSON.stringify({
            date: [
              moment().format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ],
          })
        );

        navigate(Link);
      }}
    >
      <div className="twoPartCommon">
        <div>
          <ul className="AdminDashboardFourUL">
            <li className="AdminDashboardFourBoxHead">Today's Search</li>
            <li className="AdminDashboardFourBoxNumber">{searchdetails}</li>
          </ul>
        </div>
        <div>
          <img
            src={IMAGE_PATHS.ts_icon_04}
            loading="lazy"
            alt="Logo"
            width={"60px"}
          />
        </div>
      </div>
    </li>
  );
};

export default TodaySearchBox;
