import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../constants";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import {
  calculateTotalWithPercentage,
  getAllChartUsers,
  getMonthAbbreviation,
} from "../../components/ChartsUtils/ChartsUtils";
import StateModalWIthRecent from "../../components/Modal/StateModalWIthRecent";
import MainSearchBar from "../../components/MainSearchBar";
import ProductWiseSearch from "../../components/SearchByAll/ProductWiseSearch";
import MonthWIseUserSearch from "../../components/SearchByAll/MonthWIseUserSearch";
import MonthWIseSearchChart from "../../components/SearchByAll/MonthWIseSearchChart";
import DashboardBox from "../../components/DashboardBox";
import TodaySearchBox from "../../components/TodaySearchBox";
import { PostCallsAdmin, PostCallsCommon } from "../../ApiClient/PostCall";
const baseUrl = process.env.REACT_APP_BASE_URL;

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { http } = ApiClient();
  const [isLoader, setIsLoader] = useState(false);
  const postCommon = PostCallsCommon(
    http,
    setIsLoader,
    Swal,
    navigate,
    "admin"
  );
  const postAdmin = PostCallsAdmin(http, setIsLoader, Swal, navigate);

  const [recentStates, setRecentStates] = useState([]);

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
    users: [],
    paymentsWise: [],
  });

  const [stateShow, setStateShow] = useState(false);
  const [searchdetails, setsearchdetails] = useState({
    customerName: "",
    allProducts: [],
    allCountData: {},
    state: {},
    product: {},
    stateId: "",
    stateName: "",
    allStates: [],
  });

  console.log(searchdetails, "S");

  const handleStateShow = () => {
    getAllState();
    setStateShow(true);
  };
  const handleStateClose = () => {
    setStateShow(false);
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setsearchdetails({ ...searchdetails, [name]: value });
    }
    if (name === "state") {
      setsearchdetails({
        ...searchdetails,
        stateId: +id,
        stateName: value,
      });
    }

    if (name === "product") {
      setsearchdetails({
        ...searchdetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  // const selectAllStateHandleOnClick = () => {
  //   const arrayOfIds = searchdetails.allStates.map((state) => state.stateId);

  //   const arrayOfState = searchdetails.allStates.map(
  //     (state) => state.stateCode
  //   );
  //   setsearchdetails({
  //     ...searchdetails,
  //     stateId: arrayOfIds,
  //     state: arrayOfState,
  //   });
  // };

  const handleSearchCarrierButtonOnClick = () => {
    if (
      !searchdetails.customerName ||
      !searchdetails.product.proName ||
      !searchdetails.state.stateName
    ) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let agentDashboardSearchDetails = {
      customerName: searchdetails.customerName,
      product: searchdetails.product,
      state: searchdetails.state,
    };
    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(agentDashboardSearchDetails)
    );

    navigate(`${baseUrl}/Admin/AdminDashboardSearch`);
  };

  const stateModalOnClickHandle = () => {
    setsearchdetails({
      ...searchdetails,
      state: {
        stateName: searchdetails.stateName,
        stateId: searchdetails.stateId,
      },
    });
    setStateShow(false);
  };

  const getAllState = async () => {
    setIsLoader(true);
    let data = await postCommon.getAllStateCommon();
    setsearchdetails({
      ...searchdetails,
      allStates: data,
      stateName: "",
      stateId: "",
    });
    getAllStatesWithRecent();
    setIsLoader(false);
  };

  const getAllStatesWithRecent = async () => {
    let res = await postCommon.getAllRecentStateCommon();
    if (res.status === 200) {
      setRecentStates(res.data);
    }
  };

  const getAllAllData = async () => {
    let data = await postCommon.getAllCarriersAndOthers();
    setsearchdetails((prevSearchDetails) => ({
      ...prevSearchDetails,
      allCountData: data,
    }));
  };

  // chart showing start

  const getMonthWiseSearch = async () => {
    let data = await postAdmin.getMonthWiseSearch("", "");

    setReportLists((prevLists) => ({
      ...prevLists,
      monthWise: data,
    }));
  };

  const getProductWiseSearch = async () => {
    let data = await postAdmin.getProductWiseSearch("", "");

    setReportLists((prevLists) => ({
      ...prevLists,
      productWise: data,
    }));
  };
  const getPaymetsBySearch = async () => {
    let data = await postAdmin.getPaymentsByMonth("", "");

    setReportLists((prevLists) => ({
      ...prevLists,
      paymentsWise: data,
    }));
  };
  const getMonthWiseUserSearch = async () => {
    let data = await postAdmin.getUsersByMonth("", "");

    setReportLists((prevLists) => ({
      ...prevLists,
      userWise: calculateTotalWithPercentage(data),
      users: getAllChartUsers(data),
    }));
  };

  // chart showing end

  const getAllProduct = async () => {
    setIsLoader(true);
    let data = await postCommon.getAllConnectedProducts();
    getLastSearch();
    getMonthWiseSearch();
    getProductWiseSearch();
    getPaymetsBySearch();
    getMonthWiseUserSearch();
    getAllAllData();
    setIsLoader(false);
    setsearchdetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      allProducts: data,
    }));
  };

  const getLastSearch = async () => {
    let data = await postCommon.getTodaysSearch();
    setsearchdetails((prevLists) => ({
      ...prevLists,
      // customerName: res.data[0]?.searchEntity?.customerName,
      state: {
        stateName: data[0]?.masterState?.stateCode,
        stateId: data[0]?.masterState?.stateId,
      },
      product: {
        proName: data[0]?.searchEntity?.productBuilder?.productName,
        proId: data[0]?.searchEntity?.productBuilder?.productId,
      },
    }));
  };

  useEffect(() => {
    getAllProduct();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={1}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <MainSearchBar
          searchdetails={searchdetails}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          handleStateShow={handleStateShow}
          handleSearchCarrierButtonOnClick={handleSearchCarrierButtonOnClick}
        />

        <div className="head_bread mt-4 mb-3">
          <div className="ReportDate">
            <h1>Dashboard</h1>
          </div>
        </div>
        <div className="AdminDashboardFourBox">
          <ul>
            <DashboardBox
              Link={`${baseUrl}/Admin/UserManagement`}
              boxValue={"Number of Agents"}
              searchdetails={
                Object.keys(searchdetails.allCountData).length > 0
                  ? searchdetails.allCountData.agents
                  : 0
              }
              IMAGE_PATHS={IMAGE_PATHS.na_icon_01}
            />

            <DashboardBox
              Link={`${baseUrl}/Admin/Payments`}
              boxValue={"Active Subscribers"}
              searchdetails={
                Object.keys(searchdetails.allCountData).length > 0
                  ? searchdetails.allCountData.activeSubscribers
                  : 0
              }
              IMAGE_PATHS={IMAGE_PATHS.as_icon_02}
            />
            <DashboardBox
              Link={`${baseUrl}/Admin/Carriers`}
              boxValue={"Added Carriers"}
              searchdetails={
                Object.keys(searchdetails.allCountData).length > 0
                  ? searchdetails.allCountData.addedCarriers
                  : 0
              }
              IMAGE_PATHS={IMAGE_PATHS.ac_icon_03}
            />
            {console.log(searchdetails.allCountData, "A")}
            <TodaySearchBox
              Link={`${baseUrl}/Admin/SearchHistory`}
              searchdetails={
                Object.keys(searchdetails.allCountData).length > 0
                  ? searchdetails.allCountData.todaySearch
                  : 0
              }
            />
          </ul>
        </div>
        <div className="AdminDashboardThreeBox">
          <div className="whiteBoxShadow">
            <h3>Month Wise Payments</h3>
            {reportLists.paymentsWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={reportLists.paymentsWise}
                  // barSize={20}
                >
                  <XAxis
                    dataKey="month"
                    interval={"preserveStartEnd"}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    tickFormatter={getMonthAbbreviation}
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    domain={[0, reportLists.paymentsWise.length + 8]}
                  />
                  {/* <Tooltip content={<CutomizedToolTip />} /> */}
                  {/* <Legend /> */}
                  <CartesianGrid vertical={false} />

                  <Bar
                    dataKey="count"
                    fill="#82ACD3"
                    // name="Month"
                    // legendType="rect"

                    maxBarSize={20}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <MonthWIseSearchChart reportLists={reportLists} />

          <ProductWiseSearch reportLists={reportLists} />
        </div>
        <MonthWIseUserSearch reportLists={reportLists} />

        <StateModalWIthRecent
          stateShow={stateShow}
          handleStateClose={handleStateClose}
          isLoader={isLoader}
          Loader={Loader}
          recentStates={recentStates}
          handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          searchdetails={searchdetails}
          stateModalOnClickHandle={stateModalOnClickHandle}
        />
      </div>
    </Layout>
  );
};

export default AdminDashboard;
