import React from "react";
import { Form } from "react-bootstrap";

const DependentTextOptionComponent = ({
  dependendAns,
  productQuestionEntity,
  dependentQuestionTextOptionOnChangeHandle,
  ind,
  handleCheckboxOnChange,
}) => {
  return (
    <>
      {Object.keys(dependendAns).length ? (
        dependendAns.optionType === "Text" ? (
          <div className="bottomPart">
            <Form.Group className="width125" controlId="formBasicEmail">
              <Form.Control
                type="text"
                className="form_control_2"
                name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                placeholder="min-value"
                value={dependendAns.minValue || ""}
                onChange={(event) =>
                  dependentQuestionTextOptionOnChangeHandle(
                    event,
                    dependendAns,
                    "min",
                    ind
                  )
                }
              />
            </Form.Group>
            <Form.Group
              className="width125"
              controlId="formBasicEmail"
              style={
                dependendAns.productQuestionEntity.isCurrentYearField === 2 &&
                dependendAns.maxValue === null
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <Form.Control
                type="text"
                className="form_control_2"
                name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                placeholder="max-value"
                value={dependendAns.maxValue || ""}
                onChange={(event) =>
                  dependentQuestionTextOptionOnChangeHandle(
                    event,
                    dependendAns,
                    "max",
                    ind
                  )
                }
              />
            </Form.Group>
            {dependendAns?.productQuestionEntity?.isCurrentYearField === 2 && (
              <>
                <input
                  style={{
                    width: "20px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  type="checkbox"
                  name={`${productQuestionEntity.questionLabelCode}_${dependendAns.productQuestionEntity.productQuestionId}`}
                  checked={dependendAns.maxValue === null}
                  onChange={(event) =>
                    handleCheckboxOnChange(event, dependendAns, "dependent")
                  }
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Current Year
                </span>
              </>
            )}
          </div>
        ) : (
          <div className="borderBoxCheckBox">
            <fieldset className="checkbox-group">
              {dependendAns.optionValue?.map((elem, indx) => (
                <div className="checkbox" key={indx}>
                  {Object.entries(elem).map(([key, value]) => (
                    <label className="checkbox-wrapper" key={key}>
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                        value={key} // Assuming 'key' is the label for checkbox
                        onChange={(event) =>
                          dependentQuestionTextOptionOnChangeHandle(
                            event,
                            dependendAns,
                            "",
                            ind
                          )
                        }
                        checked={value} // Assuming 'value' is the checked state
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">{key}</span>
                      </span>
                    </label>
                  ))}
                </div>
              ))}
            </fieldset>
          </div>
        )
      ) : null}
    </>
  );
};

export default DependentTextOptionComponent;
