const env = process.env.REACT_APP_ENV;

const baseName =
  env === "PROD"
    ? ""
    : env === "staging_local" || env === "local"
    ? "/risker_prod/react-local"
    : "/risker_prod";

const IMAGE_PATHS = {
  risker_logo_admin: `${baseName}/images/risker_logo_admin.svg`,
  risker_logo_admin_small: `${baseName}/images/risker_logo_admin_small.svg`,
  risker_logo: `${baseName}/images/risker_logo.svg`,
  risker_logo_white: `${baseName}/images/risker_logo_white.svg`,
  eye_close: `${baseName}/images/eye_close.svg`,
  eye_open: `${baseName}/images/eye_open.svg`,
  fb_icon: `${baseName}/images/fb_icon.svg`,
  google_icon: `${baseName}/images/google_icon.svg`,
  login_bg_img: `${baseName}/images/login_background_img.png`,
  login_img: `${baseName}/images/login_inage.svg`,
  userBlankIcon: `${baseName}/images/user_blank_icon.svg`,
  userIonEdit: `${baseName}/images/user_icon_edit.svg`,
  hambargarMenu: `${baseName}/images/hambargar_menu.png`,

  SelectPlanActiveIcon: `${baseName}/images/SelectPlan_active_icon.svg`,
  FinishDeactiveIcon: `${baseName}/images/Finish_deactive_icon.svg`,
  FinishActiveIcon: `${baseName}/images/Finish_active_icon.svg`,
  PaymentOptionDeactiveIcon: `${baseName}/images/PaymentOption_deactive_icon.svg`,
  PaymentOptionActiveIcon: `${baseName}/images/PaymentOption_active_icon.svg`,
  AccountDeactiveIcon: `${baseName}/images/Account_deactive_icon.svg`,
  AccountActiveIcon: `${baseName}/images/Account_active_icon.svg`,
  PayByBankAccountIcon: `${baseName}/images/Pay_by_Bank_Account_icon.svg`,
  PayByCreditCardIcon: `${baseName}/images/Pay_by_Credit_card_icon.svg`,
  SuccessBlueTicIcon: `${baseName}/images/success_blue_tic.svg`,
  SuccessBlueTicIconBig: `${baseName}/images/success_blue_tic_big.svg`,
  NotificationIcon: `${baseName}/images/notification.svg`,
  SearcIconHeaderIcon: `${baseName}/images/search_icon_header.svg`,
  UserIcon: `${baseName}/images/user_icon.png`,
  finishIconActive: `${baseName}/images/finish_icon_active.svg`,
  finishIconDeactive: `${baseName}/images/finish_icon_deactive.svg`,
  underGuidActive: `${baseName}/images/under_guid_active.svg`,
  underGuidDective: `${baseName}/images/under_guid_deactive.svg`,

  HomeProductIcon: `${baseName}/images/HomeProduct_icon.png`,
  AutoProducttIcon: `${baseName}/images/AutoProduct_icon.png`,
  DwellingFireIcon: `${baseName}/images/DwellingFire_icon.png`,
  ManufacturedHomeIcon: `${baseName}/images/ManufacturedHome_icon.png`,
  phPlusBoldIcon: `${baseName}/images/ph_plus-bold.svg`,
  modalCloseIcon: `${baseName}/images/modal_close.png`,

  modalIcon_01: `${baseName}/images/icon_01.svg`,
  modalIcon_02: `${baseName}/images/icon_02.svg`,
  modalIcon_03: `${baseName}/images/icon_03.svg`,
  modalIcon_04: `${baseName}/images/icon_04.svg`,
  modalIcon_05: `${baseName}/images/icon_05.svg`,
  modalIcon_06: `${baseName}/images/icon_06.svg`,
  modalIcon_07: `${baseName}/images/icon_07.svg`,
  // modalIcon_08: `${baseName}/images/icon_08.svg`,

  ShortIconGray: `${baseName}/images/short_icon_gray.svg`,
  DeleteIconGray: `${baseName}/images/delete_icon_gray.svg`,
  EditIconGray: `${baseName}/images/edit_icon_gray.svg`,

  blueCirleTick: `${baseName}/images/blue_cirle_tick.svg`,
  plusIcon2: `${baseName}/images/plus_icon_2.svg`,
  crossIcon2: `${baseName}/images/cross_icon_2.svg`,

  carrier_01: `${baseName}/images/carrier_01.png`,
  carrier_02: `${baseName}/images/carrier_02.png`,
  carrier_03: `${baseName}/images/carrier_03.png`,
  carrier_04: `${baseName}/images/carrier_04.png`,
  ep_back: `${baseName}/images/ep_back.png`,
  edit_icon: `${baseName}/images/edit_icon.png`,
  Close_modal: `${baseName}/images/Close-modal.png`,
  Close_modal_normal: `${baseName}/images/Close_modal_normal.png`,

  search_icon: `${baseName}/images/search_icon.png`,
  view_icon: `${baseName}/images/view_icon.svg`,
  edit_icon_table: `${baseName}/images/edit_icon_table.svg`,
  down_arrow: `${baseName}/images/down_arrow.png`,
  up_arrow: `${baseName}/images/up_arrow.png`,
  date_icon: `${baseName}/images/date_icon.svg`,
  search_icon_white: `${baseName}/images/search_icon_white.svg`,
  maxlife_logo: `${baseName}/images/maxlife.png`,
  progresive_logo: `${baseName}/images/progresive.png`,
  maxLife_Big_logo: `${baseName}/images/maxLife-Big.png`,

  report_bar_image_01: `${baseName}/images/report_bar_image_01.png`,
  report_bar_image_02: `${baseName}/images/report_bar_image_02.png`,
  report_bar_image_03: `${baseName}/images/report_bar_image_03.png`,
  report_bar_image_04: `${baseName}/images/report_bar_image_04.png`,

  na_icon_01: `${baseName}/images/na_icon_01.svg`,
  as_icon_02: `${baseName}/images/as_icon_02.svg`,
  ac_icon_03: `${baseName}/images/ac_icon_03.svg`,
  ts_icon_04: `${baseName}/images/ts_icon_04.svg`,
  ip_icon_05: `${baseName}/images/ip_icon_05.svg`,

  cc_01: `${baseName}/images/cc_01.png`,
  cc_02: `${baseName}/images/cc_02.png`,
  cc_03: `${baseName}/images/cc_03.png`,
  cc_04: `${baseName}/images/cc_04.png`,
  cc_05: `${baseName}/images/cc_05.png`,
  cc_06: `${baseName}/images/cc_06.png`,

  logo_01: `${baseName}/images/logo_01.png`,
  logo_02: `${baseName}/images/logo_02.png`,
  logo_03: `${baseName}/images/logo_03.png`,
  logo_04: `${baseName}/images/logo_04.png`,
  logo_05: `${baseName}/images/logo_05.png`,
  switch_active: `${baseName}/images/switch_active.svg`,
  switch_deactive: `${baseName}/images/switch_deactive.svg`,

  add_user_agent_active: `${baseName}/images/add_user_agent_active.svg`,
  subscription_agent_active: `${baseName}/images/subscription_agent_active.svg`,
  subscription_agent_deactive: `${baseName}/images/subscription_agent_deactive.svg`,
  finish_agent_active: `${baseName}/images/finish_agent_active.svg`,
  finish_agent_deactive: `${baseName}/images/finish_agent_deactive.svg`,
  priority_icon: `${baseName}/images/priority_icon.svg`,
  modal_normal_close: `${baseName}/images/modal_normal_close.svg`,
  up_down_icon: `${baseName}/images/up_down_icon.svg`,
  month_wise_search_agent: `${baseName}/images/month_wise_search_agent.svg`,
  user_wise_search_sgent: `${baseName}/images/user_wise_search_sgent.svg`,
  download_icon: `${baseName}/images/download_icon.svg`,
  card_icon: `${baseName}/images/card_icon.svg`,
  cardPay: `${baseName}/images/cc_pay.svg`,

  help_icon: `${baseName}/images/help_icon.svg`,
  profile_edit_button: `${baseName}/images/profile_edit_button.svg`,
  profile_image_01: `${baseName}/images/profile_image_01.png`,

  tooltip_icon: `${baseName}/images/tooltip_icon.svg`,

  // main images
  main_risker_logo: `${baseName}/images/main_risker_logo.svg`,
  main_contact_icon: `${baseName}/images/main_contact_icon.svg`,
  Risker_final_logo: `${baseName}/images/main/Risker_final_logo.svg`,
  phone_icon: `${baseName}/images/main/phone_icon.svg`,
  main_banner_home: `${baseName}/images/main/main_banner_home.svg`,
  hamburger_menu: `${baseName}/images/main/hamburger-menu.svg`,
  Home_our_Coverages: `${baseName}/images/main/Home_our_Coverages.svg`,
  Auto_our_Coverages: `${baseName}/images/main/Auto_our_Coverages.svg`,
  DwellingFire_our_Coverages: `${baseName}/images/main/DwellingFire_our_Coverages.svg`,
  ManufacturedHome_our_Coverages: `${baseName}/images/main/ManufacturedHome_our_Coverages.svg`,
  sponsore_logo_01: `${baseName}/images/main/sponsore_logo_01.svg`,
  sponsore_logo_02: `${baseName}/images/main/sponsore_logo_02.svg`,
  sponsore_logo_03: `${baseName}/images/main/sponsore_logo_03.svg`,
  sponsore_logo_04: `${baseName}/images/main/sponsore_logo_04.svg`,
  sponsore_logo_05: `${baseName}/images/main/sponsore_logo_05.svg`,
  sponsore_logo_06: `${baseName}/images/main/sponsore_logo_06.svg`,
  star_fill: `${baseName}/images/main/star_fill.png`,
  map: `${baseName}/images/main/map.svg`,
  line_arrow: `${baseName}/images/main/line_arrow.svg`,
  how_it_work_img: `${baseName}/images/main/how_it_work_img.svg`,
  Risker_final_logo_footer: `${baseName}/images/main/Risker_final_logo_footer.svg`,
  footer_phone_icon: `${baseName}/images/main/footer_phone_icon.svg`,
  fb_footer: `${baseName}/images/main/fb_footer.svg`,
  tw_footer: `${baseName}/images/main/tw_footer.svg`,
  in_footer: `${baseName}/images/main/in_footer.svg`,
  yo_footer: `${baseName}/images/main/yo_footer.svg`,
  Help_you_focus_img: `${baseName}/images/main/Help_you_focus_img.svg`,
  orange_tic: `${baseName}/images/main/orange_tic.svg`,
  main_login_image: `${baseName}/images/main/main_login_image.svg`,
  book_a_demo_banner_img: `${baseName}/images/main/book_a_demo_banner_img.svg`,
  uiw_date: `${baseName}/images/main/uiw_date.svg`,
  ph_clock: `${baseName}/images/main/ph_clock.svg`,
  carriersMainBg: `${baseName}/images/main/carriersMainBg.svg`,
  Become_an_Agent_Bg: `${baseName}/images/main/Become_an_Agent_Bg.svg`,
  Grow_with_us_box_img_01: `${baseName}/images/main/Grow_with_us_box_img_01.svg`,
  Grow_with_us_box_img_02: `${baseName}/images/main/Grow_with_us_box_img_02.svg`,
  WhoWeAre_img: `${baseName}/images/main/WhoWeAre_img.svg`,
  simply_transparent_img: `${baseName}/images/main/simply_transparent_img.svg`,
  acting_as_one_team_img: `${baseName}/images/main/acting_as_one_team_img.svg`,
  make_bold_decisions_img: `${baseName}/images/main/make_bold_decisions_img.svg`,
  how_can_we_help_img: `${baseName}/images/main/how_can_we_help_img.png`,
  image_04: `${baseName}/images/main/image_04.png`,
  image_03: `${baseName}/images/main/image_03.png`,
  image_02: `${baseName}/images/main/image_02.png`,
  image_01: `${baseName}/images/main/image_01.png`,
  productMain_Home: `${baseName}/images/main/productMain_Home.svg`,
  productMain_Auto: `${baseName}/images/main/productMain_Auto.svg`,
  productMain_Dwelling: `${baseName}/images/main/productMain_Dwelling.svg`,
  productMain_Mnufactured: `${baseName}/images/main/productMain_Mnufactured.svg`,
  StatesWeServe_mainProduct_map: `${baseName}/images/main/StatesWeServe_mainProduct_map.png`,
  callUs_icon: `${baseName}/images/main/callUs_icon.svg`,
  office_icon: `${baseName}/images/main/office_icon.svg`,
  text_us_icon: `${baseName}/images/main/text_us_icon.png`,
  popUp_close: `${baseName}/images/main/popUp_close.svg`,
};

export default IMAGE_PATHS;
