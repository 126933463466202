import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import IMAGE_PATHS from "../../../constants";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import MyLoader from "../../../components/Loader/myLoader";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import { sortArray } from "../../../Utils/helper";
import Paginations from "../../../components/Pagination";

const BillingManagement = () => {
  const [sortData, setSortData] = useState({ name: "", order: "" });
  const [page, setPage] = useState(1);

  const [historyDetails, setHistoryDetails] = useState({
    history: [],
    count: 1,
  });
  const [loading, setLoading] = useState(false);

  const { http } = ApiClient();

  const handleDownloadClick = (invoice) => {
    if (invoice) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      // window.location.href = invoice;
      window.open(invoice, "_blank");
    } else {
      Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "Invoice not available!",
        confirmButtonText: "OK",
      });
    }
  };

  const getPaymentsData = async () => {
    try {
      setLoading(true);
      const res = await http.get(`/getAllPayment/${page}`);

      setHistoryDetails({
        ...historyDetails,
        history: res.data.data,
        count: res.data.count,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message || "Sorry, Something went wrong",
        confirmButtonText: "CANCEL",
      });
    }
  };
  const sortHistoryData = (name, order) => {
    setHistoryDetails({
      ...historyDetails,
      history: sortArray(
        historyDetails.history,
        // name === "userName" ? "userNameSpecial" : name,
        name,
        order
      ),
    });
  };

  useEffect(() => {
    if (sortData.name && sortData.order) {
      sortHistoryData(sortData.name, sortData.order);
    }
    return () => {};
    // eslint-disable-next-line
  }, [sortData]);

  useEffect(() => {
    getPaymentsData();
    return () => {};
    // eslint-disable-next-line
  }, [page]);
  return (
    <LayoutAgent sideBarActive={26}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer AvencyPage walletBlance">
        {/* <div className="head_bread">
          <div className="searchHistoryFormBar">
            <h1>Billing Management</h1>
          </div>
          <div>
            <Link to={`${baseUrl}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Billing Management</span>
          </div>
        </div>
        <div className="walletBlanceTwoBox">
          <div className="whiteBoxShadow">
            <h2>Modify Saved Card</h2>
            <div className="twoPartCommon mt-4">
              <div className="walletBlanceCardDetails left">
                <img
                  src={IMAGE_PATHS.card_icon}
                  loading="lazy"
                  alt="Logo"
                  width={"48px"}
                />
                <div>
                  <ul>
                    <li>
                      Card: <span>Mastercard</span>
                    </li>
                    <li className="cardNumber">
                      Card Number : <span>X X X X</span>
                      <span>X X X X</span>
                      <span>X X X X</span>
                      <span>2458</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right">
                <Link
                  to={`${baseUrl}/BillingManagementEdit`}
                >
                  <Button className="button_blueBorder">Edit</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="walletBlanceBox">
            <h2>Wallet Balance</h2>
            <p>$100</p>
          </div>
        </div> */}
        <div className="head_bread mt-5">
          <h1>Transactions History</h1>
        </div>
        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>
                  <span className="shorting_container">
                    ID
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({ name: "paymentId", order: "asc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({ name: "paymentId", order: "desc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Date/Time
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({ name: "date", order: "asc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({ name: "date", order: "desc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Payment For
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({
                            name: "subscriptionType",
                            order: "asc",
                          });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({
                            name: "subscriptionType",
                            order: "desc",
                          });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    User Name
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({ name: "userName", order: "asc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({ name: "userName", order: "desc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Amount
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({ name: "amount", order: "asc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({ name: "amount", order: "desc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Pay by
                    <span className="shorting">
                      <img
                        src={IMAGE_PATHS.up_arrow}
                        onClick={() => {
                          setSortData({ name: "payBy", order: "asc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        src={IMAGE_PATHS.down_arrow}
                        onClick={() => {
                          setSortData({ name: "payBy", order: "desc" });
                        }}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {historyDetails.history.length ? (
                historyDetails?.history?.map((pay) => (
                  <tr key={pay?.invoiceId}>
                    <td>#{pay?.paymentId}</td>
                    <td>{DateFormat(pay?.date)}</td>
                    <td>{pay?.subscriptionType}</td>
                    <td>{pay?.userName}</td>
                    <td>${pay?.amount}</td>
                    <td>{pay.payBy}</td>
                    <td>
                      <div
                        to="#"
                        className="downloadLink"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadClick(pay?.invoiceDownload)
                        }
                      >
                        <img
                          src={IMAGE_PATHS.download_icon}
                          loading="lazy"
                          alt="Logo"
                          width={"16px"}
                        />
                        Download Invoice
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>No data Found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {historyDetails.history.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={historyDetails.count}
          />
        ) : null}
      </div>
    </LayoutAgent>
  );
};

export default BillingManagement;
