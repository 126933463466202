import React from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { useLocation } from "react-router-dom";
import ProfileSettingsCommon from "../../../components/ProfileSettingsCommon";

const ProfileSettingsAgent = () => {
  const location = useLocation();

  const profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));

  //   reset password handle

  return (
    <LayoutAgent>
      <ProfileSettingsCommon
        profileDetails={profileDetails}
        location={location}
      />
    </LayoutAgent>
  );
};

export default ProfileSettingsAgent;
