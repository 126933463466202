import moment from "moment";
import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const SearchHistoryDetailsCalendar = ({ searchdata, setSearchdata }) => {
  return (
    <div className="dateButtonContainer" style={{ width: "650px" }}>
      <RangePicker
        onChange={(event) => {
          setSearchdata({
            ...searchdata,
            startDate: event ? moment(event[0]["$d"]).format("YYYY-MM-DD") : "",
            endDate: event ? moment(event[1]["$d"]).format("YYYY-MM-DD") : "",
          });
        }}
        className="AgentRangePicker"
      />
    </div>
  );
};

export default SearchHistoryDetailsCalendar;
