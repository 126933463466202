import React from "react";
import { Button, Modal } from "react-bootstrap";
import IMAGE_PATHS from "../../constants";

const CommonLikeModal = ({
  show,
  handleClose,
  searchDetails,
  carrierLikeDislikeOnChangeHandle,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="LikeModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="orangeRadio">
        <div className="twoPartCommon align-items-center">
          <img
            src={searchDetails.likedQuestion.carrierLogo}
            loading="lazy"
            alt="Logo"
            width={"148px"}
            height={"43px"}
          />
          {searchDetails.likedQuestion.isConnect ? (
            <img
              src={IMAGE_PATHS.switch_active}
              loading="lazy"
              alt="Logo"
              width={"30px"}
              height={"15px"}
            />
          ) : (
            <img
              src={IMAGE_PATHS.switch_deactive}
              loading="lazy"
              alt="Logo"
              width={"30px"}
              height={"15px"}
            />
          )}
        </div>
        <span>Great..!!!! Share Your Thoughts</span>

        <form>
          {searchDetails.carrierLikeQuestionsDetails.length &&
            searchDetails.carrierLikeQuestionsDetails.map(
              ({ carrierLikeQuestionsId, carrierLikeQuestion }) => (
                <div
                  className="formBox01"
                  key={carrierLikeQuestionsId}
                  style={{ width: "100%" }}
                >
                  <input
                    type="radio"
                    id={carrierLikeQuestion}
                    name="likeQuestion"
                    value={carrierLikeQuestionsId}
                    onChange={(event) =>
                      carrierLikeDislikeOnChangeHandle(event)
                    }
                    checked={
                      carrierLikeQuestionsId ===
                      searchDetails.likedQuestion.like[0].likeQuestionsId
                    }
                  />
                  <label htmlFor={carrierLikeQuestion}>
                    {carrierLikeQuestion}
                  </label>
                </div>
              )
            )}
        </form>
        <div className="buttn_row mt_30">
          <Button
            className="button_blue_100"
            onClick={() => handleClose("confirm")}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommonLikeModal;
