import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import LogoutModal from "../../../components/Modal/LogoutModal";
const baseUrl = process.env.REACT_APP_BASE_URL;

const LeftMenuAgent = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };

  const userRole = parseInt(sessionStorage.getItem("userRole"));

  const { logout } = ApiClient();

  const signout = () => {
    logout("agent");
  };
  return (
    <div>
      <div className="full_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin}
          loading="lazy"
          alt="Logo"
          width={"120px"}
          onClick={() => navigate(`${baseUrl}/AgentDashboard`)}
        />
      </div>
      <div className="small_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin_small}
          loading="lazy"
          alt="Logo"
          width={"120px"}
        />
      </div>
      <div>
        <ul className="mainMenu">
          <li className="AgentDashboard">
            <Link
              className={props.sideBarActive === 21 ? "active" : ""}
              to={`${baseUrl}/AgentDashboard`}
            >
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="AgentSearchHistory">
            <Link
              className={props.sideBarActive === 22 ? "active" : ""}
              onClick={() => sessionStorage.removeItem("todaySearch")}
              to={`${baseUrl}/SearchHistoryAgent`}
            >
              <span>Search History</span>
            </Link>
          </li>
          <li className="AgentCarriers">
            <Link
              className={props.sideBarActive === 23 ? "active" : ""}
              onClick={() => sessionStorage.removeItem("tab")}
              to={`${baseUrl}/CarriersAgent`}
            >
              <span>Carriers</span>
            </Link>
          </li>
          {userRole === 1 ? (
            <li className="AgentUserManagement">
              <Link
                className={props.sideBarActive === 24 ? "active" : ""}
                to={`${baseUrl}/UserManagementAgent`}
              >
                <span>User Management</span>
              </Link>
            </li>
          ) : null}
          <li className="AgentReport">
            <Link
              className={props.sideBarActive === 25 ? "active" : ""}
              to={`${baseUrl}/ReportAgent`}
            >
              <span>Report</span>
            </Link>
          </li>
          <li className="AgentBillingManagement">
            <Link
              className={props.sideBarActive === 26 ? "active" : ""}
              to={`${baseUrl}/BillingManagement`}
            >
              <span>Billing Management</span>
            </Link>
          </li>
          <li className="Reviews">
            <Link
              className={props.sideBarActive === 27 ? "active" : ""}
              to={`${baseUrl}/ReviewsAgent`}
            >
              <span>Reviews</span>
            </Link>
          </li>
          <li
            onClick={() => {
              setShow(true);
            }}
            className="Logout"
          >
            <Link>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
      <LogoutModal show={show} handleClose={handleClose} signout={signout} />
    </div>
  );
};

export default LeftMenuAgent;
