import React from "react";
import IMAGE_PATHS from "../constants";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const CommonHeader = ({
  profileDetails,
  profileImage,
  show,
  handleShow,
  handleClose,
  location,
  portalTypeLocation,
}) => {
  const navigate = useNavigate();

  const sidebarToggle = () => {
    document.body.classList.toggle("sidebar-toggle");
  };

  return (
    <div className="header">
      <div className="left">
        <img
          src={IMAGE_PATHS.hambargarMenu}
          loading="lazy"
          alt="Logo"
          width={"35px"}
          onClick={sidebarToggle}
        />
      </div>
      <div className="right">
        <ul className="HeaderRight">
          {/* <li className="notification">
            <img
              src={IMAGE_PATHS.NotificationIcon}
              loading="lazy"
              alt="Logo"
              width={"20px"}
              // onClick={sidebarToggle}
            />
            <div className="notificationCirle">2</div>
          </li> */}

          {location}

          <span>Hi,{profileDetails?.firstName}</span>
          <li>
            <div className="dropdown">
              <img
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                src={profileImage}
                loading="lazy"
                alt="Logo"
                width={"40px"}
                // onClick={sidebarToggle}
              />
              <ul
                className="dropdown-menu profile_area"
                aria-labelledby="dropdownMenuButton1"
              >
                <li
                  className="dropdown-item"
                  onClick={() =>
                    portalTypeLocation === "agent"
                      ? navigate(`${baseUrl}/ProfileSettingsAgent`, {
                          state: { tab: "Edit Profile" },
                        })
                      : navigate(`${baseUrl}/ProfileSettingsAdmin`, {
                          state: { tab: "Edit Profile" },
                        })
                  }
                  style={{ cursor: "pointer" }}
                >
                  Edit Profile
                </li>
                <li
                  className="dropdown-item"
                  onClick={() =>
                    portalTypeLocation === "agent"
                      ? navigate(`${baseUrl}/ProfileSettingsAgent`, {
                          state: { tab: "Reset Password" },
                        })
                      : navigate(`${baseUrl}/ProfileSettingsAdmin`, {
                          state: { tab: "Reset Password" },
                        })
                  }
                  style={{ cursor: "pointer" }}
                >
                  Reset Password
                </li>
                <li>
                  <Link className="dropdown-item" to="#" onClick={handleShow}>
                    Sign Out
                  </Link>
                </li>
              </ul>
              <Modal show={show} onHide={handleClose} size="sm" centered>
                <Modal.Body>
                  <div className="modal_body pad20 logout_modal">
                    <img
                      src={IMAGE_PATHS.Close_modal_normal}
                      loading="lazy"
                      alt="Logo"
                      width={"15px"}
                      onClick={handleClose}
                      className="modalCrossNormal hand"
                    />

                    <div className="row ">
                      <p>
                        Are you sure,
                        <br />
                        you want to logout?
                      </p>
                    </div>

                    <div className="buttn_row mt_20">
                      <div className="cal-12 twoButtonSideBySide2 mt-0">
                        <button
                          className="button_grayBorder w-100 justify-content-center"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button className="button_lightBlueFill w-100 justify-content-center">
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CommonHeader;
