import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
// import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
// import Paginations from "../../../components/Pagination";

const FAQDetails = () => {
  const [editFAQDetails, setEditFAQDetails] = useState({
    allFAQDetails: [],
    allTopics: [],
    faqId: 0,
    topicId: 0,
    topicIds: 0,
    topic: "",
    topicName: "",
    question: "",
    answer: "",
    initialCheckboxState: 1,
    popupType: "Add",
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [editShow, setEditShow] = useState(false);
  const handleShow = (faqId, status) => {
    setEditFAQDetails({
      ...editFAQDetails,
      faqId,
      initialCheckboxState: status,
    });
    setShow(true);
  };
  const handleEditShowClose = () => setEditShow(false);

  const { http } = ApiClient();

  const selectTopicOnChangeHandle = (event) => {
    let { value } = event.target;
    const [faqTopicId, topic] = value.split(",");

    setEditFAQDetails({
      ...editFAQDetails,
      topicId: +faqTopicId,
      topic,
    });
  };

  const handleEditShow = async (type, topicId, faqId, question, answer) => {
    if (type === "Add") {
      setEditFAQDetails({
        ...editFAQDetails,
        topicName: "",
        question: "",
        answer: "",
        popupType: type,
      });
    } else {
      setEditFAQDetails({
        ...editFAQDetails,
        topicName: editFAQDetails.topic,
        topicId,
        topicIds: topicId,
        question,
        answer,
        faqId,
        popupType: type,
      });
    }
    setEditShow(true);
  };

  const deleteFAQDetailsOnClick = (faqId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteFAQ/${faqId}`)
          .then(() => {
            setIsLoader(false);
            getAllFAQDetails(editFAQDetails.topicId);
          })
          .catch((error) => {
            setIsLoader(false);
            isLogin(error, navigate, Swal);
          });
      }
    });
  };

  const editFAQOnChangeHandle = (event) => {
    let { name, value } = event.target;
    if (name === "topicName") {
      const [faqTopicId, topic] = value.split(",");
      setEditFAQDetails({
        ...editFAQDetails,
        [name]: topic,
        topicIds: +faqTopicId,
      });
    } else {
      setEditFAQDetails({ ...editFAQDetails, [name]: value });
    }
  };

  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(`/inactiveFAQ/${editFAQDetails.faqId}`)
      .then(() => {
        getAllFAQDetails(editFAQDetails.topicId);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };

  const handleSubmitEditButtonOnClick = () => {
    const { topicName, question, answer, popupType, faqId, topicIds, topicId } =
      editFAQDetails;
    if (!topicName || !question || !answer) {
      return Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let faqPayload = [
      {
        faqTopic: {
          faqTopicId: topicIds,
        },
        question,
        answer,
      },
    ];

    if (popupType === "Add") {
      setIsLoader(true);

      http
        .post("/saveFAQ", faqPayload)
        .then(() => {
          getAllFAQDetails(topicId);
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    } else {
      setIsLoader(true);

      http
        .put("/updateFAQ", { ...faqPayload, faqEntityId: faqId })
        .then(() => {
          getAllFAQDetails(topicId);
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    }

    handleEditShowClose();
  };

  const getAllTopics = () => {
    setIsLoader(true);
    http
      .get(`/getFAQTopics`)
      .then((res) => {
        setEditFAQDetails({
          ...editFAQDetails,
          allTopics: res.data,
          topic: res.data[0].topic,
          topicId: res.data[0].faqTopicId,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const getAllFAQDetails = async (id) => {
    try {
      setIsLoader(true);
      let res = await http.get(`/getFAQByTopicId/${id}`);

      setEditFAQDetails({
        ...editFAQDetails,
        allFAQDetails: res.data,
        topicId: id,
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getAllTopics();
    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editFAQDetails.allTopics.length) {
      getAllFAQDetails(editFAQDetails.topicId);
    }
    return () => {};
    // eslint-disable-next-line
  }, [editFAQDetails.topicId]);
  console.log(editFAQDetails, "E");

  return (
    <Layout sideBarActive={11}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>FAQ Details</h1>
          <div className="rightWithButton">
            <div>
              <select
                style={{
                  padding: "5px",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                aria-label="Default select example"
                name="topic"
                onChange={(event) => selectTopicOnChangeHandle(event)}
              >
                {editFAQDetails?.allTopics?.length ? (
                  editFAQDetails.allTopics.map(({ topic, faqTopicId }) => (
                    <option key={faqTopicId} value={`${faqTopicId},${topic}`}>
                      {topic}
                    </option>
                  ))
                ) : (
                  <option>Topic Not Found</option>
                )}
              </select>
            </div>
            <div>
              <Button
                className="button_blueBorder"
                onClick={() => handleEditShow("Add", "", "", "", "")}
              >
                <img
                  src={IMAGE_PATHS.phPlusBoldIcon}
                  loading="lazy"
                  alt="Logo"
                  width={"16px"}
                />
                Add FAQ Details
              </Button>
            </div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ FAQDetails</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>FAQ Questions</th>
                <th>FAQ Answers</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editFAQDetails?.allFAQDetails?.length ? (
                editFAQDetails?.allFAQDetails[0].questionAnswers?.map(
                  ({ faqEntityId, question, answer, status }) => (
                    <tr key={faqEntityId}>
                      <td>{question}</td>
                      <td>{answer}</td>
                      <td>
                        <span
                          className={
                            status === 1
                              ? "active_green cursorPointer"
                              : "pending_red cursorPointer"
                          }
                          onClick={() => handleShow(faqEntityId, status)}
                        >
                          {status === 1 ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <span className="action_icon_container">
                          <span
                            onClick={() =>
                              handleEditShow(
                                "Edit",
                                editFAQDetails.allFAQDetails[0].faqTopicId,
                                faqEntityId,
                                question,
                                answer
                              )
                            }
                          >
                            <img
                              src={IMAGE_PATHS.edit_icon_table}
                              loading="lazy"
                              alt="Logo"
                              width={"20px"}
                            />
                          </span>

                          <span
                            onClick={() => deleteFAQDetailsOnClick(faqEntityId)}
                          >
                            <img
                              src={IMAGE_PATHS.DeleteIconGray}
                              loading="lazy"
                              alt="Logo"
                              height={"18px"}
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to{" "}
                {editFAQDetails.initialCheckboxState === 0
                  ? "active"
                  : "inActive"}{" "}
                FAQ Details?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={editFAQDetails.initialCheckboxState}
                    onChange={() => editActiveInActiveOnChangeHandle()}
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>{editFAQDetails.popupType} FAQ Details</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>

              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Topic</Form.Label>
                  <Form.Control
                    as="select"
                    name="topicName"
                    onChange={(event) => editFAQOnChangeHandle(event)}
                  >
                    <option hidden>{editFAQDetails.topic}</option>

                    {editFAQDetails?.allTopics?.map(({ topic, faqTopicId }) => (
                      <option key={faqTopicId} value={`${faqTopicId},${topic}`}>
                        {topic}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">FAQ Question</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter FAQ Question"
                    name="question"
                    value={editFAQDetails.question}
                    onChange={(event) => editFAQOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">FAQ Answer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter FAQ Answer"
                    name="answer"
                    value={editFAQDetails.answer}
                    onChange={(event) => editFAQOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* {editFAQDetails.allFAQDetails.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={editFAQDetails.count}
          />
        ) : null} */}
      </div>
    </Layout>
  );
};

export default FAQDetails;
