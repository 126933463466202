import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import RiskerLogo from "../../../components/RiskerLogo/RiskerLogo";
import LargeButton from "../../../components/Buttons/LargeButton";
import IMAGE_PATHS from "../../../constants";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";

const AdminLogin = () => {
  const [logindetails, setlogindetails] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const { getToken } = ApiClient();
  const handleLoginDetails = (event) => {
    setlogindetails({
      ...logindetails,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (getToken()) {
      navigate(`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="loginBoxOuter">
        <div className="container-fluid container_outer_01">
          <div className="container">
            <div className="loginBox">
              <div className="left">
                <div className="logo_login">
                  <div className="login_top">
                    <RiskerLogo />

                    <h3>Welcome back!</h3>
                    <p>Sign in with your username and password</p>
                  </div>
                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Email ID"
                          value={logindetails.username}
                          name="username"
                          onChange={(event) => handleLoginDetails(event)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row password_div">
                      <Form.Group
                        className="mb_12"
                        controlId="formBasicPassword"
                      >
                        <div className="password_box">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={logindetails.password}
                            name="password"
                            onChange={(event) => handleLoginDetails(event)}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="buttn_row">
                        <LargeButton
                          buttonLabel={"Admin Sign In"}
                          authPayload={logindetails}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="right">
                <img
                  src={IMAGE_PATHS.login_img}
                  loading="lazy"
                  alt="Logo"
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
