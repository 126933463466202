import React from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";

const LoginMain = () => {
  return (
    <div className="mainLogin_outer main_login_bg">
      <div className="container">
        <div className="whiteBox_outer">
          <div className="mainLogin_whiteBox">
            <div className="mainLogin_whiteBox_Inner">
              <div className="left">
                <h3>Register now start your free 10-day trial </h3>
                <img
                  src={IMAGE_PATHS.main_login_image}
                  loading="lazy"
                  alt="LoginImage"
                  width={""}
                />
              </div>
              <div className="right">
                <div className="logo_login">
                  <div className="login_top">
                    <img
                      src={IMAGE_PATHS.Risker_final_logo}
                      loading="lazy"
                      alt="Logo"
                      width={"151px"}
                    />
                    <h3>Login</h3>
                    <p>Sign in with your username and password</p>
                  </div>
                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12" controlId="formBasicEmail">
                        {/* <Form.Label className='label_text'>User Name</Form.Label> */}
                        <Form.Control
                          type="text"
                          id="first"
                          placeholder="User ID"
                        />
                      </Form.Group>
                    </div>
                    <div className="row password_div">
                      <Form.Group
                        className="mb_12"
                        controlId="formBasicPassword"
                      >
                        {/* <Form.Label className='label_text'>Password</Form.Label> */}
                        <div className="password_box">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="mb_12 light_gray_text sideBySide">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          class="form-check-label forgotPassword"
                          for="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                      <div>
                        <Link className="light_gray_text forgotPassword" to="">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="buttn_row">
                        <Link to="/AgentUpdateProfile">
                          <Button
                            variant="primary"
                            type="submit"
                            className="login sign_in_button"
                          >
                            Sign in
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cal-12">
                        <div className="or ">
                          <span className="light_gray_text">
                            OR Sign in with
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cal-12 fb_google">
                        <Button
                          variant="primary"
                          type="submit"
                          className="login"
                        >
                          <img
                            src={IMAGE_PATHS.fb_icon}
                            loading="lazy"
                            alt="Logo"
                            width={"24px"}
                          />
                          Facebook
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="login"
                        >
                          <img
                            src={IMAGE_PATHS.google_icon}
                            loading="lazy"
                            alt="Logo"
                            width={"24px"}
                          />
                          Google
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      <p className="register">
                        Don't have an account?{" "}
                        <Link to="SignUp" className="blueLink">
                          Register
                        </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginMain;
