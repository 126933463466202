import React from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { lastLocation } from "../Utils/Locations";
import ConnectedProducts from "./Products/ConnectedProducts";

const MainSearchBar = ({
  searchdetails,
  handleCarrierSearchOnChange,
  handleStateShow,
  handleSearchCarrierButtonOnClick,
}) => {
  const location = useLocation();
  return (
    <div className="searchBar">
      <Form>
        <ul>
          <li>Searching for Carriers</li>
          <li>
            <Form.Group className="" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Customer Name"
                value={searchdetails.customerName || ""}
                name="customerName"
                onChange={(event) => handleCarrierSearchOnChange(event)}
              />
            </Form.Group>
          </li>
          {/* <li>
            <Form.Select
              aria-label="Default select example"
              name="product"
              onChange={(event) => handleCarrierSearchOnChange(event)}
            >
              <option hidden>
                {searchdetails?.product?.proName || "Select Product"}
              </option>
              {searchdetails.allProducts?.length > 0 ? (
                searchdetails.allProducts.map(({ productId, productName }) => (
                  <option key={productId} value={productName} id={productId}>
                    {productName}
                  </option>
                ))
              ) : (
                <option>No Data Found</option>
              )}
            </Form.Select>
          </li> */}
          <ConnectedProducts
            allProducts={searchdetails.allProducts}
            searchdetails={searchdetails}
            handleCarrierSearchOnChange={handleCarrierSearchOnChange}
          />
          <li>
            <Form.Select
              aria-label="Default select example"
              name="state"
              onClick={handleStateShow}
            >
              <option hidden>
                {searchdetails?.state?.stateName
                  ? searchdetails?.state?.stateName
                  : "Select State"}
              </option>
            </Form.Select>
          </li>
          <li>
            <Button
              className="searchButton"
              onClick={() => handleSearchCarrierButtonOnClick()}
            >
              {lastLocation(location) === "AdminDashboard" ||
              lastLocation(location) === "AgentDashboard"
                ? "Next"
                : "Start New Search"}
            </Button>
          </li>
        </ul>
      </Form>
    </div>
  );
};

export default MainSearchBar;
