import React, { useEffect, useState } from "react";

import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
// import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import ReviewsTable from "../../../components/ReviewsTable";

const ReviewsAgent = () => {
  const navigate = useNavigate();
  const [editReviewsDetails, seteditReviewsDetails] = useState({
    reviewId: 0,
    allReviews: [],
    name: "",
    date: "",
    message: "",
    rating: "",
    popupType: "Add",
  });
  const [isLoader, setIsLoader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (reviewId, status) => {
    seteditReviewsDetails({
      ...editReviewsDetails,
      reviewId,
      initialCheckboxState: status, // Set initial state of the checkbox
    });
    setShow(true);
  };

  const [editShow, setEditShow] = useState(false);
  const handleEditShowClose = () => setEditShow(false);
  const userId = JSON.parse(sessionStorage.getItem("userId"));

  const handleEditShow = (reviewsDetail, type) => {
    if (type === "Add") {
      seteditReviewsDetails({
        ...editReviewsDetails,
        name: "",
        date: "",
        message: "",
        rating: "",
        popupType: type,
      });
    } else {
      seteditReviewsDetails({
        ...editReviewsDetails,
        name: reviewsDetail.reviewerName,
        date: reviewsDetail.reviewDate,
        message: reviewsDetail.reviewMessage,
        rating: reviewsDetail.reviewNumber,
        popupType: type,
        reviewId: reviewsDetail.reviewEntityId,
      });
    }

    setEditShow(true);
  };

  const { http } = ApiClient();

  const handleSubmitEditButtonOnClick = () => {
    const { name, date, message, rating, popupType, reviewId } =
      editReviewsDetails;

    if (!name || !date || !message || !rating) {
      return Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let reviewPayload = {
      userEntity: {
        userId,
      },
      reviewerName: name,
      reviewMessage: message,
      reviewNumber: rating,
      reviewDate: date,
    };
    if (popupType === "Add") {
      setIsLoader(true);
      http
        .post("/saveReview", reviewPayload)
        .then(() => {
          getAllReviews();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLoginAgent(error, navigate, Swal);
        });
    } else {
      setIsLoader(true);
      http
        .put("/updateReview", {
          ...reviewPayload,
          reviewEntityId: reviewId,
        })
        .then(() => {
          getAllReviews();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLoginAgent(error, navigate, Swal);
        });
    }

    handleEditShowClose();
  };

  const deleteReviewOnClickHandle = (reviewId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteReview/${reviewId}`)
          .then(() => {
            setIsLoader(false);
            getAllReviews();
          })
          .catch((error) => {
            setIsLoader(false);
            isLoginAgent(error, navigate, Swal);
          });
      }
    });
  };

  const editReviewOnChangeHandle = (event) => {
    let { name, value } = event.target;
    seteditReviewsDetails({ ...editReviewsDetails, [name]: value });
  };
  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(`/inactiveReview/${editReviewsDetails.reviewId}`, {})
      .then(() => {
        getAllReviews();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
    handleClose();
  };

  const getAllReviews = () => {
    setIsLoader(true);
    http
      .get("/getReviewDetailsAgent")
      .then((res) => {
        seteditReviewsDetails({ ...editReviewsDetails, allReviews: res.data });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllReviews();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <LayoutAgent sideBarActive={27}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Reviews</h1>
          <div className="rightWithButton">
            <div>
              <Button
                className="button_blueBorder bgTransparent//"
                onClick={() => handleEditShow("", "Add")}
              >
                <img
                  src={IMAGE_PATHS.phPlusBoldIcon}
                  loading="lazy"
                  alt="Logo"
                  width={"16px"}
                />
                Add Reviews
              </Button>
              {/* </Link> */}
            </div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Reviews</span>
          </div>
        </div>

        <ReviewsTable
          editReviewsDetails={editReviewsDetails}
          handleShow={handleShow}
          show={show}
          handleClose={handleClose}
          editActiveInActiveOnChangeHandle={editActiveInActiveOnChangeHandle}
          handleEditShow={handleEditShow}
          deleteReviewOnClickHandle={deleteReviewOnClickHandle}
        />

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>{editReviewsDetails.popupType} Review</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Reviewer Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Reviewer Name"
                    name="name"
                    value={editReviewsDetails.name}
                    onChange={(event) => editReviewOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Review Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Review Date"
                    name="date"
                    value={editReviewsDetails.date}
                    onChange={(event) => editReviewOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Review Message</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email Message"
                    name="message"
                    value={editReviewsDetails.message}
                    onChange={(event) => editReviewOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Rating</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Rating"
                    name="rating"
                    value={editReviewsDetails.rating}
                    onChange={(event) => editReviewOnChangeHandle(event)}
                    min={1}
                    max={5}
                  />
                </Form.Group>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </LayoutAgent>
  );
};

export default ReviewsAgent;
