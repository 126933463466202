import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import DeleteDependentBox from "./DeleteDependentBox";
import DependentTextOptionComponent from "./DependentTextOptionComponent";
import AddMoreDependentButton from "./AddMoreDependentButton";

const DependentAllComponent = ({
  dependableDetails,
  setDependableDetails,
  productQuestionEntity,
  underguideLinedetails,
  handleDependableQuestionOnChange,
  dependentQuestionTextOptionOnChangeHandle,
  handleAddMoreDependent,
  handleCheckboxOnChange,
}) => {
  return (
    <div>
      {dependableDetails[
        `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
      ]?.map((dependendAns, ind) => (
        <Fragment key={ind}>
          <div
            style={{
              marginBottom: "10px",
              marginTop: "5px",
            }}
            key={ind}
            className="d-flex align-items-center"
          >
            <Form.Select
              aria-label="Default select example"
              onChange={(event) =>
                handleDependableQuestionOnChange(
                  event,
                  `${productQuestionEntity?.productQuestionId}`,
                  ind
                )
              }
            >
              <option hidden>
                {dependendAns?.productQuestionEntity?.questionLabel
                  ? dependendAns?.productQuestionEntity?.questionLabel
                  : "Select Questions"}
              </option>

              {underguideLinedetails.questionAnswer.length &&
                underguideLinedetails.questionAnswer[0].answers.map(
                  (answer) =>
                    productQuestionEntity.questionLabel !==
                      answer.productQuestionEntity.questionLabel && (
                      <option
                        key={answer.productQuestionEntity.productQuestionId}
                        // value={`${answer.productQuestionEntity.questionLabelCode}`}
                        value={`${productQuestionEntity.questionLabel}_${answer.productQuestionEntity.productQuestionId}`}
                      >
                        {answer.productQuestionEntity.questionLabel}
                      </option>
                    )
                )}
            </Form.Select>

            <DeleteDependentBox
              dependableDetails={dependableDetails}
              setDependableDetails={setDependableDetails}
              productQuestionEntity={productQuestionEntity}
              ind={ind}
            />
          </div>

          <DependentTextOptionComponent
            dependendAns={dependendAns}
            productQuestionEntity={productQuestionEntity}
            dependentQuestionTextOptionOnChangeHandle={
              dependentQuestionTextOptionOnChangeHandle
            }
            ind={ind}
            handleCheckboxOnChange={handleCheckboxOnChange}
          />
        </Fragment>
      ))}

      <AddMoreDependentButton
        handleAddMoreDependent={handleAddMoreDependent}
        productQuestionEntity={productQuestionEntity}
      />
    </div>
  );
};

export default DependentAllComponent;
