import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CarriersLeftMenu from "../../../components/CarriersLeftMenu/CarriersLeftMenu";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import {
  ConvertedDependents,
  getData,
  setDependentCurrentYear,
} from "../../../components/ConvertedDependents/ConvertedDependents";
import {
  answerLogicForProductQuestion,
  answerLogicForText,
  dependentsQuestionTextOptionOnChangeHandle,
  handleCheckboxesOnChange,
  onChangeHandleLogo,
  selectedTextBoxLogic,
  underGuideLineOnChangeHandles,
} from "../../../Utils/CommonApi";
import { PostCallsAdmin } from "../../../ApiClient/PostCall";
import SelectedStateComponent from "./SelectedStateComponent";
import HandleOptionComponent from "./HandleOptionComponent";
import PolicyDownloadComponent from "./PolicyDownloadComponent";
import MinMaxCommonComponent from "./MinMaxCommonComponent";
import DependentCheckboxComponent from "./DependentCheckboxComponent";
import OpenDependentBoxComponent from "./OpenDependentBoxComponent";
import DependentAllComponent from "./DependentAllComponent";
import DeleteDependentBox from "./DeleteDependentBox";
import DependentTextOptionComponent from "./DependentTextOptionComponent";
import AddMoreDependentButton from "./AddMoreDependentButton";
const baseUrl = process.env.REACT_APP_BASE_URL;

const UnderGuide = () => {
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const postAdmin = PostCallsAdmin(http, setisLoader, Swal, navigate);

  const location = useLocation();
  const [lastSelectedTab, setLastSelectedTab] = useState(false);
  const [carrierPolicy, setcarrierPolicy] = useState("");
  console.log(location, "LL");
  const [underguideLinedetails, setunderguideLinedetails] = useState({
    allStates: location?.state?.allCarrier?.state,
    productBuilder: location?.state?.allCarrier?.productAndQuestion,
    allSelectedOptions: [],
    logoFile: "",
    logoFileTitle: "",
    stateIds: [],
    questionAnswer: [],
    carrierQuestionAnswer: { productBuilder: null, questinAnswerSatate: [] },
    allCarrier: location?.state?.allCarrier,
  });

  console.log(underguideLinedetails, "D");

  const [dependableDetails, setDependableDetails] = useState({
    isDependable: 1,
  });

  const [selectedTab, setSelectedTab] = useState(
    underguideLinedetails.allCarrier?.productAndQuestion[0].productBuilder
      .productId
  );

  // dependable code

  //copy
  const handleAddMoreDependent = (productQuestionEntity) => {
    dependableDetails[
      `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
    ].push({});

    setDependableDetails({
      ...dependableDetails,
    });
  };

  //copy
  const dependentQuestionTextOptionOnChangeHandle = (
    event,
    details,
    type,
    ind
  ) => {
    dependentsQuestionTextOptionOnChangeHandle(
      event,
      details,
      type,
      ind,
      dependableDetails,
      setDependableDetails
    );
  };

  const handleDependableQuestionOnChange = async (event, questionId, ind) => {
    let { value } = event.target;
    let value_id = value.split("_");
    let questionValue = `${value_id[0]}_${questionId}`;

    let payload = {
      carrierId: selectedTab,
      productId: underguideLinedetails.allCarrier.carrier.carrierId,
      questionId: +questionId,
      dependentQuestionId: +value_id[1],
      stateIds: underguideLinedetails.stateIds,
    };

    //copyApi
    setisLoader(true);
    let data = await postAdmin.getDependentQuestionAnswerAdmin(payload);
    setisLoader(false);
    let updatedDependableDetails = [...dependableDetails[`${questionValue}1`]];
    updatedDependableDetails[ind] = data;

    setDependableDetails({
      ...dependableDetails,
      [questionValue]: data,
      [`${questionValue}1`]: updatedDependableDetails,
    });
  };
  //dependable code

  //copyButLittleChange
  const underGuideLineOnChangeHandleLogo = (event) => {
    onChangeHandleLogo(
      event,
      underguideLinedetails,
      setunderguideLinedetails,
      "underGuide"
    );
  };

  //copy
  const underGuideLineOnChangeHandle = (event, type) => {
    underGuideLineOnChangeHandles(
      event,
      type,
      underguideLinedetails,
      setunderguideLinedetails
    );
  };

  //copy
  const handleCheckboxOnChange = (event, productQuestionEntity, type) => {
    handleCheckboxesOnChange(
      event,
      type,
      productQuestionEntity,
      dependableDetails,
      setDependableDetails,
      underguideLinedetails,
      setunderguideLinedetails
    );
  };

  //copy

  const underGuideLineButtonOnClickHandle = async () => {
    // / Find the selected product in productAndQuestion array
    const selectedProduct =
      underguideLinedetails.allCarrier.productAndQuestion.find(
        (product) => product.productBuilder.productId === +selectedTab
      );

    const selectedProductQuestions = selectedProduct.questionEntities;

    // Map the selected states to the desired format

    const selectedStates = underguideLinedetails.stateIds.map(
      (selectedStateId) => ({
        stateId: selectedStateId,
        questionAnswer: selectedProductQuestions.map((question) => {
          const convertedAnswers = ConvertedDependents(
            dependableDetails[
              `${question.questionLabel}_${question.productQuestionId}1`
            ]
          );

          let answer = null;
          //copyIf not else
          if (question.masterFieldType.fieldType === "Text") {
            let getAnswer = answerLogicForText(
              answer,
              question,
              underguideLinedetails,
              convertedAnswers
            );
            answer = getAnswer;
          } else if (question.masterFieldType.fieldType === "Option") {
            if (
              !underguideLinedetails?.carrierQuestionAnswer?.questinAnswerSatate
                ?.length
            ) {
              answer = {
                questionId: question.productQuestionId,
                optionType: "Option",
                optionValue:
                  underguideLinedetails[question.questionLabelCode] || [],
                depenedentQuestionRequests: convertedAnswers,
              };
            } else {
              //copyStart
              let getAnswerCheck = selectedTextBoxLogic(
                question,
                answer,
                underguideLinedetails,
                convertedAnswers
              );
              answer = getAnswerCheck;

              //copyEnd
            }
          }

          return answer;
        }),
      })
    );

    var payloadReq = {
      productId: selectedTab,
      carrierPolicy: carrierPolicy,
      carrierId: underguideLinedetails.allCarrier.carrier.carrierId,
      productQuestionState: selectedStates,
    };

    //copyApiOnly
    setisLoader(true);

    await postAdmin.saveQuestionAnswerAdmin(payloadReq);
    setisLoader(false);
    if (lastSelectedTab) {
      navigate(`${baseUrl}/Admin/AddNewCarrierFinish`);
    } else {
      const currentTabIndex =
        underguideLinedetails.allCarrier.productAndQuestion.findIndex(
          (product) => product.productBuilder.productId === selectedTab
        );

      // Move to the next tab
      const nextTabIndex = currentTabIndex + 1;
      if (
        nextTabIndex <
        underguideLinedetails.allCarrier.productAndQuestion.length
      ) {
        const nextTabId =
          underguideLinedetails.allCarrier.productAndQuestion[nextTabIndex]
            .productBuilder.productId;

        setLastSelectedTab(false);
        setSelectedTab(nextTabId);
      }

      setunderguideLinedetails({
        stateIds: [],
        allStates: location?.state?.allCarrier.state,
        productBuilder: location?.state?.allCarrier.productAndQuestion,
        allSelectedOptions: [],
        logoFile: "",
        logoFileTitle: "",
        questionAnswer: [],
        carrierQuestionAnswer: {
          productBuilder: null,
          questinAnswerSatate: [],
        },
        allCarrier: location?.state?.allCarrier,
      });
    }
  };

  const handleTabSelect = (id) => {
    id = +id;
    if (
      underguideLinedetails.allCarrier?.productAndQuestion[
        underguideLinedetails.allCarrier?.productAndQuestion.length - 1
      ].productBuilder.productId === id
    ) {
      setLastSelectedTab(true);
    } else {
      setLastSelectedTab(false);
    }
    setSelectedTab(id);
  };

  useEffect(() => {
    // check for last product
    if (
      underguideLinedetails.allCarrier?.productAndQuestion[
        underguideLinedetails.allCarrier?.productAndQuestion.length - 1
      ].productBuilder.productId === selectedTab
    ) {
      setLastSelectedTab(true);
    } else {
      setLastSelectedTab(false);
    }
    setSelectedTab(selectedTab);
    // check for last product

    const getAllProductQuestion = async () => {
      let payload = {
        carrierId:
          underguideLinedetails?.allCarrier?.carrier?.carrierId.toString(),
        productId: selectedTab?.toString(),
      };

      //copyBothApiOnly
      setisLoader(true);
      console.log(payload, "P");
      let data = await postAdmin.getAllCarrierQuestionAnswerAdmin({
        requestData: aesUtil.encrypt(JSON.stringify(payload)),
      });

      let response = await postAdmin.getPolicyDownloadAdmin(
        aesUtil.encrypt(
          JSON.stringify(underguideLinedetails?.allCarrier?.carrier?.carrierId)
        )
      );

      setcarrierPolicy(response?.data?.carrierPolicy || "");

      if (!data?.questinAnswerSatate.length) {
        const selectedProduct =
          underguideLinedetails?.allCarrier?.productAndQuestion?.find(
            (product) => product.productBuilder.productId === +selectedTab
          );

        const selectedProductQuestions = selectedProduct?.questionEntities;

        selectedProductQuestions?.forEach(
          ({ productQuestionId, questionLabel }) => {
            dependableDetails[`${questionLabel}_${productQuestionId}1`] = [];
          }
        );

        setunderguideLinedetails({
          stateIds: [],
          allStates: location?.state?.allCarrier?.state,
          productBuilder: location?.state?.allCarrier?.productAndQuestion,
          allSelectedOptions: [],
          logoFile: "",
          logoFileTitle: "",
          carrierQuestionAnswer: {
            productBuilder: null,
            questinAnswerSatate: [],
          },
          allCarrier: location?.state?.allCarrier,
        });
      } else {
        const allStateIds = data?.questinAnswerSatate.flatMap(
          (item) => item.states.stateId
        );

        const newUnderGuideLineDetails = {
          ...underguideLinedetails,
          carrierQuestionAnswer: data,
          questionAnswer: data.questinAnswerSatate,
          stateIds: allStateIds,
        };

        //copyStart
        answerLogicForProductQuestion(
          data,
          getData,
          dependableDetails,
          setDependentCurrentYear,
          newUnderGuideLineDetails
        );

        //copyEnd

        setunderguideLinedetails(newUnderGuideLineDetails);
      }

      setisLoader(false);
    };
    getAllProductQuestion();
    return () => {};
    // eslint-disable-next-line
  }, [selectedTab]);

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer unerGuide">
        <div className="head_bread">
          <h1>Add New Carrier</h1>
          <div>
            <Link to={`${baseUrl}/Admin/Carriers`}>Carriers</Link>{" "}
            <span>/ Add New Carrier</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <CarriersLeftMenu pageValue={"UnderGuide"} />
          <div className="left carriersRight">
            <div className="modal_body">
              <div style={{ textAlign: "center" }}>
                <img
                  width={100}
                  src={underguideLinedetails.allCarrier?.carrier.carrierLogo}
                  alt="carrier_logo"
                />
              </div>
              <h2 className="headText">Underwriting Guidelines</h2>
              <p className="headingBottomText">Selected Products</p>

              <div className="tab_area">
                <div className="Container357" style={{ width: "100%" }}>
                  {underguideLinedetails.carrierQuestionAnswer
                    .questinAnswerSatate.length ? (
                    <Tabs
                      activeKey={selectedTab}
                      id="uncontrolled-tab-example"
                      className="mb-3 underGuideTab"
                      onSelect={handleTabSelect}
                    >
                      {underguideLinedetails.productBuilder.map(
                        ({ productBuilder }) => (
                          <Tab
                            key={productBuilder.productId}
                            eventKey={productBuilder.productId}
                            title={productBuilder.productName}
                          >
                            <div className="SelectedState">
                              <label className="SelectedStateLabel">
                                Selected State
                              </label>
                              <div className="SelectedStateRadio">
                                <fieldset className="checkbox-group">
                                  {underguideLinedetails.allStates &&
                                    underguideLinedetails.allStates.map(
                                      (states) => (
                                        <SelectedStateComponent
                                          state={states}
                                          underguideLinedetails={
                                            underguideLinedetails
                                          }
                                          setunderguideLinedetails={
                                            setunderguideLinedetails
                                          }
                                        />
                                      )
                                    )}
                                </fieldset>
                              </div>
                            </div>

                            <div>
                              {underguideLinedetails.carrierQuestionAnswer?.questinAnswerSatate?.[0]?.answers?.map(
                                ({ optionType, productQuestionEntity }) => (
                                  <div
                                    key={
                                      productQuestionEntity.productQuestionId
                                    }
                                    className="formBox01"
                                  >
                                    <div className="topPart">
                                      <h2>
                                        {productQuestionEntity.questionLabel}
                                        {productQuestionEntity.isCurrentYearField ===
                                        2
                                          ? "(Year)"
                                          : null}
                                      </h2>
                                    </div>
                                    {optionType === "Text"
                                      ? productQuestionEntity.isDelete ===
                                          0 && (
                                          <div className="bottomPart">
                                            <MinMaxCommonComponent
                                              productQuestionEntity={
                                                productQuestionEntity
                                              }
                                              underguideLinedetails={
                                                underguideLinedetails
                                              }
                                              underGuideLineOnChangeHandle={
                                                underGuideLineOnChangeHandle
                                              }
                                            />
                                            {productQuestionEntity.isCurrentYearField ===
                                              2 && (
                                              <DependentCheckboxComponent
                                                productQuestionEntity={
                                                  productQuestionEntity
                                                }
                                                underguideLinedetails={
                                                  underguideLinedetails
                                                }
                                                handleCheckboxOnChange={
                                                  handleCheckboxOnChange
                                                }
                                              />
                                            )}
                                          </div>
                                        )
                                      : productQuestionEntity.isDelete ===
                                          0 && (
                                          <HandleOptionComponent
                                            underguideLinedetails={
                                              underguideLinedetails
                                            }
                                            setunderguideLinedetails={
                                              setunderguideLinedetails
                                            }
                                            productQuestionEntity={
                                              productQuestionEntity
                                            }
                                          />
                                        )}

                                    <OpenDependentBoxComponent
                                      dependableDetails={dependableDetails}
                                      setDependableDetails={
                                        setDependableDetails
                                      }
                                      productQuestionEntity={
                                        productQuestionEntity
                                      }
                                    />

                                    {dependableDetails[
                                      `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                    ].length > 0 ? (
                                      <DependentAllComponent
                                        dependableDetails={dependableDetails}
                                        setDependableDetails={
                                          setDependableDetails
                                        }
                                        productQuestionEntity={
                                          productQuestionEntity
                                        }
                                        underguideLinedetails={
                                          underguideLinedetails
                                        }
                                        handleDependableQuestionOnChange={
                                          handleDependableQuestionOnChange
                                        }
                                        dependentQuestionTextOptionOnChangeHandle={
                                          dependentQuestionTextOptionOnChangeHandle
                                        }
                                        handleAddMoreDependent={
                                          handleAddMoreDependent
                                        }
                                        handleCheckboxOnChange={
                                          handleCheckboxOnChange
                                        }
                                      />
                                    ) : null}
                                  </div>
                                )
                              )}
                            </div>

                            <PolicyDownloadComponent
                              underguideLinedetails={underguideLinedetails}
                              carrierPolicy={carrierPolicy}
                              location={location}
                              underGuideLineOnChangeHandleLogo={
                                underGuideLineOnChangeHandleLogo
                              }
                            />
                          </Tab>
                        )
                      )}
                    </Tabs>
                  ) : (
                    <Tabs
                      activeKey={selectedTab}
                      id="uncontrolled-tab-example"
                      className="mb-3 underGuideTab width-auto-button"
                      onSelect={handleTabSelect}
                    >
                      {underguideLinedetails.allCarrier?.productAndQuestion &&
                        underguideLinedetails.carrierQuestionAnswer
                          ?.questinAnswerSatate?.length === 0 &&
                        underguideLinedetails?.allCarrier?.productAndQuestion?.map(
                          ({ productBuilder, questionEntities }) => (
                            <Tab
                              key={productBuilder?.productId}
                              eventKey={productBuilder?.productId}
                              title={productBuilder?.productName}
                            >
                              <div className="SelectedState">
                                <label className="SelectedStateLabel">
                                  Selected State
                                </label>
                                <div className="SelectedStateRadio">
                                  <fieldset className="checkbox-group">
                                    {underguideLinedetails?.allStates?.length >
                                      0 &&
                                      underguideLinedetails.allStates.map(
                                        (state) => (
                                          <SelectedStateComponent
                                            state={state}
                                            underguideLinedetails={
                                              underguideLinedetails
                                            }
                                            setunderguideLinedetails={
                                              setunderguideLinedetails
                                            }
                                          />
                                        )
                                      )}
                                  </fieldset>
                                </div>
                              </div>

                              {questionEntities.map((questionEntity) =>
                                questionEntity.isDelete === 0 ? (
                                  <div
                                    className="formBox01"
                                    key={questionEntity.productQuestionId}
                                  >
                                    <div className="topPart">
                                      <h2>
                                        {questionEntity.questionLabel}
                                        {questionEntity.isCurrentYearField === 2
                                          ? "(Year)"
                                          : null}
                                      </h2>
                                    </div>

                                    {questionEntity.masterFieldType
                                      .fieldType === "Text" ? (
                                      <div className="bottomPart">
                                        <Form.Group
                                          className="width125"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Control
                                            type="text"
                                            className="form_control_2"
                                            name={`${questionEntity.questionLabelCode}1`}
                                            placeholder="min-value"
                                            value={
                                              underguideLinedetails.questionLabelCode
                                            }
                                            onChange={(event) =>
                                              underGuideLineOnChangeHandle(
                                                event,
                                                questionEntity
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          className="width125"
                                          controlId="formBasicEmail"
                                          style={
                                            underguideLinedetails[
                                              `${questionEntity.questionLabelCode}3`
                                            ] === 2
                                              ? // &&
                                                // underguideLinedetails[
                                                //   `${productQuestionEntity.questionLabelCode}2`
                                                // ] === null
                                                { display: "none" }
                                              : { display: "block" }
                                          }
                                        >
                                          <Form.Control
                                            type="text"
                                            className="form_control_2"
                                            name={`${questionEntity.questionLabelCode}2`}
                                            placeholder="max-value"
                                            value={
                                              underguideLinedetails[
                                                `${questionEntity.questionLabelCode}2`
                                              ] || ""
                                            }
                                            onChange={(event) =>
                                              underGuideLineOnChangeHandle(
                                                event,
                                                questionEntity
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        {questionEntity.isCurrentYearField ===
                                          2 && (
                                          <DependentCheckboxComponent
                                            productQuestionEntity={
                                              questionEntity
                                            }
                                            underguideLinedetails={
                                              underguideLinedetails
                                            }
                                            handleCheckboxOnChange={
                                              handleCheckboxOnChange
                                            }
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div className="borderBoxCheckBox">
                                        <fieldset className="checkbox-group">
                                          {questionEntity.optionType.map(
                                            (prod, ind) => (
                                              <div
                                                className="checkbox"
                                                key={ind}
                                              >
                                                <label className="checkbox-wrapper">
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name={`${questionEntity.questionLabelCode}`}
                                                    placeholder="max-value"
                                                    value={prod}
                                                    onChange={(event) =>
                                                      underGuideLineOnChangeHandle(
                                                        event,
                                                        questionEntity
                                                      )
                                                    }
                                                  />
                                                  <span className="checkbox-tile">
                                                    <span className="checkbox-label">
                                                      {prod}
                                                    </span>
                                                  </span>
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </fieldset>
                                      </div>
                                    )}

                                    <OpenDependentBoxComponent
                                      dependableDetails={dependableDetails}
                                      setDependableDetails={
                                        setDependableDetails
                                      }
                                      productQuestionEntity={questionEntity}
                                    />

                                    {dependableDetails[
                                      `${questionEntity?.questionLabel}_${questionEntity?.productQuestionId}1`
                                    ]?.length > 0 ? (
                                      <div>
                                        {dependableDetails[
                                          `${questionEntity.questionLabel}_${questionEntity.productQuestionId}1`
                                        ]?.map((dependendAns, ind) => (
                                          <Fragment key={ind}>
                                            <div
                                              style={{
                                                marginBottom: "10px",
                                                marginTop: "5px",
                                              }}
                                              key={ind}
                                              className="d-flex align-items-center"
                                            >
                                              <Form.Select
                                                aria-label="Default select example"
                                                onChange={(event) =>
                                                  handleDependableQuestionOnChange(
                                                    event,
                                                    `${questionEntity?.productQuestionId}`,
                                                    ind
                                                  )
                                                }
                                              >
                                                <option hidden>
                                                  Select Questions
                                                </option>

                                                {productBuilder.productId ===
                                                  selectedTab &&
                                                  questionEntities.map(
                                                    (answer) =>
                                                      questionEntity.questionLabel !==
                                                        answer.questionLabel && (
                                                        <option
                                                          key={
                                                            answer.productQuestionId
                                                          }
                                                          value={`${questionEntity.questionLabel}_${answer.productQuestionId}`}
                                                        >
                                                          {
                                                            answer?.questionLabel
                                                          }
                                                        </option>
                                                      )
                                                  )}
                                              </Form.Select>

                                              <DeleteDependentBox
                                                dependableDetails={
                                                  dependableDetails
                                                }
                                                setDependableDetails={
                                                  setDependableDetails
                                                }
                                                productQuestionEntity={
                                                  questionEntity
                                                }
                                                ind={ind}
                                              />
                                            </div>

                                            <DependentTextOptionComponent
                                              dependendAns={dependendAns}
                                              productQuestionEntity={
                                                questionEntity
                                              }
                                              dependentQuestionTextOptionOnChangeHandle={
                                                dependentQuestionTextOptionOnChangeHandle
                                              }
                                              ind={ind}
                                              handleCheckboxOnChange={
                                                handleCheckboxOnChange
                                              }
                                            />
                                          </Fragment>
                                        ))}

                                        <AddMoreDependentButton
                                          handleAddMoreDependent={
                                            handleAddMoreDependent
                                          }
                                          productQuestionEntity={questionEntity}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div>No Questions Found</div>
                                )
                              )}

                              <PolicyDownloadComponent
                                underguideLinedetails={underguideLinedetails}
                                carrierPolicy={carrierPolicy}
                                location={location}
                                underGuideLineOnChangeHandleLogo={
                                  underGuideLineOnChangeHandleLogo
                                }
                              />
                            </Tab>
                          )
                        )}
                    </Tabs>
                  )}
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  // className={`login sign_in_button ${
                  //   underguideLinedetails.stateIds.length > 0 && carrierPolicy
                  //     ? ""
                  //     : "deactive"
                  // }`}
                  // disabled={
                  //   underguideLinedetails.stateIds.length === 0 ||
                  //   !carrierPolicy
                  // }
                  className={`login sign_in_button ${
                    underguideLinedetails.stateIds.length > 0 ? "" : "deactive"
                  }`}
                  disabled={underguideLinedetails.stateIds.length === 0}
                  onClick={() => underGuideLineButtonOnClickHandle()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnderGuide;
