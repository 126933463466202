import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import RiskerLogo from "../../../components/RiskerLogo/RiskerLogo";
import LargeButton from "../../../components/Buttons/LargeButton";
// import FbButton from "../../../components/Buttons/FbButton";
// import GoogleButton from "../../../components/Buttons/GoogleButton";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const [logindetails, setlogindetails] = useState({
    userId: "",
    password: "",
    forgotUserId: "",
  });

  const [show, setShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const handleClose = () => setShow(false);
  const { http } = ApiClient();

  const navigate = useNavigate();
  const { getToken } = ApiClient();
  const handleShow = () => {
    setlogindetails({
      ...logindetails,
      forgotUserId: "",
      forgotPassword: "",
      confPassword: "",
    });
    setShow(true);
  };

  const handleLoginDetails = (event) => {
    setlogindetails({
      ...logindetails,
      [event.target.name]: event.target.value,
    });
  };
  const forgotPasswordHandleOnChange = (event) => {
    let { name, value } = event.target;
    setlogindetails({ ...logindetails, [name]: value });
  };

  const forgotPasswordOnClickHandle = async () => {
    const { forgotUserId } = logindetails;

    if (!forgotUserId) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let sendMailPayload = {
      userEmail: forgotUserId,
      url: `${process.env.REACT_APP_MAIN_PATH}${baseUrl}/setPassword/forgot/`,
      isForgot: 1,
    };

    try {
      setIsLoader(true);
      await http.post("/sendForgotEmail", sendMailPayload);
      Swal.fire({
        icon: "success",
        // title: "Oops...",
        text: "Mail sent to registered Email to set password!",
        confirmButtonText: "OK",
      }).then(() => {
        handleClose();
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    const profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));
    const userRole = sessionStorage.getItem("userRole");

    if (
      !profileDetails?.customerId &&
      profileDetails?.trial === 0 &&
      userRole === "1"
    ) {
      navigate(`${baseUrl}/SelectYourPlan`);
      return;
    }

    if (getToken()) {
      navigate(`${baseUrl}/AgentDashboard`);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="loginBoxOuter">
      {isLoader && <Loader />}
      <div className="container-fluid container_outer_01">
        <div className="container">
          <div className="loginBox">
            <div className="left">
              <div className="logo_login">
                <div className="login_top">
                  <RiskerLogo />

                  <h3>Welcome back!</h3>
                  <p>Sign in with your username and password</p>
                </div>
                <Form>
                  <div className="row">
                    <Form.Group className="mb_12" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="Email ID"
                        value={logindetails.userId}
                        name="userId"
                        onChange={(event) => handleLoginDetails(event)}
                      />
                    </Form.Group>
                  </div>

                  <div className="row password_div">
                    <Form.Group className="mb_12" controlId="formBasicPassword">
                      <div className="password_box">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={logindetails.password}
                          name="password"
                          onChange={(event) => handleLoginDetails(event)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="mb_12 light_gray_text sideBySide">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label forgotPassword"
                        htmlFor="flexCheckDefault"
                      >
                        Remember me
                      </label>
                    </div>
                    <div onClick={handleShow}>
                      <Link className="light_gray_text forgotPassword" to="">
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="buttn_row">
                      <LargeButton
                        buttonLabel={"Sign in"}
                        authPayload={logindetails}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="cal-12">
                      <div className="or ">
                        <span className="light_gray_text">OR Sign in with</span>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="cal-12 fb_google">
                      <FbButton />
                      <GoogleButton socialLoginValue={"Sign in"} />
                    </div>
                  </div>
                  */}
                  <div className="row">
                    <p className="register">
                      Don't have an account?{" "}
                      <Link to={`${baseUrl}/Signup`} className="blueLink">
                        Register
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
            <div className="right">
              <img
                src={IMAGE_PATHS.login_img}
                loading="lazy"
                alt="Logo"
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="AddNewCategoryModal"
        keyboard={false}
      >
        <Modal.Body>
          <div className="modal_body">
            <div className="row">
              <div className="twoPartCommon align-items-center mb-3">
                <div className="left"></div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.Close_modal}
                    loading="lazy"
                    alt="Logo"
                    width={"31px"}
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
            <h2 className="headText">Forgot Password</h2>
            <div className="row">
              <Form.Group className="mb_12" controlId="formBasicEmail">
                {/* <Form.Label className="label_text">User Id</Form.Label> */}

                <Form.Control
                  type="text"
                  placeholder="Email ID"
                  name="forgotUserId"
                  value={logindetails.forgotUserId}
                  onChange={(event) => forgotPasswordHandleOnChange(event)}
                />
              </Form.Group>
            </div>
            {/* <div className="row password_div">
              <InputGroup className="mb_12">
                <Form.Control
                  type={isPasswordOpen ? "text" : "password"}
                  placeholder="New Password"
                  name="forgotPassword"
                  value={logindetails.forgotPassword}
                  onChange={(event) => forgotPasswordHandleOnChange(event)}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon1"
                  onClick={() => eyeOpenCloseHandle("password")}
                >
                  {isPasswordOpen ? (
                    <img
                      src={IMAGE_PATHS.eye_open}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  ) : (
                    <img
                      src={IMAGE_PATHS.eye_close}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  )}
                </Button>
              </InputGroup>
            </div> */}

            {/* <div className="row password_div">
              <InputGroup className="mb_12">
                <Form.Control
                  type={isConfPasswordOpen ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confPassword"
                  value={logindetails.confPassword}
                  onChange={(event) => forgotPasswordHandleOnChange(event)}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon1"
                  onClick={() => eyeOpenCloseHandle("confPassword")}
                >
                  {isConfPasswordOpen ? (
                    <img
                      src={IMAGE_PATHS.eye_open}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  ) : (
                    <img
                      src={IMAGE_PATHS.eye_close}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  )}
                </Button>
              </InputGroup>
            </div> */}

            <div className="buttn_row mt_20">
              <Button
                variant="primary"
                type="button"
                className="login sign_in_button"
                onClick={() => forgotPasswordOnClickHandle()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
