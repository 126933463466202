import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import IMAGE_PATHS from "../../../constants";
// import { Button } from "react-bootstrap";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { convertPngTosvg } from "../../../components/FunctionHelper";

const ProductsMain = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [productDetails, setproductDetails] = useState({
    allProduct: [],
  });
  const { http } = ApiClient();

  const getAllProduct = async () => {
    let data = await MainPostCalls.getProduct(http, setIsLoader, Swal);
    let product = convertPngTosvg(data);

    setproductDetails((prevDetails) => ({
      ...prevDetails,
      allProduct: product,
    }));
  };

  useEffect(() => {
    getAllProduct();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <LayoutMain sideBarActive={1}>
      <>
        {isLoader && <Loader />}
        <div className="ProductsMain_outer">
          <div className="ProductsMain_topBg"></div>
          <div className="ProductsMain_contentPart">
            <div className="container">
              <h2>Our coverages</h2>
              <p>
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himeClass aptent taciti sociosqu
              </p>
              <div className="ProductsMain_4Box">
                {productDetails.allProduct.length ? (
                  productDetails.allProduct.map(
                    ({
                      productId,
                      productName,
                      productIcon,
                      productBkColor,
                      productDetails,
                    }) => (
                      <div
                        className="ProductsMain_individualBox"
                        key={productId}
                      >
                        <div
                          className="ProductsMain_individualBox_color"
                          style={{
                            backgroundColor: `${productBkColor}`,
                          }}
                        >
                          <img
                            src={`${productIcon.productIcon}`}
                            loading="lazy"
                            alt="Logo"
                            // width="50px"
                            className="imageColorWhite"
                          />
                        </div>

                        <h3>{productName}</h3>
                        <pre
                          style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          {productDetails || ""}
                        </pre>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed sit amet justo ipsum. Sed accumsan quam
                          vitae est varius fringilla.{" "}
                        </p>
                        <ul>
                          <li>Pellentesque suscipit fringilla libero eu </li>
                          <li>Cras risus eros, faucibus sit amet augue eros</li>
                          <li>In Uperdiet tristique tincidunt.orem</li>
                        </ul> */}
                        {/* <Button>Find out more</Button> */}
                      </div>
                    )
                  )
                ) : (
                  <div>No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="StatesWeServe_mainProduct">
          <div className="container">
            <h2>States We Serve</h2>
            <p>
              Our team of knowledgeable sales agents across the country are
              experts at finding the right coverage for your needs.
            </p>
            <img
              src={IMAGE_PATHS.StatesWeServe_mainProduct_map}
              loading="lazy"
              alt="Logo"
              width={""}
            />
          </div>
        </div>
      </>
    </LayoutMain>
  );
};

export default ProductsMain;
