import _ from "lodash";
import { PolicyDownload } from "../components/PolicyDownload/Policydownload";

export const sortArray = (arr, name, order = "asc") => {
  if (!name || !arr?.length) {
    return [];
  }
  try {
    let sortedArr = _.orderBy(arr, [name], [order]);
    return sortedArr;
  } catch (error) {
    return [];
  }
};

export const downloadPdf = async (
  postCommon,
  setisLoader,
  aesUtil,
  carrierId,
  carrierName
) => {
  setisLoader(true);

  let res = await postCommon.policyDownloadCommon(
    aesUtil.encrypt(JSON.stringify(carrierId))
  );
  if (res) PolicyDownload(res.data.carrierPolicy, carrierName);

  setisLoader(false);
};

export const itemMatchedAndMerged = (searchDetails, data) => {
  const mergedArray = searchDetails.allSearchedDetails?.productQuestion.map(
    (item2) => {
      const matchedItem = data.carrierDislikeResponses.find(
        (item1) => item1.productQuestionId === item2.productQuestionId
      );
      return matchedItem ? { ...item2, ...matchedItem } : item2;
    }
  );
  return mergedArray;
};
