import React from "react";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import ProfileSettingsCommon from "../../../components/ProfileSettingsCommon";

const ProfileSettingsAdmin = () => {
  const location = useLocation();

  const profileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));

  return (
    <Layout>
      <ProfileSettingsCommon
        profileDetails={profileDetails}
        location={location}
      />
    </Layout>
  );
};

export default ProfileSettingsAdmin;
