import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getMonthAbbreviation } from "../ChartsUtils/ChartsUtils";
import { lastLocation } from "../../Utils/Locations";

const baseUrl = process.env.REACT_APP_BASE_URL;

const MonthWIseSearchChart = ({ reportLists }) => {
  const location = useLocation();

  let monthSearchLocation =
    lastLocation(location) === "Report"
      ? `${baseUrl}/Admin/MonthSearchHistory`
      : lastLocation(location) === "ReportAgent" ||
        lastLocation(location) === "AgentDashboard"
      ? `${baseUrl}/ReportDetailsAgent`
      : `${baseUrl}/Admin/AdminDashboard`;

  let fillColor =
    lastLocation(location) === "Report" ||
    lastLocation(location) === "AdminDashboard"
      ? "#82ACD3"
      : lastLocation(location) === "ReportAgent"
      ? "#0AB39C"
      : "#8884d8";

  return (
    <li className="whiteBoxShadow">
      <h3>Month Wise Search</h3>

      {reportLists.monthWise.length ? (
        <Link to={monthSearchLocation}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={reportLists.monthWise}>
              <XAxis
                dataKey="month"
                interval={
                  lastLocation(location) === "AgentDashboard"
                    ? 0
                    : "preserveStartEnd"
                }
                style={{
                  fontSize: "10px",
                }}
                tickFormatter={getMonthAbbreviation}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                style={{
                  fontSize: "10px",
                }}
                domain={[0, reportLists.monthWise.length + 8]}
                // allowDataOverflow={true}
              />
              {/* <Tooltip content={<CutomizedToolTip />} /> */}
              {/* <Legend /> */}
              <CartesianGrid vertical={false} />

              <Bar
                dataKey="count"
                fill={fillColor}
                // name="Month"
                // legendType="rect"
                maxBarSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </Link>
      ) : (
        <div>No Data Found</div>
      )}
    </li>
  );
};

export default MonthWIseSearchChart;
