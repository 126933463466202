import React from "react";
import { Button } from "react-bootstrap";

const FullPartialMatchCarriers = ({
  carrier,
  handleShow3,
  questionDetails,
  rating,
  like,
  handleShow,
  dislike,
  handleShow2,
  downloadPdf,
  type,
}) => {
  return type === "full" ? (
    <div className="white_data_box2">
      <div className="twoPartCommon align-items-center">
        <div>
          <img
            src={carrier.carrierLogo}
            loading="lazy"
            alt="Logo"
            width={"148px"}
            height={"43px"}
          />
        </div>
        {carrier.carrierName}
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleShow3(questionDetails, carrier.carrierLogo, rating)
          }
        >
          <div
            role="progressbar"
            aria-valuenow="67"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": 100 }}
          ></div>
        </div>

        <div className="logo_like_button">
          <div>
            <Button
              className={`${like > 0 ? "active" : ""} thumb_icon_like`}
              onClick={() => handleShow(carrier.carrierId)}
            />
            <span className="devider_01">|</span>
            <Button
              className={`${dislike > 0 ? "active" : ""} thumb_icon_dislike`}
              onClick={() => handleShow2(carrier.carrierId)}
            />
          </div>
          <Button
            className="button_blueBorder_small"
            onClick={() => downloadPdf(carrier.carrierId, carrier.carrierName)}
          >
            Underwriting Guidelines
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="white_data_box2">
      <div className="twoPartCommon align-items-center">
        <div>
          <img
            src={carrier.carrierLogo}
            loading="lazy"
            alt="Logo"
            width={"148px"}
            height={"43px"}
          />
        </div>
        {carrier.carrierName}
        <div className="logo_like_button">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleShow3(questionDetails, carrier.carrierLogo, rating)
            }
          >
            <div
              role="progressbar"
              aria-valuenow="67"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ "--value": rating }}
            ></div>
          </div>
          <div>
            <Button
              className={`${like > 0 ? "active" : ""} thumb_icon_like`}
              onClick={() => handleShow(carrier.carrierId)}
            />
            <span className="devider_01">|</span>
            <Button
              className={`${dislike > 0 ? "active" : ""} thumb_icon_dislike`}
              onClick={() => handleShow2(carrier.carrierId)}
            />
          </div>
          <Button
            className="button_blueBorder_small"
            onClick={() => downloadPdf(carrier.carrierId, carrier.carrierName)}
          >
            Underwriting Guidelines
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FullPartialMatchCarriers;
