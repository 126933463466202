import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import { uploadImage } from "../../../components/FunctionHelper";
import { PostCallsAdmin, PostCallsCommon } from "../../../ApiClient/PostCall";
const baseUrl = process.env.REACT_APP_BASE_URL;

let buttons = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];
const CarriersDetails = () => {
  const navigate = useNavigate();
  const { http } = ApiClient();
  const [isLoader, setisLoader] = useState(false);
  const handleClose = () => setShow(false);
  const postCommon = PostCallsCommon(http, isLoader, Swal, navigate, "admin");
  const postAdmin = PostCallsAdmin(http, setisLoader, Swal, navigate);

  const [show, setShow] = useState(false);
  const [stateShow, setStateShow] = useState(false);

  const location = useLocation();
  let pathName = location.pathname.split("/");

  let carrierId = pathName[pathName.length - 1];

  const [editNewCarrierdetails, seteditNewCarrierdetails] = useState({
    carrierName: "",
    allProducts: [],
    email: "",
    carrierDetails: { carrier: {} },
    statesName: [],
    tempStates: [],
    productIds: [],
    contact: "",
    allStates: [],
    stateIds: [],
    serviceAbleProduct: [],
    carrierLogo: "",
    carrierLogoTitle: "",
    statesOfcarriers: [],
    carrierStates: [],
    servicesAbleProds: [],
    status: 1,
  });

  const handleShow = async () => {
    setisLoader(true);

    let data = await postAdmin.getProductWithQuestion();
    setisLoader(false);
    seteditNewCarrierdetails({
      ...editNewCarrierdetails,
      allProducts: data,
    });

    setShow(true);
  };

  const handleCloseState = () => {
    seteditNewCarrierdetails({
      ...editNewCarrierdetails,
      tempStates: [...editNewCarrierdetails.statesName],
    });

    setStateShow(false);
  };

  const editNewCarrierOnChangeHandle = (event) => {
    let { name, value, id } = event.target;

    if (name === "statesName") {
      let items = editNewCarrierdetails.stateIds;
      const index = items.indexOf(+id);
      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(+id);
      }

      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        stateIds: items,
      });
    } else if (name === "state") {
      let items = editNewCarrierdetails.tempStates;
      const index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(value);
      }

      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        tempStates: items,
      });
    } else if (name === "serviceAbleProduct") {
      const items = editNewCarrierdetails.productIds;
      const index = items.indexOf(+id);
      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(+id);
      }
      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        [name]: items,
      });
    } else if (name === "carrierLogo") {
      uploadImage(
        event,
        Swal,
        seteditNewCarrierdetails,
        editNewCarrierdetails,
        name
      );
    } else if (name === "status") {
      let status = value === "Inactive" ? 0 : 1;
      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        [name]: status,
      });
    } else {
      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        [name]: value,
      });
    }
  };

  const handleShowState = () => {
    getAllState();
    setStateShow(true);
  };
  const stateModalOnClickHandle = () => {
    const allStatesOfCarrier = editNewCarrierdetails.allStates.filter((state) =>
      editNewCarrierdetails.tempStates.includes(state.stateCode)
    );

    const stateIds = allStatesOfCarrier.map((state) => state.stateId);

    seteditNewCarrierdetails({
      ...editNewCarrierdetails,
      stateIds: stateIds,
      statesOfcarriers: allStatesOfCarrier,
    });

    setStateShow(false);
  };

  const editNnewcarrierPageOnClickHandle = async () => {
    let editCarrierPayload = {
      carrierId: editNewCarrierdetails.carrierDetails.carrier.carrierId,
      carrierName: editNewCarrierdetails.carrierName,
      carrierEmail: editNewCarrierdetails.email,
      carrierContactNo: editNewCarrierdetails.contact,
      carrierLogo: editNewCarrierdetails.carrierLogo,
      stateId: editNewCarrierdetails.stateIds,
      productId: editNewCarrierdetails.productIds,
      status: editNewCarrierdetails.status,
    };

    await postAdmin.updateCarrierAdmin(editCarrierPayload);
    seteditNewCarrierdetails({ ...editNewCarrierdetails });
    window.location.reload(true);

    handleClose();
  };

  const serviceAreaOnClickHandle = async (prodName, prodId) => {
    let payload = {
      carrierId: aesUtil.decrypt(carrierId),
      productId: prodId.toString(),
    };
    setisLoader(true);
    let response = await postAdmin.getAllCarrierQuestionAnswerAdmin({
      requestData: aesUtil.encrypt(JSON.stringify(payload)),
    });
    let carrierPayload = {
      carrierId: editNewCarrierdetails.carrierDetails.carrier.carrierId,
      carrierName: editNewCarrierdetails.carrierName,
      carrierEmail: editNewCarrierdetails.email,
      carrierContactNo: editNewCarrierdetails.contact,
      carrierLogo: editNewCarrierdetails.carrierLogo,
      stateId: editNewCarrierdetails.stateIds,
      productId: [prodId],
    };

    let data = await postAdmin.saveCarrierAdmin(carrierPayload);
    if (response?.questinAnswerSatate?.length) {
      navigate(
        `${baseUrl}/Admin/CarriersDetails/${carrierId}/UnderwritingGuidelines/${aesUtil.encrypt(
          JSON.stringify(prodId)
        )}`,
        {
          state: {
            prodName,
            allCarrier: data,
            carrierStates: editNewCarrierdetails.carrierStates,
            carrierLogo: editNewCarrierdetails.carrierLogo,
          },
        }
      );
    } else {
      navigate(`${baseUrl}/Admin/UnderGuide`, {
        state: {
          allCarrier: data,
        },
      });
    }
    setisLoader(false);
  };
  const selectAllHandleOnClick = () => {
    const stateName = editNewCarrierdetails.allStates.map(
      (state) => state.stateCode
    );
    seteditNewCarrierdetails({
      ...editNewCarrierdetails,
      tempStates: stateName,
    });
  };

  const getAllCarriersDetails = async () => {
    if (carrierId) {
      setisLoader(true);
      let data = await postAdmin.getCarrierByIdAdmin(carrierId);
      setisLoader(false);
      const stateIds = data?.productAndState[0]?.states.map(
        (state) => state.stateId
      );

      const stateName = data?.productAndState[0]?.states.map(
        (state) => state.stateCode
      );

      const productIds = data?.productAndState.map(
        ({ productBuilder }) => productBuilder.productId
      );

      seteditNewCarrierdetails({
        ...editNewCarrierdetails,
        carrierDetails: data,
        carrierName: data?.carrier?.carrierName,
        email: data?.carrier?.carrierEmail,
        contact: data?.carrier?.carrierContactNo,
        statesOfcarriers: data?.productAndState[0].states,
        carrierStates: data?.productAndState[0]?.states,
        servicesAbleProds: data?.productAndState,
        carrierLogo: data?.carrier?.carrierLogo,
        stateIds: stateIds,
        statesName: [...stateName],
        tempStates: [...stateName],
        productIds: productIds,
        status: data?.carrier?.status,
      });
    }
  };
  const getAllState = async () => {
    setisLoader(true);
    let data = await postCommon.getAllStateCommon();
    seteditNewCarrierdetails({
      ...editNewCarrierdetails,
      allStates: data,
    });
    setisLoader(false);
  };

  useEffect(() => {
    getAllCarriersDetails();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer CarriersPage">
        <div className="head_bread">
          <h1>
            <Link to={`${baseUrl}/Admin/Carriers`}>
              <img src={IMAGE_PATHS.ep_back} loading="lazy" alt="Logo" />
            </Link>
            Carrier Details
          </h1>
          <div>
            <Link to={`${baseUrl}/Admin/Carriers`}>Carriers</Link>
            <span>/ Home Insurance</span>
          </div>
        </div>

        <div className="AdminDashboardWhiteBody390 CarrierDetails">
          <div className="top">
            <div className="left">
              <img
                src={
                  Object.keys(editNewCarrierdetails.carrierDetails).length > 1
                    ? editNewCarrierdetails.carrierDetails.carrier.carrierLogo
                    : ""
                }
                width={100}
                height={100}
                loading="lazy"
                alt="Logo"
              />
            </div>
            <div className="left">
              <img
                src={IMAGE_PATHS.edit_icon}
                loading="lazy"
                alt="Logo"
                onClick={() => handleShow()}
              />
            </div>
          </div>
          <div className="middle">
            <div className="row">
              <label>Email address</label>
              <p>
                {(Object.keys(editNewCarrierdetails.carrierDetails).length >
                  1 &&
                  editNewCarrierdetails.carrierDetails.carrier.carrierEmail) ||
                  "NA"}
              </p>
            </div>
            <div className="row">
              <label>State</label>
              <p
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(25px, 2fr))",
                }}
              >
                {editNewCarrierdetails.carrierStates.length > 0 &&
                  editNewCarrierdetails.carrierStates.map(
                    ({ stateId, stateCode }, ind, array) => (
                      <span key={stateId}>
                        {stateCode}
                        {ind === array.length - 1 ? null : ","}
                      </span>
                    )
                  )}
              </p>
            </div>
            <div className="row">
              <label>Contact</label>
              <p>
                {(Object.keys(editNewCarrierdetails.carrierDetails).length >
                  1 &&
                  editNewCarrierdetails.carrierDetails.carrier
                    .carrierContactNo) ||
                  "NA"}
              </p>
            </div>
            <div className="row">
              <label>Service area</label>
              <div className="button_blueBorder_container_small">
                {editNewCarrierdetails.servicesAbleProds.length > 0 &&
                  editNewCarrierdetails.servicesAbleProds.map(
                    ({ productBuilder }) => (
                      <Button
                        className="button_blueBorder_small"
                        key={productBuilder.productId}
                        onClick={() =>
                          serviceAreaOnClickHandle(
                            productBuilder.productName,
                            productBuilder.productId
                          )
                        }
                      >
                        {productBuilder.productName}
                      </Button>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left"></div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={() => window.location.reload()}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              <h2 className="headText">Edit Carrier</h2>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">
                    Name of Carrier
                  </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="State Farm"
                    name="carrierName"
                    value={editNewCarrierdetails.carrierName}
                    onChange={(event) => editNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    value={editNewCarrierdetails.email || ""}
                    onChange={(event) => editNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row SelectedState ">
                <Form.Group
                  className=""
                  controlId="formBasicEmail"
                  style={{ marginBottom: "5px" }}
                >
                  <Form.Label className="label_text">Select State</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onClick={() => handleShowState()}
                  >
                    <option>
                      {editNewCarrierdetails.statesName[0] || "States"}
                    </option>
                  </Form.Select>
                </Form.Group>

                <fieldset className="checkbox-group">
                  {editNewCarrierdetails.statesOfcarriers &&
                    editNewCarrierdetails.statesOfcarriers.map(
                      ({ stateId, stateCode }) => (
                        <div className="checkbox" key={stateId}>
                          <label className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              name="statesName"
                              id={stateId}
                              value={editNewCarrierdetails.statesName[0]}
                              onChange={(event) =>
                                editNewCarrierOnChangeHandle(event)
                              }
                              checked={editNewCarrierdetails.stateIds.includes(
                                stateId
                              )}
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">
                                {stateCode}
                              </span>
                            </span>
                          </label>
                        </div>
                      )
                    )}
                </fieldset>
              </div>
              <div className="row">
                <Form.Group className="mb_12" controlId="formBasicEmail">
                  <Form.Label className="label_text">Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contact Number"
                    name="contact"
                    value={editNewCarrierdetails.contact || ""}
                    onChange={(event) => editNewCarrierOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Label className="label_text">
                    Select Serviceable Products
                  </Form.Label>
                </Form.Group>

                <div className="borderBoxCheckBox">
                  <fieldset className="checkbox-group">
                    {editNewCarrierdetails?.allProducts?.map(
                      ({ productId, productName }) => (
                        <div className="checkbox" key={productId}>
                          <label className="checkbox-wrapper">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              name="serviceAbleProduct"
                              id={productId}
                              value={productName}
                              onChange={(event) =>
                                editNewCarrierOnChangeHandle(event)
                              }
                              checked={editNewCarrierdetails.productIds.includes(
                                productId
                              )}
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">
                                {productName}
                              </span>
                            </span>
                          </label>
                        </div>
                      )
                    )}
                  </fieldset>
                </div>
              </div>

              <div className="row">
                <div>
                  <div className="formBox01 noBorder" style={{ width: "100%" }}>
                    <div className="twoPart">
                      <div className="left">
                        <p className="head">Upload Carrier Logo</p>

                        <p className="smallTest">
                          <img
                            src={editNewCarrierdetails.carrierLogo}
                            loading="lazy"
                            alt="Logo"
                            width={50}
                            height={50}
                          />
                        </p>
                      </div>
                      <div className="right">
                        <div className="upload_btn_wrapper">
                          <input
                            type="file"
                            name="carrierLogo"
                            onChange={(event) =>
                              editNewCarrierOnChangeHandle(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="twoPartCommon">
                  <div className="left">
                    <span className="status">Status</span>
                  </div>
                  <div className="right borderBoxCheckBox activeInactive">
                    <fieldset className="checkbox-group">
                      {buttons.map(({ value, label }) => (
                        <div className="checkbox" key={value}>
                          <label className="checkbox-wrapper">
                            <input
                              type="radio"
                              className="checkbox-input"
                              name="status"
                              value={label}
                              onChange={(event) =>
                                editNewCarrierOnChangeHandle(event)
                              }
                              defaultChecked={
                                value === editNewCarrierdetails.status
                              }
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">{label}</span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => editNnewcarrierPageOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={stateShow}
          onHide={handleClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left"></div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleCloseState}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="twoPartCommon align-items-center">
                  <div className="left">Add states</div>
                  <div className="left">
                    <Button
                      className="button_blueBorder_small"
                      onClick={selectAllHandleOnClick}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row">
                {isLoader && <Loader />}

                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group">
                      {editNewCarrierdetails.allStates.length &&
                        editNewCarrierdetails.allStates.map(
                          ({ stateId, stateCode }) => (
                            <div className="checkbox" key={stateId}>
                              <label className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  name="state"
                                  id={stateId}
                                  value={stateCode}
                                  onChange={(event) =>
                                    editNewCarrierOnChangeHandle(event)
                                  }
                                  checked={editNewCarrierdetails.tempStates.includes(
                                    stateCode
                                  )}
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => stateModalOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default CarriersDetails;
