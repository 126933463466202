import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import MyLoader from "../../../components/Loader/myLoader";
import { sortArray } from "../../../Utils/helper";
import SelectOption from "../../SelectOption/ProductSelectOption";

import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { downloadCsv } from "../../../components/CsvDownload/CsvDownload";
import Paginations from "../../../components/Pagination";
import MonthSearchHistoryTable from "../../../components/MonthSearchHistoryTable";
import SearchHistoryDetailsCalendar from "../../../components/Calendar/SearchHistoryDetailsCalendar";

const ReportDetailsAgent = () => {
  const [history, setHistory] = useState({ data: [], count: 0, csvFile: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchdata, setSearchdata] = useState({
    customerName: "",
    startDate: "",
    endDate: "",
  });
  const [selectedProd, setSelectedProd] = useState(null);
  const [page, setPage] = useState(1);
  const [sortData, setSortData] = useState({ name: "", order: "" });

  const { http } = ApiClient();

  const getMonthllyHistoryData = async (payload = false) => {
    try {
      let reqPayload = {
        productId: selectedProd,
        ...searchdata,
      };

      if (payload) {
        setPage(1);
      }
      setLoading(true);

      const res = await http.post(
        `/getMonthWiseProductSearchDetailsAgent/${payload ? 1 : page}`,
        payload ? reqPayload : null
      );
      setHistory({
        ...history,
        data: res?.data?.data?.map((el) => {
          return { ...el, userNameSpecial: el?.userName?.toLowerCase() };
        }),
        count: res?.data?.count,
        csvFile: res?.data?.csvFile,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthllyHistoryData();
    return () => {};
    // eslint-disable-next-line
  }, [page]);

  const sortHistoryData = (name, order) => {
    setHistory({
      ...history,
      data: sortArray(
        history.data,
        name === "userName" ? "userNameSpecial" : name,
        order
      ),
    });
  };

  useEffect(() => {
    if (sortData.name && sortData.order) {
      sortHistoryData(sortData.name, sortData.order);
    }
    return () => {};
    // eslint-disable-next-line
  }, [sortData]);

  return (
    <LayoutAgent sideBarActive={25}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>
            <Link to={`${process.env.REACT_APP_BASE_URL}/ReportAgent`}>
              <img
                src={IMAGE_PATHS.ep_back}
                loading="lazy"
                alt="Logo"
                // width={"57px"}
              />
            </Link>
            Month wise product search details
          </h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Report</span>
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="searchHistoryFormBar">
                <div>
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      name="customerName"
                      value={searchdata.customerName}
                      onChange={(e) => {
                        setSearchdata({
                          ...searchdata,
                          customerName: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <SelectOption setSelectedProduct={setSelectedProd} />
                </div>
                <SearchHistoryDetailsCalendar
                  searchdata={searchdata}
                  setSearchdata={setSearchdata}
                />

                <div>
                  <Button
                    className="button_blue"
                    onClick={() => {
                      getMonthllyHistoryData(true);
                    }}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>
            <div className="right">
              <Button
                className="button_redBorder"
                onClick={() => downloadCsv(history.csvFile)}
              >
                Export Report
              </Button>
            </div>
          </div>
          <MonthSearchHistoryTable
            setSortData={setSortData}
            history={history}
          />
        </div>

        {history?.data.length ? (
          <Paginations page={page} setPage={setPage} count={history.count} />
        ) : null}
      </div>
      {/* <CommonMonthlySearchHistory type="agent" /> */}
    </LayoutAgent>
  );
};

export default ReportDetailsAgent;
