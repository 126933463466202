import React, { Fragment } from "react";
import { DateFormat } from "./DateFormat/DateFormat";
import { Button } from "react-bootstrap";
import { lastLocation } from "../Utils/Locations";
import { useLocation } from "react-router-dom";

const SearchHistoryTable = ({
  searchHistoryLists,
  ViewHandleShow,
  loading,
  handleRepeatSearch,
}) => {
  const location = useLocation();
  return (
    <Fragment>
      {searchHistoryLists?.length > 0 ? (
        searchHistoryLists?.map((el) =>
          el?.historyDatas?.map((list, ind) => (
            <div className="white_data_box" key={ind}>
              <h2>
                <span className="lightText">Agency:</span> {list.agency}
              </h2>
              <div className="LeftRightPart">
                <div className="left">
                  <ul className="agencyListing">
                    <li>
                      <ul className="agencyListingData">
                        <li>
                          <span>Product:</span> {list.product || "NA"}
                        </li>
                        <li>
                          <span>Created By:</span> {list.createdBy || "NA"}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="agencyListingData">
                        <li>
                          <span>Created on:</span>{" "}
                          {DateFormat(list.createdOn) || "NA"}
                        </li>
                        <li>
                          <span>Last Updated On:</span>
                          {DateFormat(list.lastUpdatedOn) || "NA"}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="agencyListingData">
                        <li>
                          <span>Matched Carrier:</span>
                          {list.matchedCarrier || "NA"}
                        </li>
                        <li>
                          <span>Reviewed Carrier:</span>
                          {list.reviewdcarrier.length || "NA"}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="agencyListingData">
                        <li>
                          <span>Customer Name:</span>
                          {list.customerName || "NA"}
                        </li>
                        <li>
                          <span>Search Date:</span>
                          {DateFormat(list.searchDate) || "NA"}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                {lastLocation(location) === "SearchHistoryAgent" && (
                  <div className="left">
                    <Button
                      className="button_blueBorder"
                      onClick={() => {
                        handleRepeatSearch(list);
                      }}
                    >
                      Repeat Search
                    </Button>
                  </div>
                )}
                <div className="left">
                  <Button
                    className="button_blueBorder"
                    onClick={() => {
                      ViewHandleShow(list);
                    }}
                  >
                    History
                  </Button>
                </div>
              </div>
            </div>
          ))
        )
      ) : loading ? null : (
        <div
          style={{
            textAlign: "center",
            marginTop: "5rem",
            fontWeight: "600",
          }}
        >
          No Data Found!
        </div>
      )}
    </Fragment>
  );
};

export default SearchHistoryTable;
